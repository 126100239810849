import React, { ChangeEvent, useRef, useState } from "react"
import { SearchIcon } from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom"
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteRefMethods,
} from "@choc-ui/chakra-autocomplete"
import {
  Box,
  Flex,
  Icon,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react"
import { format } from "date-fns"
import { useAppContext } from "../AppContext"
import { findPatients, PatientType } from "../api/patient"
import { useDebouncedCallback } from "use-debounce"
import { useQuery } from "@tanstack/react-query"
import getHighlightData from "utils/getHighlightData"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import includesInsensitive from "utils/includesInsensitive"
import getUtcDate from "utils/getUtcDate"

export interface Item {
  label: string
  value: string
}

interface SearchBarProps {
  isDisabled?: boolean
}
const SearchBar = ({ isDisabled }: SearchBarProps) => {
  const searchBarRef = useRef<AutoCompleteRefMethods>(null)
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [searchDebounced, setSearchDebounced] = useState("")

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    debouncedSearch()
  }

  const debouncedSearch = useDebouncedCallback(() => {
    setSearchDebounced(search)
  }, 275)
  const { currentScope } = useAppContext()

  const handleChange = (value: any, item: any) => {
    if (item) {
      const { originalValue }: { originalValue: PatientType } = item
      navigate(`/patients/${originalValue.id}`)
      setSearch("")
      // waits for next animation frame before clearing autocomplete, so that it gets in "selected" state
      requestAnimationFrame(() => {
        searchBarRef.current?.resetItems()
      })
    }
  }

  const findPatientParams = {
    search,
    ...getCurrentScopeParams(currentScope),
  }

  const { data: patients, isLoading } = useQuery(
    ["getPatients", searchDebounced],
    () => findPatients(findPatientParams)
  )

  const customFilter = (
    inputValue: string,
    optionValue: number,
    optionLabel: string
  ) => includesInsensitive(inputValue, optionLabel)

  return (
    <Box pos="relative" flex={1}>
      <AutoComplete
        ref={searchBarRef}
        onChange={handleChange}
        filter={customFilter}
        emptyState={
          <Text fontSize={14} margin={0} mx={2} px={2} whiteSpace="pre">
            Aucun patient trouvé !
          </Text>
        }
      >
        {({ isOpen }) => (
          <div>
            <InputGroup>
              <AutoCompleteInput
                isDisabled={isDisabled}
                placeholder="Rechercher des patients"
                fontFamily="Montserrat"
                fontSize={14}
                backgroundColor="white"
                borderColor="white"
                py={4}
                px={8}
                flex={1}
                borderTopRadius={9}
                borderBottomRadius={isOpen ? 0 : 9}
                boxShadow="none !important"
                _focus={{
                  borderColor: "white",
                }}
                value={search}
                onChange={handleInputChange}
              />
              <InputLeftElement children={<Icon as={SearchIcon} />} mt={0} />
            </InputGroup>
            <AutoCompleteList
              mt={0.25}
              px={4.5}
              w="100%"
              borderTopRadius={0}
              borderBottomRadius={9}
            >
              {!isLoading &&
                patients?.map((patient, cid) => {
                  const text = patient.label ?? ""
                  const { startHighlightIndex, endHighlightIndex } =
                    getHighlightData({ text, inputValue: search })
                  return (
                    <AutoCompleteItem
                      value={patient}
                      label={patient.label}
                      getValue={(item: PatientType) => item.id?.toString()}
                      color="common.500"
                      _focus={{
                        backgroundColor: "common.100",
                      }}
                      key={`option-${cid}`}
                    >
                      <Flex alignItems="center">
                        <Text
                          fontSize={14}
                          mr={1}
                          fontWeight={700}
                          margin={0}
                          whiteSpace="pre"
                        >
                          {patient.label?.substring(0, startHighlightIndex)}
                        </Text>
                        <Text
                          fontSize={14}
                          mr={1}
                          fontWeight={700}
                          color="primary.500"
                          margin={0}
                          whiteSpace="pre"
                        >
                          {patient.label?.substring(
                            startHighlightIndex,
                            endHighlightIndex
                          )}
                        </Text>
                        <Text
                          fontSize={14}
                          mr={1}
                          fontWeight={700}
                          margin={0}
                          whiteSpace="pre"
                        >
                          {patient.label?.substring(endHighlightIndex)}
                        </Text>
                        <Text fontSize={12} ml={1}>
                          -{" "}
                          {patient.birthday
                            ? format(getUtcDate(patient.birthday), "dd/MM/yyyy")
                            : "Pas de date de naissance"}{" "}
                          - {patient.appointments?.length || 0}{" "}
                          {patient.appointments?.length &&
                          patient.appointments?.length > 1
                            ? "consultations"
                            : "consultation"}
                        </Text>
                      </Flex>
                    </AutoCompleteItem>
                  )
                })}
            </AutoCompleteList>
          </div>
        )}
      </AutoComplete>
    </Box>
  )
}

export default SearchBar

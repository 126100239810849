import { createContext, useContext } from "react"
import isCenterAdminCurrentScope from "utils/currentScope/isCenterAdmin"
import { MeType } from "./api/user"

export type AcademyCurrentScope = { id: number; type: "academy" }
export type CenterCurrentScope = {
  id: number
  centerGroupId?: never
  type: "center"
}
export type AdminCenterCurrentScope = {
  id: number
  centerGroupId: number
  type: "center"
}

export type CurrentScope =
  | AcademyCurrentScope
  | CenterCurrentScope
  | AdminCenterCurrentScope
interface AppContextInterface {
  jwt: string | null
  setJwt: (jwt: string | null) => void
  user: MeType | null
  setUser: React.Dispatch<React.SetStateAction<MeType | null>>
  currentScope: CurrentScope | null
  setCurrentScope: (currentScope: CurrentScope | null) => void
  setCenterAdminCurrentScope: (currentScope: CurrentScope | null) => void
  cleverLogin: (user: MeType) => void
}
const AppContext = createContext<AppContextInterface | undefined>(undefined)

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider")
  }
  return context
}

// Forces type inference on currentScope
export const useCenterAdminCurrentScope = () => {
  const context = useAppContext()
  const { currentScope } = context
  if (!isCenterAdminCurrentScope(currentScope)) {
    throw new Error("useCenterAdminCurrentScope must be used for center admins")
  }
  return currentScope
}

export default AppContext

import { AddIcon } from "@chakra-ui/icons"
import { Box, Heading, Text, useDisclosure } from "@chakra-ui/react"
import React, { useRef } from "react"
import convertBreakLineToBr from "utils/convertLineBreakToBr"
import { PatientAppointmentType } from "../../../api/patient"
import AButton from "../../../components/AButton"
import AEditButton from "../../../components/AEditButton"
import ASkeleton from "../../../components/ASkeleton"
import EditRecommandation from "./EditRecommandation"

interface IProps {
  isLoading?: boolean
  appointment?: PatientAppointmentType
  setAppointment?: React.Dispatch<
    React.SetStateAction<PatientAppointmentType | null>
  >
  type: "osteoAdvice" | "internalNote" | "secondaryInternalNote"
}

const addButtonText = {
  osteoAdvice: "Note pour le patient",
  internalNote: "Note complémentaire privée",
  secondaryInternalNote:
    "Ajouter une note supplémentaire (non visible par le patient)",
}

const title = {
  osteoAdvice: "Recommandation écrite aux patients",
  internalNote: "Notes complémentaires (non visible par le patient)",
  secondaryInternalNote: "",
}

const emptyText = {
  osteoAdvice: "Aucune note pour le patient pour le moment",
  internalNote: "Aucune note complémentaire pour le moment",
  secondaryInternalNote: "Aucune note supplémentaire pour le moment",
}

const Recommendation: React.FC<IProps> = ({
  isLoading,
  appointment,
  setAppointment,
  type,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  if (!appointment) return null
  return (
    <div>
      <Box pos="relative">
        <Heading as="h3" fontSize={18} mb={2}>
          {title[type]}
        </Heading>
        <ASkeleton isLoaded={!isLoading}>
          <Box
            bg={type === "osteoAdvice" ? "secondary.200" : "white"}
            p={4}
            mb={4}
            borderRadius={9}
            pos="relative"
          >
            {appointment[type] ? (
              <Box
                fontSize={14}
                lineHeight="18px"
                dangerouslySetInnerHTML={{
                  __html: convertBreakLineToBr(appointment[type]),
                }}
              />
            ) : (
              <Text fontSize={14} lineHeight="18px" as={"i"}>
                {emptyText[type]}
              </Text>
            )}
            <AEditButton
              pos="absolute"
              top={2}
              right={2}
              onClick={onOpen}
              ariaLabel="Modifier la recommandation"
            />
          </Box>
        </ASkeleton>
      </Box>
      <EditRecommandation
        onClose={onClose}
        isOpen={isOpen}
        cancelRef={cancelRef}
        appointment={appointment}
        setAppointment={setAppointment}
        type={type}
      />
    </div>
  )
}

export default Recommendation

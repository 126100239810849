import { CurrencyCode, StripeProductType } from "api/subscriptions"
import formatPrice from "utils/price/formatPrice"

const getLocalizedPricePerPeriodPerSeat = (
  product: StripeProductType,
  currencyCode: CurrencyCode
) => {
  const priceFloat = product.pricesPerCurrency[currencyCode].unit_amount / 100

  return {
    monthly:
      product.price.recurring.interval === "year"
        ? formatPrice(priceFloat / 12)
        : formatPrice(priceFloat),
    yearly:
      product.price.recurring.interval === "year"
        ? formatPrice(priceFloat)
        : formatPrice(priceFloat * 12),
  }
}
export default getLocalizedPricePerPeriodPerSeat

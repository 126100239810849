import axiosInstance from "./axiosInstance"

export type GlobalStatsType = {
  activePatients: number
  appointmentsByMonth: {
    yearMonth: string
    value: number
  }[]
  mapViewCount: {
    value: number
    averagePerOsteo: number
  }
  patientCompletingExosPercentage: number
  averageScreenViewByPatient: number
  totalCountOfBookings: {
    value: number
    averagePerOsteo: number
  }
  useForAWeek: number
  averageEngagmentTime: number
}

export type PersonalStatsType = {
  averageAppointmentsCountByMonthByOsteo: {
    yearMonth: string
    value: number
  }[]
  osteoAppointmentsCountByMonth: {
    yearMonth: string
    value: number
  }[]
  ourActivePatientsCount: number
}

export type StatsType = {
  globalStats: GlobalStatsType
  personalStats: PersonalStatsType
}

export const getAllStats = async () => {
  const response = await axiosInstance.get<StatsType>(`/osteo/stats/all`)
  return response.data
}

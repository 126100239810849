import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import arrowBack from "../../../../../../assets/arrow-back.svg"
import React, { useState } from "react"
import HeaderTab from "./components/HeaderTab"
import { useEditingFavoritesContext } from "components/EditingFavoritesContext/EditingFavoritesContext"
import AAlertDialogConfirm from "components/AAlertDialogConfirm"
import { ReasonType } from "api/patient"

interface IProps {
  setStep?: React.Dispatch<React.SetStateAction<number>>
  onBack?: () => void
  reasons?: ReasonType[]
  currentReason: ReasonType | null
  setCurrentReason: React.Dispatch<React.SetStateAction<ReasonType | null>>
}

const Header: React.FC<IProps> = ({
  setStep,
  onBack,
  reasons,
  currentReason,
  setCurrentReason,
}) => {
  const { onDone, isFavoriteNotSaved } = useEditingFavoritesContext()
  const [nextReason, setNextReason] = useState<ReasonType | undefined>(
    undefined
  )
  const isOpened = nextReason !== undefined
  const headerContent = "Quitter sans sauvegarder"
  const destructiveActionText = "Oui"

  const onChange = (reason: ReasonType) => {
    if (isFavoriteNotSaved) {
      setNextReason(reason)
    } else {
      onDone()
      setCurrentReason(reason)
    }
  }

  const onNotSave = () => {
    onDone()
    setNextReason(undefined)
    setCurrentReason(nextReason!)
  }

  const onReturn = () => {
    if (setStep) {
      setStep(0)
    } else if (onBack) {
      onBack()
    }
  }

  const returnText = setStep
    ? "Retour au choix de la consultation"
    : "Retour au détail de la consultation"
  return (
    <>
      <Box w="68%">
        <Flex mb={6} cursor="pointer" onClick={onReturn} w={"fit-content"}>
          <Image src={arrowBack} mr={2} />
          <Text>{returnText}</Text>
        </Flex>

        {reasons !== undefined &&
          currentReason !== null &&
          (reasons.length === 1 ? (
            <Heading fontSize={22} color="tertiary.500" mb={6}>
              {reasons[0].name}
            </Heading>
          ) : (
            <>
              <Heading fontSize={22} color="tertiary.500" mb={6}>
                Zones / Symptomatologies
              </Heading>
              <Flex
                borderBottom="1px solid"
                borderColor="primary.500"
                flexWrap="wrap"
                rowGap={2}
              >
                {reasons.map((reason) => (
                  <HeaderTab
                    key={reason.id}
                    reason={reason}
                    isSelected={currentReason.id === reason.id}
                    onClick={() => onChange(reason)}
                  />
                ))}
              </Flex>
              <Text fontSize={16} fontWeight="bold" mt={15} mb={2}>
                Choisissez vos vidéos
              </Text>
              <Text fontSize={14} mb={6}>
                Toutes les durées ont été présélectionnées par défaut
              </Text>
            </>
          ))}
      </Box>
      <AAlertDialogConfirm
        isOpen={isOpened}
        onClose={() => setNextReason(undefined)}
        onConfirm={() => onNotSave()}
        headerContent={headerContent}
        destructiveActionText={destructiveActionText}
        destructiveActionVariant="primary"
        bodyContent="Êtes-vous sûr de vouloir quitter sans sauvegarder ce favori ?"
      />
    </>
  )
}

export default Header

import { Box, Flex, Spinner } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { curryIncludesInsensitive } from "utils/includesInsensitive"
import {
  getAllFavoritePrograms,
  getAppointmentReason,
  SelectedExerciceType,
  TrainingVideoGroupsType,
  VideoGroupCategoryType,
  VideoType,
} from "api/appointmentReason"
import Header from "pages/NewAppointment/components/SelectExercices/components/Header/Header"
import SelectedExercices from "pages/NewAppointment/components/SelectExercices/components/SelectedExercices/SelectedExercices"
import SuggestedProgramme from "pages/NewAppointment/components/SelectExercices/components/SuggestedProgramme/SuggestedProgramme"
import VideoGroup from "pages/NewAppointment/components/SelectExercices/components/VideoGroup/VideoGroup"
import FavoritePrograms from "pages/NewAppointment/components/SelectExercices/components/FavoritePrograms/FavoritePrograms"
import EditingFavoritesContextProvider from "components/EditingFavoritesContext/EditingFavoritesContext"
import { useQuery } from "@tanstack/react-query"
import { ReasonType } from "api/patient"
import {
  ZonePathologieType,
  ZONES_MOCK,
} from "pages/NewAppointment/newAppointment.mock"
import FilterExercices from "pages/NewAppointment/components/SelectExercices/components/FilterExercices/FilterExercices"

// HELPERS
const sortByTimeUsed = (
  a: TrainingVideoGroupsType,
  b: TrainingVideoGroupsType
) => {
  if (a.timeUsed < b.timeUsed) {
    return 1
  }
  if (a.timeUsed > b.timeUsed) {
    return -1
  }
  return 0
}

// COMPONENTS
interface IProps {
  onBack: () => void
  onNext: () => void
  reasons?: ReasonType[]
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
}

const EditSelectExercices: React.FC<IProps> = ({
  onBack,
  onNext,
  reasons,
  selectedExercices,
  setSelectedExercices,
}) => {
  const [currentReason, setCurrentReason] = useState<ReasonType | null>(
    reasons === undefined ? null : reasons[0]
  )
  const [search, setSearch] = useState("")
  const [suggestedProgramme, setSuggestedProgramme] = useState<VideoType[]>([])
  const [videoGroups, setVideoGroups] = useState<TrainingVideoGroupsType[]>([])
  const [categories, setCategories] = useState<VideoGroupCategoryType[]>([])
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null)

  const [restrictionFilter, setRestrictionFilter] = useState<string | null>(
    null
  )
  const restrictions = videoGroups.reduce(
    (acc, { restrictions }) => [
      ...acc,
      ...(restrictions ?? []).reduce(
        (restrictionsAcc, { name }) =>
          acc.includes(name) ? restrictionsAcc : [...restrictionsAcc, name],
        [] as string[]
      ),
    ],
    [] as string[]
  )

  const fetchDataForReason = async (reason: ReasonType | null) => {
    const { data } = await getAppointmentReason(reason?.id ?? -1)
    setSuggestedProgramme(data.suggestedProgramme)
    setVideoGroups(data.trainingVideoGroups)
  }

  const { isLoading, data } = useQuery(
    ["getFavorites", currentReason?.id],
    async () => {
      if (currentReason) {
        return await getAllFavoritePrograms(currentReason.id)
      }
      return null
    },
    {
      enabled: currentReason !== null,
    }
  )
  const favoritePrograms = data ?? []

  useEffect(() => {
    fetchDataForReason(currentReason)
  }, [currentReason])

  useEffect(() => {
    const listOfCategories = videoGroups
      .map((videoGroup) => videoGroup.category)
      .filter((elt) => elt !== null)

    const uniqueCategories = listOfCategories.filter(
      (category, index) => listOfCategories.indexOf(category) === index
    )

    setCategories(uniqueCategories)
  }, [videoGroups])

  useEffect(() => {
    // reset filters
    setCategoryFilter(null)
    setSearch("")
  }, [currentReason])

  const filterWithCategoriesRestrictionsAndSearch = (
    list: TrainingVideoGroupsType[]
  ) => {
    const curriedIncludesInsensitive = curryIncludesInsensitive(search)
    let filteredList = list
    if (search !== "") {
      filteredList = filteredList.filter(({ title }) =>
        curriedIncludesInsensitive(title)
      )
    }
    if (categoryFilter !== null) {
      filteredList = filteredList.filter(
        ({ category }) => category === categoryFilter
      )
    }
    if (restrictionFilter !== null) {
      filteredList = filteredList.filter(({ restrictions }) =>
        restrictions.some(({ name }) => name === restrictionFilter)
      )
    }
    return filteredList
  }

  const sortedFilteredVideoGroups = filterWithCategoriesRestrictionsAndSearch(
    videoGroups.sort(sortByTimeUsed)
  )

  const allReasons: ZonePathologieType[] = [
    {
      id: -1,
      name: "Toutes les zones",
      code: "ALL",
      left: "0px",
      top: "0px",
      schema: 1,
    },
    ...ZONES_MOCK,
  ]

  return (
    <EditingFavoritesContextProvider>
      <Box>
        {reasons !== undefined && currentReason !== null ? (
          <Header
            onBack={onBack}
            reasons={reasons}
            currentReason={currentReason}
            setCurrentReason={setCurrentReason}
          />
        ) : (
          <Header
            onBack={onBack}
            currentReason={currentReason}
            setCurrentReason={setCurrentReason}
          />
        )}

        <Flex>
          <Box flex={1}>
            {reasons !== undefined && currentReason !== null && (
              <>
                {suggestedProgramme.length > 0 && (
                  <SuggestedProgramme
                    videos={suggestedProgramme}
                    selectedExercices={selectedExercices}
                    setSelectedExercices={setSelectedExercices}
                  />
                )}
                {isLoading ? (
                  <Flex
                    bg="secondary.200"
                    height={108}
                    borderRadius={9}
                    mb={6}
                    direction={"column"}
                    align={"center"}
                    gap={3}
                    justify={"center"}
                  >
                    <Spinner />
                  </Flex>
                ) : (
                  <FavoritePrograms
                    listOfFavorites={favoritePrograms}
                    selectedExercices={selectedExercices}
                    setSelectedExercices={setSelectedExercices}
                    currentReasonId={currentReason.id}
                  />
                )}
              </>
            )}
            <FilterExercices
              categories={categories}
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
              setSearch={setSearch}
              restrictions={restrictions}
              restrictionFilter={restrictionFilter}
              setRestrictionFilter={setRestrictionFilter}
              reasons={reasons}
              allReasons={allReasons}
              setCurrentReason={setCurrentReason}
            />
            {sortedFilteredVideoGroups.map((videoGroup) => (
              <VideoGroup
                key={`group_${videoGroup.id}`}
                videoGroup={videoGroup}
                selectedExercices={selectedExercices}
                setSelectedExercices={setSelectedExercices}
              />
            ))}
          </Box>
          {selectedExercices && (
            <SelectedExercices
              selectedExercices={selectedExercices}
              setSelectedExercices={setSelectedExercices}
              onNext={onNext}
            />
          )}
        </Flex>
      </Box>
    </EditingFavoritesContextProvider>
  )
}

export default EditSelectExercices

import { Box, Flex, Heading } from "@chakra-ui/react"
import { MediaType } from "api/media"
import ADiscoverCardBase from "components/ADiscoverCard/Base"
import React from "react"

interface IProps {
  selectedMedias: MediaType[]
}

const AppointmentMedias: React.FC<IProps> = ({ selectedMedias }) => {
  if (!selectedMedias) return null
  return (
    <Box mb={6}>
      <Box mt={6} p={6} bg="white" borderRadius={9}>
        <Heading as="h3" fontSize={18} mb={5}>
          Contenu Explorer sélectionnés
        </Heading>
        <Flex direction={"row"} gap={"16px"} flexWrap="wrap">
          {selectedMedias.map((media, index) => (
            <ADiscoverCardBase
              media={media}
              onAppointmentRecap={true}
              key={index}
              isSmall
            />
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default AppointmentMedias

import { AddIcon, ViewIcon } from "@chakra-ui/icons"
import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useState } from "react"
import AButton from "../AButton"
import { MediaType } from "api/media"

interface IProps {
  media: MediaType
  isDisabled?: boolean
  onAdd?: () => void
  onAppointmentRecap?: boolean
  isSmall?: boolean
}

const ADiscoverCardBase: React.FC<IProps> = ({
  media,
  isDisabled,
  onAdd,
  onAppointmentRecap,
  isSmall = false,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box w="full">
      <Box
        borderRadius={9}
        overflow="hidden"
        cursor={isDisabled ? "not-allowed" : undefined}
        w={isSmall ? "25%" : "full"}
        opacity={isDisabled ? 0.4 : 1}
        bg={onAppointmentRecap ? "primary.200" : "white"}
      >
        {media.type === "video" && (
          <AspectRatio ratio={8 / 3}>
            <Box
              pos="relative"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <Image
                alt={media.title}
                src={media.cover.url}
                w="100%"
                h="100%"
                objectFit="cover"
              />
              {isHovering && !isDisabled && (
                <Flex
                  bg="rgba(17, 23, 45, 0.5)"
                  pos="absolute"
                  top={0}
                  left={0}
                  w="100%"
                  h="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AButton
                    text="Aperçu"
                    variant="tertiary"
                    fontSize={12}
                    leftIcon={<ViewIcon />}
                    onClick={isDisabled ? () => null : onOpen}
                  />
                </Flex>
              )}
            </Box>
          </AspectRatio>
        )}
        {media.type === "audio" && (
          <Flex padding="8px" bg="primary.200" alignItems="center" gap="8px">
            <audio
              controls
              src={media.media.url}
              typeof="audio/mpeg"
              style={{ pointerEvents: isDisabled ? "none" : "auto" }}
            ></audio>
          </Flex>
        )}
        <Flex
          p={3}
          alignItems={"center"}
          justify={"start"}
          onClick={onAdd ? () => onAdd() : undefined}
          cursor="pointer"
          gap={2}
          as="button"
        >
          {onAdd !== undefined && (
            <AddIcon w={3.5} h={3.5} color="tertiary.500" ml={1} />
          )}
          <Box>
            <div>
              <Text
                fontSize={14}
                fontWeight={700}
                color="tertiary.500"
                textAlign={"start"}
              >
                {media.title}
              </Text>
            </div>
          </Box>
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={469}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading fontSize={22} fontWeight="bold" textAlign="center" mb={7}>
              {media.title}
            </Heading>
            {media.type === "video" && (
              <video
                src={media.media?.url}
                controls
                onContextMenu={(e: any) => e.preventDefault()}
                controlsList="nodownload"
              ></video>
            )}
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default ADiscoverCardBase

import { AddIcon, MinusIcon, ViewIcon } from "@chakra-ui/icons"
import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useNumberInput,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { VideoType } from "api/appointmentReason"
import AButton from "../AButton"
import { MdFavorite, MdFavoriteBorder } from "react-icons/md"

interface IProps {
  video: VideoType
  videoGroupId: number
  title?: string
  isEditingFavorite?: boolean
  isSelecting?: boolean
  isSelected?: boolean
  isDisabled?: boolean
  onClick: () => void
  onAdd?: (value: number) => void
}

const AExerciceCardBase: React.FC<IProps> = ({
  video,
  videoGroupId,
  title,
  isEditingFavorite,
  isSelecting,
  isSelected,
  isDisabled,
  onClick,
  onAdd,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
    valueAsNumber,
  } = useNumberInput({
    step: 5,
    defaultValue: video.defaultDuration || 30,
    min: video.defaultDuration || 0,
    max: 300,
    isDisabled,
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  return (
    <Box w="calc(33% - 6px)" minW={"220px"}>
      <Box
        bg="white"
        borderRadius={9}
        w="100%"
        overflow="hidden"
        cursor={isDisabled ? "not-allowed" : undefined}
        opacity={isDisabled ? 0.4 : 1}
      >
        <AspectRatio ratio={8 / 3}>
          <Box
            pos="relative"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <Image
              alt={video.preview.alternativeText}
              src={video.preview.formats.small.url}
              w="100%"
              h="100%"
              objectFit="cover"
            />
            {isHovering && !isDisabled && (
              <Flex
                bg="rgba(17, 23, 45, 0.5)"
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                alignItems="center"
                justifyContent="center"
              >
                <AButton
                  text="Aperçu"
                  variant="tertiary"
                  fontSize={12}
                  leftIcon={<ViewIcon />}
                  onClick={isDisabled ? () => null : onOpen}
                />
              </Flex>
            )}
          </Box>
        </AspectRatio>
        {isSelecting && !isEditingFavorite ? (
          <Flex
            py={3}
            px={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center" fontSize={12}>
              <Text fontWeight={700}>Durée</Text>
              <Text ml={1}>(sec)</Text>
            </Flex>
            <Flex alignItems="center">
              <MinusIcon
                {...dec}
                w={3}
                color="primary.500"
                cursor={isDisabled ? "not-allowed" : "pointer"}
              >
                -
              </MinusIcon>
              <Input
                {...input}
                bg="common.100"
                w="60px"
                h="25px"
                p={2}
                textAlign="center"
                mx={2}
                fontWeight={700}
                fontSize={14}
              />
              <AddIcon
                {...inc}
                w={3}
                color="primary.500"
                cursor={isDisabled ? "not-allowed" : "pointer"}
              >
                -
              </AddIcon>
            </Flex>
            <AButton
              text="Ajouter"
              fontSize={11}
              fontWeight={700}
              px={5}
              py={2}
              isDisabled={isDisabled}
              onClick={onAdd ? () => onAdd(valueAsNumber) : undefined}
            />
          </Flex>
        ) : (
          <Flex
            p={2}
            alignItems="center"
            onClick={onClick}
            cursor="pointer"
            as="button"
          >
            {isEditingFavorite ? (
              <>
                {isSelected ? (
                  <Icon
                    w={3.5}
                    h={3.5}
                    ml={1}
                    mr={13}
                    color="secondary.900"
                    aria-label="Enlever des favoris"
                    as={MdFavorite}
                  />
                ) : (
                  <Icon
                    w={3.5}
                    h={3.5}
                    ml={1}
                    mr={13}
                    color="secondary.900"
                    aria-label="Ajouter aux favoris"
                    as={MdFavoriteBorder}
                  />
                )}
              </>
            ) : (
              <AddIcon w={3.5} h={3.5} color="tertiary.500" ml={1} mr={13} />
            )}
            <Box>
              {title ? (
                <div>
                  <Text
                    fontSize={14}
                    fontWeight={700}
                    mb={2}
                    color="tertiary.500"
                  >
                    {title}
                  </Text>
                  <Text fontSize={12}>Niveau {video.level}</Text>
                </div>
              ) : (
                <Text
                  fontSize={14}
                  fontWeight={700}
                  my={1}
                  color="tertiary.500"
                >
                  Niveau {video.level}
                </Text>
              )}
            </Box>
          </Flex>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={469}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading fontSize={22} fontWeight="bold" textAlign="center" mb={7}>
              {title
                ? title
                : video.group.find((g) => g.id === videoGroupId)?.title}
            </Heading>
            <video
              src={video.fullVideo?.url}
              controls
              onContextMenu={(e) => e.preventDefault()}
              controlsList="nodownload"
            ></video>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default AExerciceCardBase

import React, { ChangeEvent, useId } from "react"
import { Link as RouterLink, useParams } from "react-router-dom"
import arrowBack from "../../../../assets/arrow-back.svg"
import Coordinates from "./components/Coordinates"
import PatientAdvice from "./components/PatientAdvice"
import Reason from "./components/Reason"
import SelectedZonesPathologies from "./components/SelectedZonesPathologies/SelectedZonesPathologies"
import ZonesPathologies from "./components/ZonesPathologies/ZonesPathologies"
import Questionnaire from "./components/Questionnaire/Questionnaire"
import { PATHOLOGIES_MOCK, ZONES_MOCK } from "../../newAppointment.mock"
import AButton from "../../../../components/AButton"
import SelectMainReason from "./components/SelectMainReason"
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { ZonePathologieType } from "../../newAppointment.mock"
import { getPatient, PatientType } from "../../../../api/patient"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "AppContext"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { retryPatientNoAccessRight } from "utils/patientNoAccessRight"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import useOnPatientNoAccessRight from "hooks/useOnPatientNoAccessRight"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import InternalNotes from "pages/NewAppointment/components/SelectReason/components/InternalNotes"
import { BabyMediaTag } from "api/media"
import BabyMediaTags from "pages/NewAppointment/components/SelectReason/components/BabyMediaTags/BabyMediaTags"
import SelectedBabyMediaTags from "pages/NewAppointment/components/SelectReason/components/SelectedBabyMediaTags/SelectedBabyMediaTags"

interface IProps {
  patient: PatientType | undefined
  setPatient: React.Dispatch<React.SetStateAction<PatientType | undefined>>
  reason: "zones" | "pathologies" | "no" | "no-exos" | null
  setReason: (reason: "zones" | "pathologies" | "no" | "no-exos" | null) => void
  zones: ZonePathologieType[]
  setZones: React.Dispatch<React.SetStateAction<ZonePathologieType[]>>
  pathologies: ZonePathologieType[]
  setPathologies: React.Dispatch<React.SetStateAction<ZonePathologieType[]>>
  mainReason: ZonePathologieType | null
  setMainReason: React.Dispatch<React.SetStateAction<ZonePathologieType | null>>
  setStep: React.Dispatch<React.SetStateAction<number>>
  advice: string | null
  setAdvice: React.Dispatch<React.SetStateAction<string | null>>
  pain: number
  setPain: React.Dispatch<React.SetStateAction<number>>
  stress: number
  setStress: React.Dispatch<React.SetStateAction<number>>
  frequency: number
  setFrequency: React.Dispatch<React.SetStateAction<number>>
  mobility: number
  setMobility: React.Dispatch<React.SetStateAction<number>>
  internalNote: string | null
  setInternalNote: React.Dispatch<React.SetStateAction<string | null>>
  secondaryInternalNote: string | null
  setSecondaryInternalNote: React.Dispatch<React.SetStateAction<string | null>>
  isBabySwitchOn: boolean
  setIsBabySwitchOn: React.Dispatch<React.SetStateAction<boolean>>
  selectedBabyMediaTags: BabyMediaTag[] | null
  setSelectedBabyMediaTags: React.Dispatch<
    React.SetStateAction<BabyMediaTag[] | null>
  >
  babyMediaTags: BabyMediaTag[]
}

const SelectReason: React.FC<IProps> = ({
  patient,
  setPatient,
  reason,
  setReason,
  zones,
  setZones,
  pathologies,
  setPathologies,
  mainReason,
  setMainReason,
  setStep,
  advice,
  setAdvice,
  pain,
  setPain,
  stress,
  setStress,
  frequency,
  setFrequency,
  mobility,
  setMobility,
  internalNote,
  setInternalNote,
  secondaryInternalNote,
  setSecondaryInternalNote,
  isBabySwitchOn,
  setIsBabySwitchOn,
  selectedBabyMediaTags,
  setSelectedBabyMediaTags,
  babyMediaTags,
}) => {
  const { patientId } = useParams()
  const { currentScope } = useAppContext()
  const onPatientNoAccessRight = useOnPatientNoAccessRight()
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()
  const getPatientParams = { patientId, ...getCurrentScopeParams(currentScope) }
  const switchStatusId = useId()
  const {
    onOpen: onBabyPopoverOpen,
    onClose: onBabyPopoverClose,
    isOpen: isBabyPopoverOpen,
  } = useDisclosure()

  const onBabySwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsBabySwitchOn(e.target.checked)
    if (isBabySwitchOn) {
      onBabyPopoverClose()
      return
    }
    if (!isBabySwitchOn && !isBabyPopoverOpen) {
      onBabyPopoverOpen()
    }
  }

  useQuery<PatientType>(
    ["getPatient", patientId],
    () => getPatient(getPatientParams),
    {
      enabled: !!patientId,
      retry: (failureCount, error: any) =>
        retryPatientNoAccessRight(failureCount, error) &&
        retryCollaboratorUnauthorized(failureCount, error),
      onSuccess: (data) => {
        setPatient(data)
      },
      onError: (error: any) => {
        onCollaboratorUnauthorized(error)
        onPatientNoAccessRight(error)
      },
    }
  )

  const isNextStepButtonDisabled =
    (isBabySwitchOn &&
      selectedBabyMediaTags !== null &&
      selectedBabyMediaTags.length === 0) ||
    (!isBabySwitchOn &&
      mainReason === null &&
      reason !== "no" &&
      reason !== "no-exos")

  const handleNextStepForReason = () => {
    if (isBabySwitchOn) {
      setReason("no-exos")
      setStep(2)
      return
    }
    if (reason === "no-exos") {
      setStep(2)
      return
    }
    setStep(1)
  }

  if (!patient) return null
  return (
    <Box flex={1} mr={6}>
      <Link as={RouterLink} to={`/patients/${patientId}`}>
        <Flex alignItems="center">
          <Image src={arrowBack} mr={2} />
          <Text>Retour</Text>
        </Flex>
      </Link>
      <Flex
        direction={"row"}
        width="full"
        gap={4}
        alignItems={"start"}
        mb={6}
        justifyContent="space-between"
        w="70%"
        mt={7}
      >
        <Heading as="h1" fontSize={28} color="tertiary.500">
          Créer une consultation
        </Heading>
        <Popover
          isOpen={isBabyPopoverOpen}
          onOpen={onBabyPopoverOpen}
          onClose={onBabyPopoverClose}
          placement="bottom"
        >
          <PopoverTrigger>
            <Switch
              as="div"
              id={switchStatusId}
              whiteSpace="nowrap"
              colorScheme="orange"
              isChecked={isBabySwitchOn}
              onChange={onBabySwitchChange}
              height="fit-content"
              bg="white"
              py="12px"
              px="16px"
              display="flex"
              alignItems="center"
              borderRadius="xl"
              fontWeight={"bold"}
            >
              Consultation nourrisson
            </Switch>
          </PopoverTrigger>
          <PopoverContent
            backgroundColor={"common.800"}
            px={2}
            py={4}
            maxW={240}
            mt={5}
            ml={44}
          >
            <PopoverBody>
              <Flex direction={"column"} alignItems={"start"} gap={4}>
                <Text color={"white"}>
                  La consultation nourrisson est activée : les vidéos sont
                  adaptées à ce type de consultation
                </Text>
                <Button
                  variant="link"
                  textDecoration={"underline"}
                  color={"white"}
                  onClick={onBabyPopoverClose}
                >
                  C'est compris
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      <Coordinates patient={patient} />
      {!isBabySwitchOn && (
        <Questionnaire
          pain={pain}
          setPain={setPain}
          stress={stress}
          setStress={setStress}
          frequency={frequency}
          setFrequency={setFrequency}
          mobility={mobility}
          setMobility={setMobility}
        />
      )}

      <InternalNotes
        internalNote={internalNote}
        setInternalNote={setInternalNote}
        secondaryInternalNote={secondaryInternalNote}
        setSecondaryInternalNote={setSecondaryInternalNote}
      />
      <PatientAdvice advice={advice} setAdvice={setAdvice} />

      <Reason
        reason={reason}
        setReason={setReason}
        isBabySwitchOn={isBabySwitchOn}
        selectedBabyMediaTags={selectedBabyMediaTags}
        setSelectedBabyMediaTags={setSelectedBabyMediaTags}
      />
      {isBabySwitchOn ? (
        <Flex direction={"column"}>
          <BabyMediaTags
            selectedBabyMediaTags={selectedBabyMediaTags}
            setSelectedBabyMediaTags={setSelectedBabyMediaTags}
            babyMediaTags={babyMediaTags}
          />
          {selectedBabyMediaTags && (
            <SelectedBabyMediaTags
              selectedBabyMediaTags={selectedBabyMediaTags}
              setSelectedBabyMediaTags={setSelectedBabyMediaTags}
            />
          )}
        </Flex>
      ) : (
        <>
          <Flex alignItems={"center"}>
            <Box w="657px">
              {reason === "zones" && (
                <ZonesPathologies
                  title="De quelle zone s'agit-il ?"
                  datas={ZONES_MOCK}
                  selectedDatas={zones}
                  setSelectedDatas={setZones}
                />
              )}
              {reason === "pathologies" && (
                <ZonesPathologies
                  title="De quelle symptomatologie s'agit-il ?"
                  datas={PATHOLOGIES_MOCK}
                  selectedDatas={pathologies}
                  setSelectedDatas={setPathologies}
                />
              )}
            </Box>
            <Box flex={1}>
              {(reason === "zones" || reason === "pathologies") && (
                <SelectedZonesPathologies
                  pathologies={pathologies}
                  zones={zones}
                  setPathologies={setPathologies}
                  setZones={setZones}
                />
              )}
              {zones.length + pathologies.length > 1 && (
                <SelectMainReason
                  mainReason={mainReason}
                  setMainReason={setMainReason}
                  zones={zones}
                  pathologies={pathologies}
                />
              )}
            </Box>
          </Flex>
        </>
      )}
      <Flex
        direction={"column"}
        align={"center"}
        justify="center"
        w={"full"}
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
      >
        <AButton
          text="Suivant"
          px={120}
          py={3}
          onClick={handleNextStepForReason}
          isDisabled={isNextStepButtonDisabled}
        />
      </Flex>
    </Box>
  )
}

export default SelectReason

import { Box, Divider, FormLabel, Textarea } from "@chakra-ui/react"
import React, { useId } from "react"

interface IProps {
  advice: string | null
  setAdvice: React.Dispatch<React.SetStateAction<string | null>>
}

const PatientAdvice: React.FC<IProps> = ({ advice, setAdvice }) => {
  const textareaId = useId()
  return (
    <Box mb={12}>
      <Box mb={12} w="70%">
        <FormLabel
          fontFamily="Montserrat"
          fontWeight="bold"
          htmlFor={textareaId}
          fontSize={18}
          mb={4}
        >
          Recommandations écrites pour le patient
        </FormLabel>
        <Textarea
          id={textareaId}
          placeholder="Écrivez vos commentaires ici qui seront visibles pour le patient"
          bg="secondary.200"
          border="1px solid"
          borderColor={"secondary.500"}
          value={advice || ""}
          onChange={(e) => setAdvice(e.target.value)}
        />
      </Box>
      <Divider orientation="horizontal" borderColor="primary.300" />
    </Box>
  )
}

export default PatientAdvice

import {
  deleteFavoriteProgram,
  FavoriteProgram,
  SelectedExerciceType,
  updateFavoriteProgram,
} from "api/appointmentReason"
import { Flex, Heading, Accordion, Box, IconButton } from "@chakra-ui/react"
import AButton from "components/AButton"
import FavoriteProgramCard from "./components/FavoriteProgramCard"
import { useState } from "react"
import NewFavoriteProgram from "pages/NewAppointment/components/SelectExercices/components/FavoritePrograms/components/NewFavoriteProgramCard"
import { useEditingFavoritesContext } from "components/EditingFavoritesContext/EditingFavoritesContext"
import { AddIcon } from "@chakra-ui/icons"
import EditFavoriteProgramCard from "./components/EditFavoriteProgramCard"
import { Form, Formik, FormikHelpers } from "formik"
import { FAVORITE_VALIDATION_SCHEMA } from "constants/schemas"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import useToast from "hooks/useToast"
import favoriteProgramsQueryKeys from "constants/queryKeys/favoritePrograms"

// COMPONENTS
interface FavoriteProgramProps {
  listOfFavorites: FavoriteProgram[]
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  currentReasonId?: number
}

const FavoritePrograms = ({
  listOfFavorites,
  selectedExercices,
  setSelectedExercices,
  currentReasonId,
}: FavoriteProgramProps) => {
  const [newProgram, setNewProgram] = useState<FavoriteProgram | undefined>(
    undefined
  )

  const updateFavoriteProgramMutation = useMutation(updateFavoriteProgram)
  const deleteFavoriteProgramMutation = useMutation(deleteFavoriteProgram)
  const toast = useToast()

  const {
    onEditFavoriteProgram,
    currentFavoriteProgramId,
    onDone,
    isEditing,
    currentFavorites,
  } = useEditingFavoritesContext()

  const onNewProgram = () => {
    const favLength = listOfFavorites.length
    const newFavoriteProgram = {
      id: "new",
      title: `Favori ${(favLength + 1).toString()}`,
      trainingVideos: [],
    }
    setNewProgram(newFavoriteProgram)
    onEditFavoriteProgram(newFavoriteProgram)
  }

  const hasNewProgram = newProgram !== undefined
  const queryClient = useQueryClient()

  const onCancel = () => {
    onDone()
  }
  const onSubmit = (
    { id, title }: FavoriteProgram,
    { setSubmitting }: FormikHelpers<FavoriteProgram>
  ) => {
    updateFavoriteProgramMutation.mutate(
      {
        id,
        title,
        trainingVideos: currentFavorites,
      },
      {
        onSuccess: async () => {
          const rightQuery = currentReasonId
            ? favoriteProgramsQueryKeys.byReason(currentReasonId)
            : favoriteProgramsQueryKeys.all
          await queryClient.invalidateQueries({
            queryKey: rightQuery,
          })
          toast({
            status: "success",
            title: "Favori modifié avec succès",
          })
        },
        onError: () => {
          setSubmitting(false)
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
      }
    )
    onDone()
  }

  const onDelete = () => {
    //We check if the id is a number, because it can also be a string when it is new
    if (typeof currentFavoriteProgramId === "number") {
      deleteFavoriteProgramMutation.mutate(currentFavoriteProgramId, {
        onSuccess: async () => {
          const rightQuery = currentReasonId
            ? favoriteProgramsQueryKeys.byReason(currentReasonId)
            : favoriteProgramsQueryKeys.all

          await queryClient.invalidateQueries({
            queryKey: rightQuery,
          })
          onDone()
          toast({
            status: "success",
            title: "Favori supprimé avec succès",
          })
        },
        onError: () => {
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
      })
    }
  }

  const getIsEdited = (id: FavoriteProgram["id"]) =>
    id === currentFavoriteProgramId

  if (listOfFavorites?.length === 0 && !hasNewProgram) {
    return (
      <Flex
        bg="secondary.200"
        p={4}
        borderRadius={9}
        mb={6}
        direction={"column"}
        align={"center"}
        gap={3}
      >
        <Heading fontSize={18}>Pas encore de programme favori?</Heading>
        <AButton
          text="Créer un programme"
          onClick={onNewProgram}
          variant="tertiary"
        />
      </Flex>
    )
  }
  return (
    <Box bg="secondary.200" p={4} borderRadius={9} mb={6}>
      <Heading as="h2" fontSize={18} mb={4}>
        Programmes favoris
      </Heading>
      <Accordion allowMultiple defaultIndex={[0]}>
        {listOfFavorites?.map((favoriteProgram) =>
          getIsEdited(favoriteProgram.id) ? (
            <Formik
              initialValues={favoriteProgram}
              validationSchema={FAVORITE_VALIDATION_SCHEMA}
              onSubmit={onSubmit}
              key={favoriteProgram.id}
            >
              <Form>
                <EditFavoriteProgramCard
                  onCancel={onCancel}
                  selectedExercices={selectedExercices}
                  setSelectedExercices={setSelectedExercices}
                  onDelete={onDelete}
                />
              </Form>
            </Formik>
          ) : (
            <FavoriteProgramCard
              key={favoriteProgram.id}
              favoriteProgram={favoriteProgram}
              selectedExercices={selectedExercices}
              setSelectedExercices={setSelectedExercices}
            />
          )
        )}

        {hasNewProgram && (
          <NewFavoriteProgram
            newProgram={newProgram}
            setNewProgram={setNewProgram}
            selectedExercices={selectedExercices}
            setSelectedExercices={setSelectedExercices}
            currentReasonId={currentReasonId}
          />
        )}
      </Accordion>
      <Flex justify="end" align="center" mt={2}>
        <IconButton
          aria-label="Ajouter un programme favori"
          w={10}
          h={10}
          border="1px solid"
          borderColor="primary.500"
          color="primary.500"
          borderRadius="50%"
          isDisabled={hasNewProgram || isEditing}
          onClick={onNewProgram}
        >
          <AddIcon />
        </IconButton>
      </Flex>
    </Box>
  )
}

export default FavoritePrograms

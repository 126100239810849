import {
  Box,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"
import AButton from "../../components/AButton"
import { useQuery } from "@tanstack/react-query"
import { AppointmentType, getAppointments } from "../../api/appointments"
import { isBefore } from "date-fns"
import ATablePagination from "components/Table/ATablePagination/ATablePagination"
import { useState } from "react"
import { CurrentScope, useAppContext } from "AppContext"
import appointmentsQueryKeys from "constants/queryKeys/appointments"
import OsteoFilter from "pages/RecentAppointments/components/OsteoFilter"
import { AcademieOsteoType } from "api/academie"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { retryCollaboratorUnauthorized } from "utils/collaboratorUnauthorized"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"

// HELPERS
const getAppointmentsParams = (
  currentScope: CurrentScope | null,
  page: number,
  osteos?: number[]
) => {
  if (currentScope?.type === "academy") {
    return {
      osteos,
      page,
      ...getCurrentScopeParams(currentScope),
    }
  }
  if (currentScope?.type === "center") {
    return {
      osteos,
      page,
      ...getCurrentScopeParams(currentScope),
    }
  }
  return { page }
}

// COMPONENTS
const RecentAppointments = () => {
  const { currentScope } = useAppContext()
  const [page, setPage] = useState(1)
  const [selectedOsteos, setSelectedOsteos] = useState<AcademieOsteoType[]>([])
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()

  const selectedOsteoIds =
    selectedOsteos.length === 0 ? undefined : selectedOsteos.map(({ id }) => id)

  const { data, isLoading } = useQuery(
    appointmentsQueryKeys.list(currentScope?.id, selectedOsteoIds, page),
    () =>
      getAppointments(
        getAppointmentsParams(currentScope, page, selectedOsteoIds)
      ),
    {
      retry: retryCollaboratorUnauthorized,
      onError: onCollaboratorUnauthorized,
    }
  )

  const list = data?.results ?? []
  const pageCount = data?.count.totalPage ?? 0

  return (
    <Box>
      <Heading
        as="h1"
        fontWeight={700}
        fontSize={28}
        mb={8}
        color="tertiary.500"
      >
        Consultations récentes
      </Heading>
      <Box my={6}>
        <OsteoFilter
          selectedItems={selectedOsteos}
          setSelectedItems={setSelectedOsteos}
        />
      </Box>
      <TableContainer>
        <Table size="lg" background="white" borderRadius={10}>
          <Thead>
            <Tr>
              <Th
                color="common.700"
                textTransform="none"
                fontFamily="Mulish"
                textAlign="center"
              >
                Jour
              </Th>

              <Th
                color="common.700"
                textTransform="none"
                fontFamily="Mulish"
                textAlign="center"
              >
                Nom
              </Th>

              <Th
                color="common.700"
                textTransform="none"
                fontFamily="Mulish"
                textAlign="center"
              >
                Prénom
              </Th>
              {currentScope?.id && (
                <Th
                  color="common.700"
                  textTransform="none"
                  fontFamily="Mulish"
                  textAlign="center"
                >
                  Vu par
                </Th>
              )}
              <Th
                color="common.700"
                textTransform="none"
                fontFamily="Mulish"
                textAlign="center"
              >
                Consultations
              </Th>

              <Th
                color="common.700"
                textTransform="none"
                fontFamily="Mulish"
                textAlign="center"
              >
                Fiche patient
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {list
              ?.filter((item: AppointmentType) => item.patient)
              ?.sort((a, b) =>
                isBefore(new Date(a.createdAt), new Date(b.createdAt)) ? 1 : -1
              )
              .map((item: AppointmentType) => (
                <Tr key={item.id} border="none">
                  <Td fontSize={14} border="none" textAlign="center">
                    {item.dateFormatted}
                  </Td>
                  <Td fontSize={14} border="none" textAlign="center">
                    {item.patient.lastname}
                  </Td>
                  <Td fontSize={14} border="none" textAlign="center">
                    {item.patient.firstname}
                  </Td>
                  {currentScope?.id && (
                    <Td fontSize={14} border="none" textAlign="center">
                      {item.osteo.firstname} {item.osteo.lastname}
                    </Td>
                  )}
                  <Td fontSize={14} border="none" textAlign="center">
                    <Link
                      px={4}
                      py={2}
                      as={RouterLink}
                      to={`/patients/${item.patient.id}/appointments/${item.id}`}
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <AButton
                        variant="secondary"
                        text="Voir la consultation"
                        fontWeight={400}
                      />
                    </Link>
                  </Td>
                  <Td fontSize={14} border="none" textAlign="center">
                    <Link
                      px={4}
                      py={2}
                      as={RouterLink}
                      to={`/patients/${item.patient.id}`}
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <AButton
                        variant="tertiary"
                        text="Voir le patient"
                        fontWeight={400}
                      />
                    </Link>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {!isLoading && (
        <ATablePagination
          pageCount={pageCount}
          onPageChange={setPage}
          page={page}
        />
      )}
    </Box>
  )
}

export default RecentAppointments

import { useDisclosure } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { getCenterGroup } from "api/centerGroup"
import { StripeProductType } from "api/subscriptions"
import { AdminCenterCurrentScope, useAppContext } from "AppContext"
import BillingCard from "components/Billing/Card"
import CenterBillingProration from "components/Billing/Center/BillingProration"
import { centerGroupQueryKeys } from "pages/Center/constants/queryKeys"

// HELPERS
const getOtherBillingSubscription = (
  currentInterval?: "year" | "month",
  stripeProducts?: StripeProductType[]
) => {
  if (!currentInterval) {
    return undefined
  }
  const nextInterval = currentInterval === "month" ? "year" : "month"
  const otherBillingSubscriptionMetadataId = `business_${nextInterval}ly`
  return stripeProducts?.find(
    ({ metadata }) => metadata.id === otherBillingSubscriptionMetadataId
  )
}

// COMPONENTS
interface CenterSubscriptionBillingProps {
  stripeProducts?: StripeProductType[]
  isLoadingStripeProducts: boolean
}
const CenterSubscriptionBilling = ({
  stripeProducts,
  isLoadingStripeProducts,
}: CenterSubscriptionBillingProps) => {
  const { currentScope } = useAppContext()
  const { data: centerGroup, isLoading: isLoadingCenterGroup } = useQuery(
    centerGroupQueryKeys.current(
      (currentScope as AdminCenterCurrentScope)?.centerGroupId
    ),
    () =>
      getCenterGroup((currentScope as AdminCenterCurrentScope).centerGroupId)
  )
  const subscription = centerGroup?.subscription

  const { isOpen, onOpen, onClose } = useDisclosure()

  const currentInterval = subscription?.plan.interval

  const nextStripeProduct = getOtherBillingSubscription(
    currentInterval,
    stripeProducts
  )

  if (!subscription) {
    return null
  }

  return (
    <BillingCard
      discount={`10€ / ${
        subscription.plan.interval === "year"
          ? "an / thérapeute"
          : "mois / thérapeute"
      }`}
      subscription={subscription}
      isLoading={isLoadingStripeProducts && isLoadingCenterGroup}
      onClick={onOpen}
    >
      {nextStripeProduct && (
        <CenterBillingProration
          isOpen={isOpen}
          onClose={onClose}
          subscription={subscription!}
          nextStripeProduct={nextStripeProduct}
        />
      )}
    </BillingCard>
  )
}

export default CenterSubscriptionBilling

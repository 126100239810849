import { Flex, FlexProps, Image } from "@chakra-ui/react"
import { ReactNode } from "react"

interface AAvatarProps extends FlexProps {
  isSmall?: boolean
  avatar?: AvatarType
  fallback?: ReactNode
  alt: string
}

const AAvatar = ({
  isSmall = false,
  avatar,
  fallback,
  alt,
  ...props
}: AAvatarProps) => {
  if (avatar?.url) {
    return (
      <Flex w={isSmall ? 6 : 8} h={isSmall ? 6 : 8} mr={2} {...props}>
        <Image
          src={avatar.url}
          w="100%"
          h="100%"
          borderRadius="50%"
          alt={alt}
        />
      </Flex>
    )
  }
  return <>{fallback}</>
}

export default AAvatar

import { AddIcon } from "@chakra-ui/icons"
import { Box, Divider, FormLabel, Textarea } from "@chakra-ui/react"
import AButton from "components/AButton"
import React, { useId } from "react"

type InternalNotesProps = {
  internalNote: string | null
  setInternalNote: React.Dispatch<React.SetStateAction<string | null>>
  secondaryInternalNote: string | null
  setSecondaryInternalNote: React.Dispatch<React.SetStateAction<string | null>>
}

const InternalNotes = ({
  internalNote,
  setInternalNote,
  secondaryInternalNote,
  setSecondaryInternalNote,
}: InternalNotesProps) => {
  const [hasInternalNote, setHasInternalNote] = React.useState(false)
  const [hasSecondaryInternalNote, setHasSecondaryInternalNote] =
    React.useState(false)
  const textareaId = useId()
  return (
    <Box>
      {hasInternalNote ? (
        <Box mb={12} w="70%">
          <FormLabel
            fontFamily="Montserrat"
            fontWeight="bold"
            htmlFor={textareaId}
            fontSize={18}
            mb={4}
          >
            Note complémentaire (non visible par le patient)
          </FormLabel>
          <Textarea
            id={textareaId}
            placeholder="Note complémentaire..."
            value={internalNote || ""}
            bg="white"
            onChange={(e) => setInternalNote(e.target.value)}
          />
          {!hasSecondaryInternalNote && (
            <AButton
              text="Ajouter une deuxième note interne"
              mt={12}
              onClick={() => setHasSecondaryInternalNote(true)}
              leftIcon={<AddIcon w={3} h={3} />}
              variant="tertiary"
              borderColor={"tertiary.500"}
              color={"tertiary.500"}
            />
          )}
        </Box>
      ) : (
        <AButton
          text="Ajouter une note interne"
          mb={12}
          onClick={() => setHasInternalNote(true)}
          leftIcon={<AddIcon w={3} h={3} />}
          variant="tertiary"
          borderColor={"tertiary.500"}
          color={"tertiary.500"}
        />
      )}

      {hasSecondaryInternalNote && (
        <Box mb={12} mt={-8} w="70%">
          <Textarea
            id={textareaId}
            placeholder="Note supplémentaire..."
            value={secondaryInternalNote || ""}
            bg="white"
            onChange={(e) => setSecondaryInternalNote(e.target.value)}
          />
        </Box>
      )}
    </Box>
  )
}

export default InternalNotes

import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import React from "react"
import AButton from "../../../../../../components/AButton"
import { SelectedExerciceType } from "api/appointmentReason"
import SelectedExerciceCard from "./components/SelectedExerciceCard"
import { useEditingFavoritesContext } from "components/EditingFavoritesContext/EditingFavoritesContext"

interface IProps {
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  setStep?: React.Dispatch<React.SetStateAction<number>>
  onNext?: () => void
}

const SelectedExercices: React.FC<IProps> = ({
  selectedExercices,
  setSelectedExercices,
  setStep,
  onNext,
}) => {
  const { isEditing } = useEditingFavoritesContext()

  const onNextOrStepOver = () => {
    if (setStep) {
      setStep(2)
    } else if (onNext) {
      onNext()
    }
  }

  const textButton = setStep ? "Suivant" : "Enregistrer les modifications"

  return (
    <div>
      <Box pos="sticky" top={4} maxH={"calc(100vh - 120px)"} overflow="auto">
        <Box
          p={4}
          borderRadius={9}
          bg={isEditing ? "" : "primary.200"}
          opacity={isEditing ? "0.3" : "1"}
          w={427}
          ml={6}
        >
          {selectedExercices.length > 4 && (
            <Box
              pos="absolute"
              transform="translateY(calc(-100% - 30px))"
              p={4}
              bg="secondary.400"
              borderRadius={9}
              w="100%"
              left={0}
            >
              <Text fontSize={14}>
                Nous vous recommandons de limiter la sélection à 4 exercices.
                Cela optimise les chances de voir votre patient réaliser
                réellement sa séance
              </Text>
            </Box>
          )}
          <Heading ml={2} fontSize={18} mb={2}>
            Vidéos sélectionnées ({selectedExercices.length || 0})
          </Heading>
          {selectedExercices.length > 4 && (
            <Text fontSize={14} color="primary.700" ml={2}>
              Vous avez dépassé la limite de 4 exercices maximum recommandés.
            </Text>
          )}
          <Flex
            flexDir="column"
            gap={2}
            mt={4}
            as="ul"
            aria-label="Liste des vidéos sélectionnées"
          >
            {selectedExercices.length > 0 ? (
              selectedExercices.map((selectedExercice, i) => (
                <SelectedExerciceCard
                  key={selectedExercice.id}
                  selectedExercice={selectedExercice}
                  setSelectedExercices={setSelectedExercices}
                  position={i + 1}
                  size={selectedExercices.length}
                  isDisabled={isEditing}
                />
              ))
            ) : (
              <Text ml={3} fontSize={12}>
                Aucune vidéo sélectionnée
              </Text>
            )}
          </Flex>
        </Box>
      </Box>
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
      >
        <AButton
          text={textButton}
          px={120}
          py={3}
          fontFamily="Montserrat"
          onClick={onNextOrStepOver}
        />
      </Flex>
    </div>
  )
}

export default SelectedExercices

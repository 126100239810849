import {
  SelectedDiscoverType,
  SelectedExerciceType,
} from "api/appointmentReason"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import React, { useEffect, useState } from "react"
import { PatientType, TraininScheduleType } from "../../api/patient"
import SelectExercices from "./components/SelectExercices/SelectExercices"
import SelectReason from "./components/SelectReason/SelectReason"
import SelectSchedule from "./components/SelectSchedule/SelectSchedule"
import SelectDiscovers from "pages/NewAppointment/components/SelectDiscovers/SelectDiscovers"
import { BabyMediaTag } from "api/media"
import { useAppContext } from "AppContext"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import { useMutation } from "@tanstack/react-query"
import { newAppointment } from "api/appointments"
import { format } from "date-fns"
import { useNavigate } from "react-router-dom"

const NewAppointment = () => {
  const [step, setStep] = useState(0)
  const [patient, setPatient] = React.useState<PatientType | undefined>(
    undefined
  )
  const [advice, setAdvice] = React.useState<string | null>(null)
  const [internalNote, setInternalNote] = React.useState<string | null>(null)
  const [secondaryInternalNote, setSecondaryInternalNote] = React.useState<
    string | null
  >(null)
  const [pain, setPain] = React.useState(0)
  const [frequency, setFrequency] = React.useState(0)
  const [mobility, setMobility] = React.useState(0)
  const [stress, setStress] = React.useState(0)
  const [reason, setReason] = useState<
    "zones" | "pathologies" | "no" | "no-exos" | null
  >(null)
  const [zones, setZones] = useState<ZonePathologieType[]>([])
  const [pathologies, setPathologies] = useState<ZonePathologieType[]>([])
  const [mainReason, setMainReason] = useState<ZonePathologieType | null>(null)
  const [selectedExercices, setSelectedExercices] = useState<
    SelectedExerciceType[]
  >([])
  const [selectedDiscovers, setSelectedDiscovers] = useState<
    SelectedDiscoverType[]
  >([])
  const [trainingSchedule, setTrainingSchedule] = React.useState<
    TraininScheduleType[]
  >(patient?.trainingSchedule || [])
  const [isBabySwitchOn, setIsBabySwitchOn] = useState(false)
  const [selectedBabyMediaTags, setSelectedBabyMediaTags] = useState<
    BabyMediaTag[] | null
  >([])
  const babyMediaTags = [
    { key: "bebe_tete", name: "Crâne" },
    { key: "bebe_ventre", name: "Système digestif" },
    { key: "bebe_portage", name: "Portage" },
    { key: "bebe_moteur", name: "Éveil et développement moteur" },
  ]

  useEffect(() => {
    if (zones.length + pathologies.length === 1) {
      setMainReason(zones.length === 1 ? zones[0] : pathologies[0])
    } else setMainReason(null)
  }, [zones, pathologies])

  useEffect(() => {
    setTrainingSchedule(patient?.trainingSchedule || [])
  }, [patient])

  const setReasonAndReset = (
    reason: "zones" | "pathologies" | "no" | "no-exos" | null
  ) => {
    setReason(reason)
    if (reason === "no" || reason === "no-exos") {
      setMainReason(null)
      setPathologies([])
      setZones([])
    }
  }

  const { user, currentScope } = useAppContext()
  const newAppointmentExtraParams = getCurrentScopeParams(currentScope)
  const newAppointmentMutation = useMutation(newAppointment)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const handleNewAppointment = () => {
    setIsSubmitting(true)
    const dateInTwoMonths = new Date()
    dateInTwoMonths.setMonth(dateInTwoMonths.getMonth() + 2)

    if (!user || !patient?.id) {
      setIsSubmitting(false)
      return
    }

    const reasons = mainReason === null ? null : [zones, pathologies].flat()

    const reasonsTemp: (number | undefined)[] = (reasons || []).map((reason) =>
      reason !== null ? reason.id : undefined
    )

    const mainReasonTemp: number | undefined =
      mainReason !== null ? mainReason.id : undefined

    const playlist =
      mainReason === null
        ? {
            author: user?.osteo.id,
            expiry: format(dateInTwoMonths, "yyyy-MM-dd"),
          }
        : {
            author: user?.osteo.id,
            expiry: format(dateInTwoMonths, "yyyy-MM-dd"),
            mainReason: mainReasonTemp,
            reasons: reasonsTemp,
          }

    const explorerMedias =
      selectedDiscovers.length === 0
        ? null
        : selectedDiscovers.map(({ id }) => id)

    const checkup =
      mainReason === null
        ? {
            patient: patient.id,
            pain,
            stress,
            frequency,
            mobility,
          }
        : {
            patient: patient.id,
            pain,
            stress,
            frequency,
            mobility,
            mainReason: mainReasonTemp,
            reasons: reasonsTemp,
          }
    newAppointmentMutation.mutate(
      {
        playlist,
        appointment: {
          osteo: user?.osteo.id,
          ...newAppointmentExtraParams,
          patient: patient.id,
          osteoAdvice:
            advice ||
            `Pensez à faire régulièrement vos exercices pour maximiser les effets de votre consultation.`,
          meetingDate: format(new Date(), "yyyy-MM-dd"),
          internalNote,
          secondaryInternalNote,
          explorerMedias,
        },
        videoExercices: selectedExercices.map((exercice) => ({
          duration: exercice.duration,
          videoGroup: exercice.videoGroup,
          osteoSelection: exercice.id,
        })),
        checkup,
        trainingSchedule,
        patientId: patient.id,
      },
      {
        onSuccess: ({ id }) => {
          navigate(`/patients/${patient.id}/appointments/${id}`)
          setIsSubmitting(false)
        },
        onError: () => {
          setIsSubmitting(false)
        },
      }
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])
  if (step === 0)
    return (
      <SelectReason
        patient={patient}
        setPatient={setPatient}
        reason={reason}
        setReason={setReasonAndReset}
        zones={zones}
        setZones={setZones}
        pathologies={pathologies}
        setPathologies={setPathologies}
        mainReason={mainReason}
        setMainReason={setMainReason}
        setStep={setStep}
        advice={advice}
        setAdvice={setAdvice}
        pain={pain}
        setPain={setPain}
        stress={stress}
        setStress={setStress}
        frequency={frequency}
        setFrequency={setFrequency}
        mobility={mobility}
        setMobility={setMobility}
        internalNote={internalNote}
        setInternalNote={setInternalNote}
        secondaryInternalNote={secondaryInternalNote}
        setSecondaryInternalNote={setSecondaryInternalNote}
        isBabySwitchOn={isBabySwitchOn}
        setIsBabySwitchOn={setIsBabySwitchOn}
        selectedBabyMediaTags={selectedBabyMediaTags}
        setSelectedBabyMediaTags={setSelectedBabyMediaTags}
        babyMediaTags={babyMediaTags}
      />
    )

  if (!mainReason && reason !== "no" && reason !== "no-exos") return null

  if (step === 1)
    return mainReason === null ? (
      <SelectExercices
        setStep={setStep}
        selectedExercices={selectedExercices}
        setSelectedExercices={setSelectedExercices}
      />
    ) : (
      <SelectExercices
        setStep={setStep}
        reasons={[
          mainReason,
          zones.filter((zone) => zone.id !== mainReason?.id),
          pathologies.filter((pathologie) => pathologie.id !== mainReason?.id),
        ].flat()}
        selectedExercices={selectedExercices}
        setSelectedExercices={setSelectedExercices}
      />
    )

  if (step === 2)
    return (
      <SelectDiscovers
        setStep={setStep}
        reason={reason}
        selectedDiscovers={selectedDiscovers}
        setSelectedDiscovers={setSelectedDiscovers}
        isBabySwitchOn={isBabySwitchOn}
        selectedBabyMediaTags={selectedBabyMediaTags}
        babyMediaTags={babyMediaTags}
        handleNewAppointment={handleNewAppointment}
        isSubmitting={isSubmitting}
      />
    )

  return (
    <SelectSchedule
      setStep={setStep}
      trainingSchedule={trainingSchedule}
      setTrainingSchedule={setTrainingSchedule}
      handleNewAppointment={handleNewAppointment}
      isSubmitting={isSubmitting}
    />
  )
}

export default NewAppointment

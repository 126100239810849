import {
  Box,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"
import { format } from "date-fns"
import { useQuery } from "@tanstack/react-query"
import {
  getOsteoByAppointmentsCount,
  OsteoByAppointmentsCount,
} from "api/osteo"
import ATablePagination from "components/Table/ATablePagination/ATablePagination"
import { useState } from "react"
import { CSVLink } from "react-csv"
import AButton from "components/AButton"
import getUtcDate from "utils/getUtcDate"

const Statistiques = () => {
  const [page, setPage] = useState(1)

  const headers = [
    { id: 1, label: "Nom", key: "lastname" },
    { id: 2, label: "Prénom", key: "firstname" },
    { id: 3, label: "Mail", key: "email" },
    { id: 4, label: "Téléphone", key: "telephone" },
    { id: 5, label: "Consultations", key: "appointmentsCount" },
    { id: 6, label: "Est abonné", key: "isSubscribed" },
    { id: 7, label: "Compte créé le", key: "createdAt" },
  ]

  const [filteredHeader, setFilteredHeader] = useState({
    id: 1,
    label: "Nom",
    key: "lastname",
    order: "asc",
  })

  const { data, isLoading } = useQuery(
    ["getOsteoByAppointmentsCount", page, filteredHeader],
    () => getOsteoByAppointmentsCount(page, filteredHeader)
  )
  const { data: allDatas, isLoading: isLoadingAllDatas } = useQuery(
    ["getOsteoByAppointmentsCount", -1, filteredHeader],
    () => getOsteoByAppointmentsCount(-1, filteredHeader)
  )
  console.log(data)
  const list = data?.data ?? []

  if (isLoading) return <div>Chargement...</div>

  const handleSort = (headerId: number) => {
    setPage(1)
    if (headerId === filteredHeader.id) {
      setFilteredHeader({
        ...filteredHeader,
        order: filteredHeader.order === "asc" ? "desc" : "asc",
      })
      return
    }
    const selectedHeader = headers.find((header) => header.id === headerId)
    if (!selectedHeader) {
      return
    }
    setFilteredHeader({
      ...selectedHeader,
      order: "asc",
    })
  }

  return (
    <Box>
      <Flex justifyContent="space-between" mb={8}>
        <Heading as="h1" fontWeight={700} fontSize={28} color="tertiary.500">
          Consultations récentes
        </Heading>
        {!isLoadingAllDatas && (
          <CSVLink
            data={
              allDatas?.data.map((user) => ({
                lastname: user.osteo.lastname,
                firstname: user.osteo.firstname,
                email: user.email,
                telephone: user.osteo.telephone,
                appointmentsCount: user.appointmentsCount,
                isSubscribed: user.osteo.sessionId === null ? "Non" : "Oui",
                createdAt: format(new Date(user.createdAt), "dd/MM/yyyy"),
              })) ?? []
            }
            headers={headers}
          >
            <AButton text="Télécharger toutes les données" />
          </CSVLink>
        )}
      </Flex>
      <TableContainer>
        <Table size="lg" background="white" borderRadius={10}>
          <Thead>
            <Tr>
              {headers.map((header) => (
                <Th
                  color="common.700"
                  textTransform="none"
                  key={header.id}
                  fontFamily="Mulish"
                  textAlign="center"
                  onClick={() => handleSort(header.id)}
                  cursor="pointer"
                >
                  {header.label}
                  {filteredHeader.id === header.id && (
                    <>
                      {filteredHeader.order === "asc" ? (
                        <ChevronDownIcon boxSize={4} />
                      ) : (
                        <ChevronUpIcon boxSize={4} />
                      )}
                    </>
                  )}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {list.map((item: OsteoByAppointmentsCount) => (
              <Tr key={item.id} border="none">
                <Td fontSize={14} border="none" textAlign="center">
                  {item.osteo.lastname}
                </Td>
                <Td fontSize={14} border="none" textAlign="center">
                  {item.osteo.firstname}
                </Td>
                <Td fontSize={14} border="none" textAlign="center">
                  {item.email}
                </Td>
                <Td fontSize={14} border="none" textAlign="center">
                  {item.osteo.telephone}
                </Td>
                <Td fontSize={14} border="none" textAlign="center">
                  {item.appointmentsCount}
                </Td>
                <Td fontSize={14} border="none" textAlign="center">
                  {item.osteo.sessionId === null ? "Non" : "Oui"}
                </Td>
                <Td fontSize={14} border="none" textAlign="center">
                  {format(getUtcDate(item.createdAt), "dd/MM/yyyy")}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {!isLoading && (
        <ATablePagination
          pageCount={data?.totalPage ?? 0}
          onPageChange={setPage}
          page={page}
        />
      )}
    </Box>
  )
}

export default Statistiques

import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import useToast from "../../hooks/useToast"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useContext, useEffect, useState } from "react"
import {
  getSubscriptions,
  StripeProductType,
  createCheckoutSession,
  createCenterGroupCheckoutSession,
  CurrencyCode,
  getPartner,
  PartnerType,
  getAllSubscriptions,
} from "../../api/subscriptions"
import AButton from "../../components/AButton"
import Cover from "../../assets/andrew-cover.png"
import { useAppContext } from "../../AppContext"
import RgpdContext from "../../components/Rgpd/RgpdContext"
import { subscribeStart } from "../../utils/facebookPixelEvents"
import ALogoutButton from "components/ALogoutButton"
import { sendinblueTrack } from "../../utils/sendinBlueEvents"
import ALogo from "../../components/ALogo"
import StripeProductCard from "components/StripeProductCard"
import CenterSubscribeDialog from "pages/Subscribe/components/CenterSubscribeDialog"
import groupStripeProductsById from "utils/groupStripeProductsById"
import { useCookies } from "react-cookie"
import getCurrencyCodeForCountry from "utils/price/getCurrencyCodeForCountry"

// CONSTANTS
const TAB_INDICES = {
  monthly: 0,
  yearly: 1,
} as const

// COMPONENTS
const Subscribe = () => {
  // start with yearly
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    TAB_INDICES.yearly
  )
  const [selectedProduct, setSelectedProduct] =
    useState<StripeProductType | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data, isLoading } = useQuery<StripeProductType[]>(
    ["getSubscriptions"],
    getSubscriptions
  )

  const onTabChange = (index: number) => {
    setActiveTabIndex(index)
    setSelectedProduct(null)
  }

  const subscriptionsByMetadataId = groupStripeProductsById(data)
  const individualMonthly = subscriptionsByMetadataId["individual_monthly"]
  const individualYearly = subscriptionsByMetadataId["individual_yearly"]
  const businessMonthly = subscriptionsByMetadataId["business_monthly"]
  const businessYearly = subscriptionsByMetadataId["business_yearly"]
  const businessProducts = [businessMonthly, businessYearly]

  const toast = useToast()
  const createCheckoutSessionMutation = useMutation(createCheckoutSession)
  const createCenterGroupCheckoutSessionMutation = useMutation(
    createCenterGroupCheckoutSession
  )
  const { user } = useAppContext()
  const { marginWithBanner } = useContext(RgpdContext)

  const moveToAnnual = () => {
    setActiveTabIndex(TAB_INDICES.yearly)
    setSelectedProduct(businessYearly)
  }

  const [cookies] = useCookies(["partner"])
  const [affiliationCookie] = useCookies(["affiliation"])
  const affiliationNameUrl = affiliationCookie.affiliation

  const subscribe = (seats?: number) => {
    if (user?.email && selectedProduct?.price.id) {
      const mutation = businessProducts.includes(selectedProduct)
        ? createCenterGroupCheckoutSessionMutation
        : createCheckoutSessionMutation

      const currencyCode = getCurrencyCodeForCountry(
        user.osteo.mainAddress?.country
      ) as CurrencyCode
      const priceId = selectedProduct.pricesPerCurrency[currencyCode].id
      let stripeCode =
        partner?.name !== undefined ? partner?.stripeCode : undefined

      if (!shouldApplyPromo()) {
        stripeCode = undefined
      }

      mutation.mutate(
        {
          priceId: priceId,
          email: user.email,
          quantity: seats,
          stripeCode,
          affiliationNameUrl,
        },
        {
          onError: () => {
            toast({
              status: "error",
              title: "Une erreur s'est produite",
            })
          },
          onSuccess: async ({ id, url }: { id: string; url: string }) => {
            subscribeStart()
            window.location.href = url
          },
        }
      )
    }
  }

  useEffect(() => {
    if (user?.email) {
      sendinblueTrack("view_subscriptions", {
        email: user?.email,
      })
    }
  }, [user])

  const formatSubscriptionName = (subscriptionMetadataId: string): string => {
    switch (subscriptionMetadataId) {
      case "individual_monthly":
        return "individuel mensuel"
      case "individual_yearly":
        return "individuel annuel"
      case "business_monthly":
        return "centre mensuel"
      case "business_yearly":
        return "centre annuel"
      default:
        return ""
    }
  }

  const { data: partner, isLoading: partnerIsLoading } = useQuery<PartnerType>(
    ["getStripePartnerCode", cookies.partner],
    () => getPartner(cookies.partner),
    {
      enabled: !!cookies.partner,
      retry: false,
      onError: () => {
        //
      },
    }
  )

  const shouldApplyPromo = () => {
    if (!partner) return false
    if (partner?.subscriptions.length === 0) {
      return true
    }

    if (partner?.subscriptions.length === 1) {
      return (
        selectedProduct?.metadata.id === partner?.subscriptions[0].metadataId
      )
    }

    return partner?.subscriptions.some(
      (subscription) => subscription.metadataId === selectedProduct?.metadata.id
    )
  }

  const { data: allSubcriptions } = useQuery(
    ["getAllSubscriptions"],
    getAllSubscriptions
  )
  const nbrSubscriptions =
    partner?.name !== undefined ? partner?.subscriptions?.length : 0

  if (isLoading)
    return (
      <Center flex={1} height="100vh">
        <Spinner color="primary.500" />
      </Center>
    )
  return (
    <>
      <Flex justifyContent="center">
        <Box flex={1} mb={marginWithBanner} ml={84} mr={16}>
          <ALogo w="60%" />
          <Box>
            {user?.osteo.sessionId ? (
              <Heading
                as="h1"
                fontSize={22}
                fontWeight={700}
                color="tertiary.500"
                mb={55}
              >
                Vos informations de paiement ne sont pas à jour, pour accéder de
                nouveau à votre compte, merci de mettre à jour vos informations
                bancaires
              </Heading>
            ) : (
              <div>
                <Heading
                  as="h1"
                  fontSize={22}
                  fontWeight={700}
                  color="tertiary.500"
                >
                  Vous y êtes presque !
                </Heading>
                <Text mt={2} mb={55} color="tertiary.500">
                  Pour profiter dès à présent de toutes les fonctionnalités
                  Andrew® Pro, souscrivez à la formule d'abonnement de votre
                  choix.
                </Text>
              </div>
            )}

            {!user?.osteo.sessionId && (
              <Flex mb={12} flexDir="column" alignItems="center">
                <Heading
                  as="h2"
                  fontWeight={700}
                  color="common.900"
                  fontSize={22}
                  textAlign="center"
                  display="inline-block"
                  px={4}
                >
                  Essai gratuit de 15 jours
                </Heading>
                <Text color="common.900" fontSize={18} fontWeight={700}>
                  sans engagement
                </Text>
              </Flex>
            )}
            <Heading
              as="h2"
              textAlign="center"
              fontWeight={700}
              fontSize={18}
              color="secondary.900"
            >
              Facturation
            </Heading>
            <Tabs
              index={activeTabIndex}
              mt={2}
              align="center"
              variant="soft-rounded"
              colorScheme="common"
              onChange={onTabChange}
            >
              <TabList
                width="fit-content"
                bg="secondary.500"
                mb={activeTabIndex === TAB_INDICES.monthly ? 2 : 10}
                borderRadius={10}
                p={2}
              >
                <Tab borderRadius={10}>Mensuelle</Tab>
                <Tab borderRadius={10}>Annuelle</Tab>
              </TabList>
              {activeTabIndex === TAB_INDICES.monthly && (
                <Box mb={10}>
                  <Heading
                    as="h3"
                    textAlign="center"
                    fontWeight={700}
                    fontSize={18}
                  >
                    <Text as="span" fontWeight={400}>
                      Économisez{" "}
                    </Text>
                    <Text as="span" color="primary.500" fontWeight={700}>
                      jusqu'à -25%{" "}
                    </Text>
                    <Text as="span" fontWeight={400}>
                      avec la facturation annuelle
                    </Text>
                  </Heading>
                </Box>
              )}
              {partner && (
                <>
                  {(nbrSubscriptions === 0 ||
                    nbrSubscriptions === allSubcriptions.length) && (
                    <Text mb={4} fontWeight="bold">
                      Le partenariat avec {partner.name} est valable sur
                      l'ensemble des abonnements. <br />
                      Une promotion sera automatiquement appliquée lors de
                      l'étape suivante.
                    </Text>
                  )}

                  {nbrSubscriptions === 1 && (
                    <Text mb={4} fontWeight="bold">
                      Le partenariat avec {partner.name} n'est valable que sur
                      l'abonnement{" "}
                      {formatSubscriptionName(
                        partner.subscriptions[0].metadataId
                      )}
                      . <br />
                      Sélectionnez-le et une promotion sera automatiquement
                      appliquée lors de l'étape suivante.
                    </Text>
                  )}

                  {(nbrSubscriptions === 2 || nbrSubscriptions === 3) && (
                    <Text mb={4} fontWeight="bold">
                      Le partenariat avec {partner.name} est valable sur les
                      abonnements suivants :{" "}
                      {partner.subscriptions.map((subscription, index) => (
                        <span key={subscription.id}>
                          {formatSubscriptionName(subscription.metadataId)}
                          {index !== partner.subscriptions.length - 1 && ", "}
                        </span>
                      ))}
                      . <br />
                      Sélectionnez-en un et une promotion sera automatiquement
                      appliquée lors de l'étape suivante.
                    </Text>
                  )}
                </>
              )}

              <TabPanels>
                <TabPanel p={0}>
                  <Flex gap={6}>
                    <StripeProductCard
                      product={individualMonthly}
                      onSelect={setSelectedProduct}
                      isSelected={selectedProduct === individualMonthly}
                      variant="individual"
                    />
                    <StripeProductCard
                      product={businessMonthly}
                      onSelect={setSelectedProduct}
                      isSelected={selectedProduct === businessMonthly}
                      variant="center"
                    />
                  </Flex>
                </TabPanel>
                <TabPanel p={0}>
                  <Flex gap={6}>
                    <StripeProductCard
                      product={individualYearly}
                      onSelect={setSelectedProduct}
                      isSelected={selectedProduct === individualYearly}
                      variant="individual"
                    />
                    <StripeProductCard
                      product={businessYearly}
                      onSelect={setSelectedProduct}
                      isSelected={selectedProduct === businessYearly}
                      variant="center"
                    />
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
          <AButton
            text={
              user?.osteo.sessionId ? "Choisir" : "Démarrer mon essai gratuit"
            }
            px={user?.osteo.sessionId ? 135 : 49}
            ml="50%"
            transform="translateX(-50%)"
            mt={12}
            isDisabled={!selectedProduct}
            onClick={() =>
              businessProducts.includes(selectedProduct!)
                ? onOpen()
                : subscribe()
            }
          />

          {!user?.osteo.sessionId && (
            <Flex justifyContent="center" mt={4}>
              <Text
                maxW={404}
                fontSize={14}
                textAlign="center"
                fontWeight={700}
              >
                Vous ne serez débité•e qu&apos;à la fin de votre période
                d&apos;essai.
                <br /> Vous pourrez annuler sans frais à tout moment si vous
                n&apos;êtes pas satisfait•e.
              </Text>
            </Flex>
          )}

          <ALogoutButton
            mt={8}
            bg="none"
            _hover={{ bg: "none", textDecor: "underline" }}
            _focus={{ bg: "none", textDecor: "underline" }}
            ml="50%"
            transform="translateX(-50%)"
          />
        </Box>
        <Box flex={1} display={{ base: "none", lg: "block" }}>
          <Image
            src={Cover}
            alt="Andrew"
            w="100%"
            minH="100vh"
            h="100%"
            objectFit="cover"
          />
        </Box>
      </Flex>
      {selectedProduct && businessProducts.includes(selectedProduct) && (
        <CenterSubscribeDialog
          moveToAnnual={
            selectedProduct === businessMonthly ? moveToAnnual : undefined
          }
          isOpen={isOpen}
          isLoading={createCheckoutSessionMutation.isLoading}
          onClose={onClose}
          onSubscribe={subscribe}
          product={selectedProduct}
        />
      )}
    </>
  )
}

export default Subscribe

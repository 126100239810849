import { Flex, RadioGroup, Text } from "@chakra-ui/react"
import React from "react"
import ARadio from "../../../../../components/ARadio"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"

interface IProps {
  mainReason: ZonePathologieType | null
  setMainReason: React.Dispatch<React.SetStateAction<ZonePathologieType | null>>
  zones: ZonePathologieType[]
  pathologies: ZonePathologieType[]
}

const SelectMainReason: React.FC<IProps> = ({
  mainReason,
  setMainReason,
  zones,
  pathologies,
}) => {
  const handleMainReasonChange = (value: string) => {
    const findZone = zones.find((zone) => zone.id === parseInt(value))
    const findPathologie = pathologies.find(
      (pathologie) => pathologie.id === parseInt(value)
    )

    if (findZone) {
      setMainReason(findZone)
    } else if (findPathologie) {
      setMainReason(findPathologie)
    }
  }

  return (
    <div>
      <Text fontWeight="bold" mb={18}>
        Vous avez sélectionné plusieurs raisons de consultation, veuillez
        définir votre raison principale
      </Text>
      <RadioGroup
        value={mainReason ? mainReason.id.toString() : undefined}
        onChange={handleMainReasonChange}
      >
        <Flex gap={3} flexWrap="wrap" alignItems="center">
          {zones.map(({ id, name }) => (
            <ARadio
              key={id}
              text={`Zone : ${name}`}
              value={id.toString()}
              isSelected={mainReason?.id === id}
            />
          ))}
          {pathologies.map(({ id, name }) => (
            <ARadio
              key={id}
              text={`Pathologie : ${name}`}
              value={id.toString()}
              isSelected={mainReason?.id === id}
            />
          ))}
        </Flex>
      </RadioGroup>
    </div>
  )
}

export default SelectMainReason

import { useRef, useState } from "react"
import AButton from "./AButton"
import { AddIcon } from "@chakra-ui/icons"
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useDisclosure,
} from "@chakra-ui/react"
import useToast from "../hooks/useToast"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { createOsteo } from "../api/osteo"
import { uploadAvatar } from "api/upload"
import { useMutation } from "@tanstack/react-query"
import getRandomInt from "../utils/getRandomInt"
import { registerUser } from "../api/user"
import AAdressInput from "./AAdressInput"
import { useAppContext } from "AppContext"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import ARegisterAvatarField from "components/Field/ARegisterAvatarField"
import validateAvatarSize from "utils/validators/validateAvatarSize"
import { AVATAR_LABEL } from "constants/avatar"

// COMPONENTS
const AddOsteo = () => {
  const { currentScope } = useAppContext()
  const uploadAvatarMutation = useMutation(uploadAvatar)
  const createOsteoMutation = useMutation(createOsteo)
  const registerUserMutation = useMutation(registerUser)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const createOsteoExtraParams = getCurrentScopeParams(currentScope)

  const [mainAddress, setMainAddress] = useState<AddressType | undefined>(
    undefined
  )
  const [mainAddressError, setMainAddressError] = useState<string | undefined>()
  const [secondaryAddress, setSecondaryAddress] = useState<
    AddressType | undefined
  >(undefined)

  return (
    <div>
      <AButton
        text="Ajouter un ostéopathe"
        variant="secondary"
        px={21}
        py={5}
        leftIcon={<AddIcon w={3} h={3} />}
        fontFamily="Montserrat"
        ml={6}
        onClick={onOpen}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxW={724} borderRadius={8} p={8}>
          <AlertDialogHeader mb={8} p={0} fontSize={18}>
            Ajouter un ostéopathe
          </AlertDialogHeader>

          <Formik
            validationSchema={Yup.object().shape({
              lastname: Yup.string().min(2).required("Nom requis"),
              firstname: Yup.string().required("Prénom requis"),
              email: Yup.string()
                .trim()
                .email("Ceci n'est pas un email")
                .required("Email requis"),
              avatar: Yup.mixed().test(validateAvatarSize),
              urlDoctolib: Yup.string(),
              telephone: Yup.string()
                .required("Ce champ est obligatoire")
                .matches(
                  new RegExp(
                    /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
                  ),
                  "Ceci n'est pas un numéro de téléphone"
                ),
            })}
            initialValues={{
              lastname: "",
              firstname: "",
              email: "",
              avatar: null,
              urlDoctolib: "",
              telephone: "",
            }}
            onSubmit={({ avatar, ...values }, { setSubmitting }) => {
              if (!mainAddress) {
                setMainAddressError("Ce champ est obligatoire")
                setSubmitting(false)
                return
              }
              const password = getRandomInt(10000000, 99999999).toString()
              registerUserMutation.mutate(
                {
                  email: values.email.trim(),
                  password,
                  username: values.email,
                  passwordInitiated: false,
                },
                {
                  onError: (data: any) => {
                    setSubmitting(false)
                    toast({
                      status: "error",
                      title: data.response.data.error.message,
                    })
                  },
                  onSuccess: (data: any) => {
                    if (avatar) {
                      let file = new FormData()
                      file.append("files", avatar)
                      uploadAvatarMutation.mutate(file, {
                        onError: () => {
                          setSubmitting(false)
                          toast({
                            status: "error",
                            title: "Une erreur s'est produite",
                          })
                        },
                        onSuccess: (fileId: string) => {
                          createOsteoMutation.mutate(
                            {
                              ...values,
                              avatar: fileId,
                              userId: data.id,
                              password,
                              isOsteow: true,
                              isTester: false,
                              mainAddress,
                              secondaryAddress,
                              email: values.email.trim(),
                              ...createOsteoExtraParams,
                            },
                            {
                              onSuccess: () => {
                                setSubmitting(false)
                                onClose()
                                toast({
                                  status: "success",
                                  title: "Ostéopathe ajouté avec succès",
                                })
                              },
                              onError: () => {
                                setSubmitting(false)
                                toast({
                                  status: "error",
                                  title: "Une erreur s'est produite",
                                })
                              },
                            }
                          )
                        },
                      })
                    } else {
                      createOsteoMutation.mutate(
                        {
                          ...values,
                          userId: data.id,
                          password,
                          isOsteow: true,
                          isTester: false,
                          mainAddress,
                          secondaryAddress,
                          email: values.email.trim(),
                          ...createOsteoExtraParams,
                        },
                        {
                          onSuccess: () => {
                            setSubmitting(false)
                            onClose()
                            toast({
                              status: "success",
                              title: "Ostéopathe ajouté avec succès",
                            })
                          },
                          onError: () => {
                            setSubmitting(false)
                            toast({
                              status: "error",
                              title: "Une erreur s'est produite",
                            })
                          },
                        }
                      )
                    }
                  },
                }
              )
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <ARegisterAvatarField name="avatar" label={AVATAR_LABEL} />
                <Field name="email">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel mt={6}>Mail *</FormLabel>
                      <Input {...field} placeholder="Email" bg="common.100" />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Flex gap={4} mt={4}>
                  <Field name="lastname">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.lastname && form.touched.lastname
                        }
                      >
                        <FormLabel>Nom *</FormLabel>
                        <Input
                          {...field}
                          placeholder="Nom de famille"
                          bg="common.100"
                        />
                        <FormErrorMessage>
                          {form.errors.lastname}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="firstname">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.firstname && form.touched.firstname
                        }
                      >
                        <FormLabel>Prénom *</FormLabel>
                        <Input
                          {...field}
                          placeholder="Prénom"
                          bg="common.100"
                        />
                        <FormErrorMessage>
                          {form.errors.firstname}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>
                <Field name="telephone">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={
                        form.errors.telephone && form.touched.telephone
                      }
                    >
                      <FormLabel mt={6}>Numéro de téléphone *</FormLabel>
                      <Input {...field} bg="common.100" placeholder="06..." />
                      <FormErrorMessage>
                        {form.errors.telephone}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="urlDoctolib">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={
                        form.errors.urlDoctolib && form.touched.urlDoctolib
                      }
                    >
                      <FormLabel mt={6}>Url doctolib</FormLabel>
                      <Input
                        {...field}
                        bg="common.100"
                        placeholder="www.doctolib.fr/osteopathe/..."
                      />
                      <FormErrorMessage>
                        {form.errors.urlDoctolib}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Flex gap={4} mt={6}>
                  <FormControl isInvalid={mainAddressError !== undefined}>
                    <FormLabel mb="8px">Adresse du lieu d'exercice *</FormLabel>
                    <AAdressInput
                      setAddress={setMainAddress}
                      defaultValue={mainAddress?.name}
                    />
                    <FormErrorMessage>{mainAddressError}</FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex mt={6}>
                  <FormControl>
                    <FormLabel mb="8px">Adresse secondaire</FormLabel>
                    <AAdressInput
                      setAddress={setSecondaryAddress}
                      defaultValue={secondaryAddress?.name}
                    />
                  </FormControl>
                </Flex>
                <AlertDialogFooter justifyContent="flex-end" p={0} mt={14}>
                  <AButton
                    ref={cancelRef}
                    onClick={onClose}
                    text="Annuler"
                    variant="tertiary"
                    py={3}
                    px={27}
                  />
                  <AButton
                    ml={3}
                    text="Enregistrer les modifications"
                    py={3}
                    px={4}
                    type="submit"
                    isLoading={isSubmitting}
                  />
                </AlertDialogFooter>
              </Form>
            )}
          </Formik>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default AddOsteo

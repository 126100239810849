import { CloseIcon } from "@chakra-ui/icons"
import { Button, ButtonProps, Flex, Text } from "@chakra-ui/react"
import React from "react"
import { BabyMediaTag } from "api/media"

const theme = {
  background: "secondary.500",
  _hover: {
    background: "secondary.600",
  },
  color: "common.700",
}

interface IProps extends ButtonProps {
  name: string
  itemId: string
  setSelectedBabyMediaTags: React.Dispatch<
    React.SetStateAction<BabyMediaTag[] | null>
  >
}

const SelectedTag: React.FC<IProps> = ({
  name,
  itemId,
  setSelectedBabyMediaTags,
}) => {
  const handleClick = () => {
    setSelectedBabyMediaTags((prev) => {
      if (prev === null) return null
      return prev.filter((tag) => tag.key !== itemId)
    })
  }

  return (
    <Button
      p={2}
      bg={theme.background}
      _hover={{ bg: theme._hover.background }}
      borderRadius={9}
      rightIcon={<CloseIcon color={theme.color} w={2.5} />}
      onClick={handleClick}
    >
      <Flex
        alignItems="center"
        color={theme.color}
        fontSize={14}
        fontWeight="normal"
      >
        <Text>{name}</Text>
      </Flex>
    </Button>
  )
}

export default SelectedTag

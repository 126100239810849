import { Box, Flex, Heading } from "@chakra-ui/react"
import AExerciceCard from "components/AExerciceCard/AExerciceCard"
import React from "react"
import { SelectedExerciceType, VideoType } from "api/appointmentReason"
import AButton from "components/AButton"
import { useEditingFavoritesContext } from "components/EditingFavoritesContext/EditingFavoritesContext"

interface IProps {
  videos: VideoType[]
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
}

const SuggestedProgramme: React.FC<IProps> = ({
  videos,
  selectedExercices,
  setSelectedExercices,
}) => {
  const handleAddAll = () => {
    const videosToAdd = videos.filter(
      (video) =>
        video.group &&
        video.group.length > 0 &&
        selectedExercices.find((elt) => elt.id === video.id) === undefined
    )
    const newSelectedExercices = videosToAdd.map((video) => ({
      ...video,
      duration: video.defaultDuration || 30,
      videoGroup: video.group[0].id,
    }))
    setSelectedExercices((prev) => [...prev, ...newSelectedExercices])
  }

  const { isEditing } = useEditingFavoritesContext()

  return (
    <Box bg="primary.200" p={4} borderRadius={9} mb={6}>
      <Flex alignItems="center" justifyContent="space-between">
        <Heading fontSize={18}>Programme suggéré</Heading>
        <AButton
          text="Tout ajouter"
          onClick={handleAddAll}
          variant="tertiary"
          isDisabled={isEditing}
        />
      </Flex>
      <Flex mt={4} gap={4} flexWrap="wrap">
        {videos
          .filter((video) => video.group && video.group.length > 0)
          .map((video) => (
            <AExerciceCard
              key={`video_${video.id}`}
              selectedExercices={selectedExercices}
              setSelectedExercices={setSelectedExercices}
              video={video}
              videoGroupId={video.group[0].id}
              title={video.group[0].title}
            />
          ))}
      </Flex>
    </Box>
  )
}

export default SuggestedProgramme

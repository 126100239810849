import { Box, Flex, Heading } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { PatientType } from "../../../../../api/patient"
import ALabelValue from "../../../../../components/ALabelValue"

interface IProps {
  patient: PatientType
}

const Coordinates: React.FC<IProps> = ({ patient }) => {
  return (
    <div>
      <Heading fontSize={18} mb={4}>
        Coordonnées du patient
      </Heading>
      <Box bg="primary.200" padding={4} borderRadius={9} w="70%" mb={6}>
        <Flex>
          <Box mr={20}>
            <ALabelValue label="Nom" value={patient.lastname} />
            <ALabelValue label="Prénom" value={patient.firstname} my={3} />
            <ALabelValue
              label="Date de naissance"
              value={
                patient?.birthday
                  ? format(new Date(patient?.birthday), "dd/MM/yyyy")
                  : ""
              }
            />
          </Box>

          <Box>
            <ALabelValue label="Email" value={patient.account?.email} />
            <ALabelValue label="Téléphone" value={patient.telephone} my={3} />
            <ALabelValue label="Code postal" value={patient.postalCode} />
          </Box>
        </Flex>
      </Box>
    </div>
  )
}

export default Coordinates

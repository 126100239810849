import { Box, Radio, RadioProps } from "@chakra-ui/react"
import React from "react"

interface IProps extends RadioProps {
  text: string
  isSelected: boolean
}

const theme = {
  default: {
    bg: "white",
    color: "common.700",
    _hover: {
      bg: "common.100",
    },
  },
  selected: {
    bg: "primary.500",
    color: "white",
    _hover: { bg: "primary.600" },
  },
}
const ARadio: React.FC<IProps> = ({ text, isSelected, ...props }) => {
  return (
    <Box
      mr={3}
      borderRadius={9}
      p={2}
      bg={theme[isSelected ? "selected" : "default"].bg}
      color={theme[isSelected ? "selected" : "default"].color}
      _hover={{
        bg: theme[isSelected ? "selected" : "default"]._hover.bg,
      }}
    >
      <Radio {...props} colorScheme="primary.500" size="sm">
        {text}
      </Radio>
    </Box>
  )
}

export default ARadio

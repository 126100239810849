import { FlexProps, Text, Flex } from "@chakra-ui/react"
import { MeType } from "api/user"
import AAvatar from "components/AAvatar/AAvatar"

interface AUserAvatarProps extends FlexProps {
  user: MeType | null
  isSmall?: boolean
}

const AUserAvatar = ({ user, isSmall = false, ...props }: AUserAvatarProps) => {
  const initials = `${user?.osteo?.firstname.charAt(
    0
  )}${user?.osteo?.lastname.charAt(0)}`
  return (
    <AAvatar
      alt="Avatar de l'utilisateur"
      fallback={
        <Flex
          w={8}
          h={8}
          borderRadius="50%"
          alignItems="center"
          justifyContent="center"
          bg="secondary.500"
          mr={2}
          aria-label="Avatar par défaut de l'utilisateur"
          {...props}
        >
          <Text>{initials}</Text>
        </Flex>
      }
      avatar={user?.osteo.avatar}
      isSmall={isSmall}
      {...props}
    />
  )
}

export default AUserAvatar

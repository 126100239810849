import { useContext, useState } from "react"
import AButton from "../../components/AButton"

import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import useToast from "../../hooks/useToast"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { createOsteo } from "../../api/osteo"
import { uploadAvatar } from "api/upload"
import { useMutation } from "@tanstack/react-query"
import { registerUser } from "../../api/user"
import Cover from "../../assets/andrew-cover.png"
import { Link as RouterLink } from "react-router-dom"
import AAdressInput from "../../components/AAdressInput"
import RgpdContext from "../../components/Rgpd/RgpdContext"
import AInputPassword from "components/AInputPassword"
import { registerSuccessful } from "../../utils/facebookPixelEvents"
import { sendinBlueIdentify } from "../../utils/sendinBlueEvents"
import ALogo from "../../components/ALogo"
import validateAvatarSize from "utils/validators/validateAvatarSize"
import ARegisterAvatarField from "components/Field/ARegisterAvatarField"
import { AVATAR_LABEL } from "constants/avatar"
import useCooptionToken from "hooks/useCooptionToken"

const Register = () => {
  const uploadAvatarMutation = useMutation(uploadAvatar)
  const createOsteoMutation = useMutation(createOsteo)
  const registerUserMutation = useMutation(registerUser)
  const toast = useToast()
  const [cooptionToken, clearCooptionToken] = useCooptionToken()

  const [mainAddress, setMainAddress] = useState<AddressType | undefined>(
    undefined
  )
  const [mainAddressError, setMainAddressError] = useState<string | undefined>()
  const [secondaryAddress, setSecondaryAddress] = useState<
    AddressType | undefined
  >(undefined)
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)

  const { marginWithBanner } = useContext(RgpdContext)

  return (
    <Flex alignItems="stretch">
      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb={marginWithBanner}
      >
        {formSubmitted ? (
          <Box w="67%">
            <Heading
              as="h1"
              mb={{ base: 8, lg: 24 }}
              textAlign="center"
              color="tertiary.500"
              fontSize={22}
            >
              Bienvenue chez Andrew® ! <br />
            </Heading>
            <Text textAlign="center" fontSize={18}>
              Félicitation pour votre inscription. <br />
              Vous pouvez dès à présent vous connecter à votre compte en
              cliquant sur le bouton ci-dessous.
            </Text>

            <Flex justifyContent="center" mt={16}>
              <Link
                as={RouterLink}
                to="/login"
                _hover={{ textDecoration: "none" }}
              >
                <AButton py={3} px={94} text="Je me connecte" />
              </Link>
            </Flex>
          </Box>
        ) : (
          <Box w="67%">
            <ALogo />
            <Heading as="h1" fontSize={28} color="tertiary.500" mb={6}>
              Créer un compte
            </Heading>
            <Text mb={10}>
              En créant votre compte, vous bénéficiez de 15 jours d'essai
              gratuit pour explorer toutes nos fonctionnalités. Vous accédez
              aussi à notre module e-learning pour vous accompagner dans la
              recommandation d'exercices.
            </Text>
            <Formik
              validationSchema={Yup.object().shape({
                lastname: Yup.string().required("Nom requis"),
                firstname: Yup.string().required("Prénom requis"),
                email: Yup.string()
                  .email("Ceci n'est pas un email")
                  .required("Email requis"),
                password: Yup.string()
                  .min(8, "Le mot de passe doit faire 8 caractères minimum")
                  .matches(
                    new RegExp(
                      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!&$*?_\-()+#=~@%,]{8,}$/
                    ),
                    `Pas si vite ! Votre mot de passe doit contenir au minimum 8 caractères, dont au moins :
1 majuscule ;
1 minuscule ;
1 chiffre.
Voici les caractères spéciaux autorisés : ! & $ * ? - _ ( ) + # = @ %`
                  )
                  .required("Ce champ est obligatoire"),
                confirmPassword: Yup.string()
                  .min(8, "Le mot de passe doit faire 8 caractères minimum")
                  .oneOf(
                    [Yup.ref("password"), null],
                    "Les mots de passe ne correspondent pas"
                  ),
                urlGoogleMyBusiness: Yup.string().matches(
                  new RegExp(/\/review$/),
                  `Le lien n'est pas valide`
                ),
                avatar: Yup.mixed().test(validateAvatarSize),
                hasAcceptedCGU: Yup.boolean().oneOf(
                  [true],
                  "Vous devez accepter les CGU"
                ),
                // .required('Ce champ est obligatoire'),
                urlDoctolib: Yup.string(),
                telephone: Yup.string()
                  .required("Ce champ est obligatoire")
                  .matches(
                    new RegExp(
                      /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
                    ),
                    "Ceci n'est pas un numéro de téléphone"
                  ),
                // cooptor: Yup.string().email("Ceci n'est pas un email"),
              })}
              initialValues={{
                lastname: "",
                firstname: "",
                email: "",
                password: "",
                confirmPassword: "",
                avatar: null,
                urlDoctolib: "",
                telephone: "",
                hasAcceptedCGU: false,
                urlGoogleMyBusiness: "",
                // cooptor: '',
              }}
              onSubmit={(
                { avatar, password, confirmPassword, ...values },
                { setSubmitting }
              ) => {
                if (password !== confirmPassword) {
                  toast({
                    status: "error",
                    title: "Les mots de passe ne correspondent pas",
                  })
                  setSubmitting(false)
                  return
                }
                if (!mainAddress) {
                  setMainAddressError("Ce champ est obligatoire")
                  setSubmitting(false)
                  return
                }
                registerUserMutation.mutate(
                  {
                    email: values.email.trim(),
                    password,
                    username: values.email.trim(),
                    passwordInitiated: true,
                    cooptionToken,
                    // resetHeaders: true,
                  },
                  {
                    onError: (data: any) => {
                      console.log(data)
                      setSubmitting(false)
                      toast({
                        status: "error",
                        title:
                          data?.response?.data?.error?.message ||
                          "Une erreur s'est produite",
                      })
                    },
                    onSuccess: (data: any) => {
                      clearCooptionToken()
                      if (avatar) {
                        let file = new FormData()
                        file.append("files", avatar)
                        uploadAvatarMutation.mutate(file, {
                          onError: () => {
                            setSubmitting(false)
                            toast({
                              status: "error",
                              title: "Une erreur s'est produite",
                            })
                          },
                          onSuccess: (fileId: string) => {
                            createOsteoMutation.mutate(
                              {
                                ...values,
                                email: values.email.trim(),
                                // cooptor: values.cooptor.trim(),
                                avatar: fileId,
                                userId: data.id,
                                isOsteow: false,
                                isTester: false,
                                mainAddress,
                                secondaryAddress,
                              },
                              {
                                onSuccess: () => {
                                  setSubmitting(false)
                                  toast({
                                    status: "success",
                                    title: "Compte créé avec succès",
                                  })
                                  setFormSubmitted(true)

                                  var properties = {
                                    NOM: values.lastname,
                                    PRENOM: values.firstname,
                                    IS_OSTEOW: false,
                                    HAS_USED_TRIAL: false,
                                    IS_PRO: true,
                                    IS_CENTER: false,
                                    IS_ACADEMIE: false,
                                  }
                                  sendinBlueIdentify(
                                    values.email.trim(),
                                    properties
                                  )
                                  registerSuccessful()
                                },
                                onError: () => {
                                  setSubmitting(false)
                                  toast({
                                    status: "error",
                                    title: "Une erreur s'est produite",
                                  })
                                },
                              }
                            )
                          },
                        })
                      } else {
                        createOsteoMutation.mutate(
                          {
                            ...values,
                            email: values.email.trim(),
                            // cooptor: values.cooptor.trim(),
                            userId: data.id,
                            isOsteow: false,
                            isTester: false,
                            mainAddress,
                            secondaryAddress,
                          },
                          {
                            onSuccess: () => {
                              setSubmitting(false)
                              toast({
                                status: "success",
                                title: "Compte créé avec succès",
                              })
                              setFormSubmitted(true)

                              var properties = {
                                NOM: values.lastname,
                                PRENOM: values.firstname,
                                IS_OSTEOW: false,
                                HAS_USED_TRIAL: false,
                                IS_PRO: true,
                                IS_CENTER: false,
                                IS_ACADEMIE: false,
                              }

                              sendinBlueIdentify(
                                values.email.trim(),
                                properties
                              )
                              registerSuccessful()
                            },
                            onError: () => {
                              setSubmitting(false)
                              toast({
                                status: "error",
                                title: "Une erreur s'est produite",
                              })
                            },
                          }
                        )
                      }
                    },
                  }
                )
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <Field name="email">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel mt={6}>Mail *</FormLabel>
                        <Input
                          {...field}
                          autoComplete="username"
                          placeholder="Email"
                          bg="common.100"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.password && form.touched.password
                        }
                      >
                        <FormLabel mt={6}>Mot de passe *</FormLabel>
                        <AInputPassword
                          autoComplete="new-password"
                          {...field}
                        />
                        <FormErrorMessage whiteSpace="pre-wrap">
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="confirmPassword">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.confirmPassword &&
                          form.touched.confirmPassword
                        }
                      >
                        <FormLabel mt={6}>
                          Confirmation mot de passe *
                        </FormLabel>
                        <AInputPassword {...field} />
                        <FormErrorMessage whiteSpace="pre-wrap">
                          {form.errors.confirmPassword}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex gap={4} mt={4}>
                    <Field name="lastname">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.lastname && form.touched.lastname
                          }
                        >
                          <FormLabel>Nom *</FormLabel>
                          <Input
                            {...field}
                            autoComplete="family-name"
                            placeholder="Nom de famille"
                            bg="common.100"
                          />
                          <FormErrorMessage>
                            {form.errors.lastname}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="firstname">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.firstname && form.touched.firstname
                          }
                        >
                          <FormLabel>Prénom *</FormLabel>
                          <Input
                            {...field}
                            autoComplete="given-name"
                            placeholder="Prénom"
                            bg="common.100"
                          />
                          <FormErrorMessage>
                            {form.errors.firstname}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <Field name="telephone">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.telephone && form.touched.telephone
                        }
                      >
                        <FormLabel mt={6}>Numéro de téléphone *</FormLabel>
                        <Input
                          {...field}
                          autoComplete="tel"
                          bg="common.100"
                          placeholder="06..."
                        />
                        <FormErrorMessage>
                          {form.errors.telephone}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="urlDoctolib">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.urlDoctolib && form.touched.urlDoctolib
                        }
                      >
                        <FormLabel mt={6}>
                          Url du site de réservation (exemple: doctolib)
                        </FormLabel>
                        <Input
                          {...field}
                          bg="common.100"
                          placeholder="https://www.doctolib.fr/osteopathe/..."
                        />
                        <FormErrorMessage>
                          {form.errors.urlDoctolib}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex gap={4} mt={6}>
                    <FormControl isInvalid={mainAddressError !== undefined}>
                      <FormLabel mb="8px">
                        Adresse du lieu d'exercice *
                      </FormLabel>
                      <AAdressInput
                        setAddress={setMainAddress}
                        defaultValue={mainAddress?.name}
                      />
                      <FormErrorMessage>{mainAddressError}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <Flex mt={6}>
                    <FormControl>
                      <FormLabel mb="8px">Adresse secondaire</FormLabel>
                      <AAdressInput
                        setAddress={setSecondaryAddress}
                        defaultValue={secondaryAddress?.name}
                      />
                    </FormControl>
                  </Flex>
                  <Flex>
                    <Field name="urlGoogleMyBusiness">
                      {({ field, form }: { field: any; form: any }) => (
                        <FormControl
                          isInvalid={
                            form.errors.urlGoogleMyBusiness &&
                            form.touched.urlGoogleMyBusiness
                          }
                          my={6}
                        >
                          <Flex justify={"start"} position={"relative"}>
                            <FormLabel>
                              <Text>
                                Url de partage pour{" "}
                                <Tooltip
                                  label="Obtenir mon lien"
                                  placement="right"
                                >
                                  <Link
                                    target="_blank"
                                    href="https://support.google.com/business/answer/3474122?hl=fr"
                                    textDecoration="underline"
                                  >
                                    avis Google My Business
                                  </Link>
                                </Tooltip>
                              </Text>
                            </FormLabel>
                          </Flex>
                          <Input
                            {...field}
                            bg="common.100"
                            placeholder="https://g.page/r/CeATmWfwbkmWEAI/review"
                          />
                          <FormErrorMessage>
                            {form.errors.urlGoogleMyBusiness}
                          </FormErrorMessage>
                          <Tooltip label="Obtenir mon lien" placement="right">
                            <Link
                              target="_blank"
                              href="https://youtu.be/tLzuMAhj_2I"
                              textDecor="underline"
                              display={"flex"}
                              alignItems={"center"}
                              gap={2}
                              mt={2}
                            >
                              Ou consultez notre vidéo explicative pour obtenir
                              votre lien
                            </Link>
                          </Tooltip>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                  <ARegisterAvatarField name="avatar" label={AVATAR_LABEL} />
                  {/* <Field name='cooptor'>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.cooptor && form.touched.cooptor}
                    >
                      <FormLabel mt={6}>
                        Email du coopteur (si vous avez été coopté)
                      </FormLabel>
                      <Input {...field} bg='common.100' placeholder='Email' />
                      <FormErrorMessage>{form.errors.cooptor}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field> */}
                  <Field name="hasAcceptedCGU">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={
                          form.errors.hasAcceptedCGU &&
                          form.touched.hasAcceptedCGU
                        }
                        mt={8}
                      >
                        <Checkbox
                          colorScheme="orange"
                          borderColor="primary.500"
                          alignItems="start"
                          {...field}
                        >
                          <Text mt={-1}>
                            En cochant cette case, je reconnais avoir pris
                            connaissance des{" "}
                            <Link
                              target="_blank"
                              href="https://www.andrewapp.fr/cgu"
                              color="tertiary.500"
                              textDecoration="underline"
                            >
                              Conditions générales d'utilisation
                            </Link>{" "}
                            et les accepte
                          </Text>
                        </Checkbox>
                        <FormErrorMessage>
                          {form.errors.hasAcceptedCGU}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <AButton
                    ml="50%"
                    mb={12}
                    transform="translateX(-50%)"
                    text="Créer un compte"
                    py={3}
                    px={94}
                    mt={16}
                    type="submit"
                    isLoading={isSubmitting}
                  />
                </Form>
              )}
            </Formik>
          </Box>
        )}
      </Flex>
      <Box flex={1} display={{ base: "none", lg: "block" }}>
        <Image src={Cover} alt="Andrew" w="100%" h="100%" objectFit="cover" />
      </Box>
    </Flex>
  )
}

export default Register

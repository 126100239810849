import {
  Box,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import React, { useId } from "react"

interface IProps {
  label: string
  subtitle: string
  value: number
  handleChange: React.Dispatch<React.SetStateAction<number>>
}

const ASlider: React.FC<IProps> = ({
  label,
  subtitle,
  value,
  handleChange,
}) => {
  const labelId = useId()
  return (
    <Box>
      <FormLabel id={labelId} fontWeight={700} mt={4} mb={0}>
        {label} :
      </FormLabel>
      <Text mb={8} zIndex={10}>
        {subtitle}
      </Text>
      <Slider
        id="slider"
        aria-labelledby={labelId}
        defaultValue={0}
        value={value}
        min={0}
        max={10}
        onChange={(v) => handleChange(v)}
        mb={8}
      >
        <SliderMark value={0} mt="-8" ml="-1" fontSize="sm">
          0
        </SliderMark>
        <SliderMark value={5} mt="-8" ml="-1" fontSize="sm">
          5
        </SliderMark>
        <SliderMark value={10} mt="-8" ml="-2" fontSize="sm">
          10
        </SliderMark>
        <SliderTrack bgGradient="linear(to-l, #970808, #E0B20D, #10A10D)">
          <SliderFilledTrack bg="transparent" />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg="primary.500"
          color="white"
          placement="bottom"
          isOpen
          label={value.toString()}
          zIndex={10}
        >
          <SliderThumb boxSize={4} bg="white"></SliderThumb>
        </Tooltip>
      </Slider>
    </Box>
  )
}

export default ASlider

import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  MinusIcon,
  ViewIcon,
} from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useNumberInput,
} from "@chakra-ui/react"
import React, { useEffect } from "react"
import { SelectedExerciceType } from "api/appointmentReason"

interface IProps {
  selectedExercice: SelectedExerciceType
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  position: number
  size: number
  isDisabled: boolean
}

const SelectedExerciceCard: React.FC<IProps> = ({
  selectedExercice,
  setSelectedExercices,
  position,
  size,
  isDisabled,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 5,
      defaultValue: selectedExercice.duration,
      min: selectedExercice.defaultDuration || 0,
      max: 300,
      isDisabled,
    })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps()

  const value = input["aria-valuenow"]
  useEffect(() => {
    if (!value) return

    setSelectedExercices((prev) => {
      return prev.map((exercice) => {
        if (exercice.id === selectedExercice.id) {
          return { ...exercice, duration: value }
        }
        return exercice
      })
    })
  }, [value, selectedExercice.id, setSelectedExercices])

  const handleRemoveExercice = () => {
    if (!isDisabled) {
      setSelectedExercices((prev) => {
        return prev.filter((exercice) => exercice.id !== selectedExercice.id)
      })
    }
  }

  const increasePosition = () => {
    if (!isDisabled) {
      const pos = position - 1
      setSelectedExercices((prev) => {
        const newExercices = [...prev]
        const exercice = newExercices[pos]
        newExercices[pos] = newExercices[pos + 1]
        newExercices[pos + 1] = exercice
        return newExercices
      })
    }
  }

  const decreasePosition = () => {
    if (!isDisabled) {
      const pos = position - 1
      setSelectedExercices((prev) => {
        const newExercices = [...prev]
        const exercice = newExercices[pos]
        newExercices[pos] = newExercices[pos - 1]
        newExercices[pos - 1] = exercice
        return newExercices
      })
    }
  }

  return (
    <Flex alignItems="center" as="li">
      <Flex
        flexDir="column"
        alignItems="center"
        bg="white"
        borderRadius={9}
        py={2.5}
        px={2}
        mr={2}
      >
        {position !== 1 && (
          <ChevronUpIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={decreasePosition}
          />
        )}
        <Text>{position}</Text>
        {position !== size && (
          <ChevronDownIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={increasePosition}
          />
        )}
      </Flex>
      <Box bg="white" py={2} px={3} borderRadius={9} pos="relative" flex={1}>
        <Text color="tertiary.500" fontSize={14} fontWeight={700} mb={2}>
          {
            selectedExercice.group.find(
              (currentGroup) => currentGroup.id === selectedExercice.videoGroup
            )?.title
          }
        </Text>
        <Text fontSize={12} mb={3}>
          {`Niveau ${selectedExercice.level}`}
        </Text>
        <Flex alignItems="center">
          <Flex alignItems="center" fontSize={12} mr={3}>
            <Text fontWeight={700}>Durée</Text>
            <Text ml={1}>(sec)</Text>
          </Flex>
          <Flex alignItems="center">
            <MinusIcon
              {...dec}
              w={3}
              color="primary.500"
              cursor={isDisabled ? "not-allowed" : "pointer"}
            >
              -
            </MinusIcon>
            <Input
              {...input}
              bg="common.100"
              w="60px"
              h="25px"
              p={2}
              textAlign="center"
              mx={2}
              fontWeight={700}
              fontSize={14}
              cursor={isDisabled ? "not-allowed" : "auto"}
            />
            <AddIcon
              {...inc}
              w={3}
              color="primary.500"
              cursor={isDisabled ? "not-allowed" : "pointer"}
            >
              -
            </AddIcon>
          </Flex>
        </Flex>
        <Flex pos="absolute" top={7} right={4} alignItems="center">
          <Flex
            cursor={isDisabled ? "not-allowed" : "pointer"}
            borderRadius="50%"
            border="1px solid"
            borderColor="primary.500"
            w={34}
            h={34}
            justifyContent="center"
            alignItems="center"
            mr={3}
            onClick={isDisabled ? undefined : onOpen}
            color="primary.500"
            _hover={{
              bg: "primary.500",
              color: "white",
            }}
          >
            <ViewIcon />
          </Flex>
          <IconButton
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={handleRemoveExercice}
            _hover={{ color: "danger.500" }}
            aria-label="Supprimer la vidéo"
            background="none"
            icon={<DeleteIcon />}
          />
        </Flex>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="blackAlpha.400" />
        <ModalContent maxW="724">
          <Box
            pos="relative"
            borderRadius={9}
            bg="white"
            w={724}
            h={469}
            py="32px"
            px="54px"
          >
            <ModalCloseButton aria-label="Fermer" />
            <Heading fontSize={22} fontWeight="bold" textAlign="center" mb={7}>
              {selectedExercice.group[0].title}
            </Heading>
            <video
              src={selectedExercice.fullVideo?.url}
              controls
              onContextMenu={(e) => e.preventDefault()}
              controlsList="nodownload"
            ></video>
          </Box>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default SelectedExerciceCard

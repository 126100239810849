import { Box, Flex, Heading } from "@chakra-ui/react"
import React from "react"
import ASelectableButton from "../../../../../components/ASelectableButton"
import { BabyMediaTag } from "api/media"

interface IProps {
  reason: "zones" | "pathologies" | "no" | "no-exos" | null
  setReason: (reason: "zones" | "pathologies" | "no" | "no-exos" | null) => void
  isBabySwitchOn: boolean
  selectedBabyMediaTags: BabyMediaTag[] | null
  setSelectedBabyMediaTags: React.Dispatch<
    React.SetStateAction<BabyMediaTag[] | null>
  >
}

const Reason: React.FC<IProps> = ({
  reason,
  setReason,
  isBabySwitchOn,
  selectedBabyMediaTags,
  setSelectedBabyMediaTags,
}) => {
  return (
    <Box mb={8}>
      {isBabySwitchOn ? (
        <>
          <Heading fontSize={18} mb={4}>
            Choisir une ou plusieurs catégories
          </Heading>
          <Flex gap={6} w="full">
            <ASelectableButton
              text="Sélectionner une catégorie"
              onClick={() => setSelectedBabyMediaTags([])}
              size="lg"
              state={selectedBabyMediaTags !== null ? "active" : "default"}
            />
            <ASelectableButton
              text="Toutes les vidéos"
              onClick={() => setSelectedBabyMediaTags(null)}
              size="lg"
              state={selectedBabyMediaTags === null ? "active" : "default"}
            />
          </Flex>
        </>
      ) : (
        <>
          <Heading fontSize={18} mb={4}>
            Choisir une ou plusieurs zone ou symptomatologie
          </Heading>
          <Flex gap={6} w="full">
            <ASelectableButton
              text="Zones"
              size="lg"
              state={reason === "zones" ? "active" : "default"}
              onClick={() => setReason("zones")}
            />
            <ASelectableButton
              text="Symptomatologies"
              size="lg"
              state={reason === "pathologies" ? "active" : "default"}
              onClick={() => setReason("pathologies")}
            />

            <ASelectableButton
              text="Voir tous les exercices"
              onClick={() => setReason("no")}
              size="lg"
              state={reason === "no" ? "active" : "default"}
            />

            <ASelectableButton
              text="Continuer sans exercice"
              onClick={() => setReason("no-exos")}
              size="lg"
              state={reason === "no-exos" ? "active" : "default"}
            />
          </Flex>
        </>
      )}
    </Box>
  )
}

export default Reason

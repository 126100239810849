import { CloseIcon } from "@chakra-ui/icons"
import { Button, ButtonProps, Flex, Text } from "@chakra-ui/react"
import React from "react"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"

const theme = {
  zone: {
    background: "secondary.500",
    _hover: {
      background: "secondary.600",
    },
    color: "common.700",
  },
  pathologie: {
    background: "primary.800",
    _hover: {
      background: "primary.900",
    },
    color: "white",
  },
}

interface IProps extends ButtonProps {
  itemType: "zone" | "pathologie"
  name: string
  itemId: number
  setZones: React.Dispatch<React.SetStateAction<ZonePathologieType[]>>
  setPathologies: React.Dispatch<React.SetStateAction<ZonePathologieType[]>>
}

const SelectedItem: React.FC<IProps> = ({
  itemType,
  name,
  itemId,
  setZones,
  setPathologies,
}) => {
  const handleClick = () => {
    if (itemType === "zone") {
      setZones((prev) => prev.filter((zone) => zone.id !== itemId))
    }
    if (itemType === "pathologie") {
      setPathologies((prev) =>
        prev.filter((pathology) => pathology.id !== itemId)
      )
    }
  }

  return (
    <Button
      p={2}
      bg={theme[itemType].background}
      _hover={{ bg: theme[itemType]._hover.background }}
      borderRadius={9}
      rightIcon={<CloseIcon color={theme[itemType].color} w={2.5} />}
      onClick={handleClick}
    >
      <Flex
        alignItems="center"
        color={theme[itemType].color}
        fontSize={14}
        fontWeight="normal"
      >
        <Text whiteSpace="nowrap" mr={2}>
          {itemType === "zone" ? "Zone :" : "Symptomatologie :"}
        </Text>
        <Text>{name}</Text>
      </Flex>
    </Button>
  )
}

export default SelectedItem

import { getSubscriptions, StripeProductType } from "api/subscriptions"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "AppContext"
import userAdminsCenterGroupInCurrentScope from "utils/currentScope/userAdminsCenterGroupInCurrentScope"
import userHasSubscription from "utils/subscription/userHasSubscription"
import OsteoSubscriptionBilling from "components/Billing/Osteo/SubscriptionBilling"
import CenterSubscriptionBilling from "components/Billing/Center/SubscriptionBilling"

const Billing = () => {
  const { user, currentScope } = useAppContext()

  const { data: stripeProducts, isLoading: isLoadingStripeProducts } = useQuery<
    StripeProductType[]
  >(["getSubscriptions"], getSubscriptions)

  const userHasIndividualSubscription = userHasSubscription(user)
  const isCenterAdmin = userAdminsCenterGroupInCurrentScope(user, currentScope)

  if (userHasIndividualSubscription) {
    return (
      <OsteoSubscriptionBilling
        isLoadingStripeProducts={isLoadingStripeProducts}
        stripeProducts={stripeProducts}
      />
    )
  }

  if (isCenterAdmin) {
    return (
      <CenterSubscriptionBilling
        isLoadingStripeProducts={isLoadingStripeProducts}
        stripeProducts={stripeProducts}
      />
    )
  }
  return null
}

export default Billing

import {
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  ViewIcon,
} from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { SelectedDiscoverType } from "api/appointmentReason"

interface IProps {
  selectedDiscover: SelectedDiscoverType
  setSelectedDiscovers: React.Dispatch<
    React.SetStateAction<SelectedDiscoverType[]>
  >
  position: number
  size: number
  isDisabled: boolean
}

const SelectedDiscoverCard: React.FC<IProps> = ({
  selectedDiscover,
  setSelectedDiscovers,
  position,
  size,
  isDisabled,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleRemoveDiscover = () => {
    if (!isDisabled) {
      setSelectedDiscovers((prev) => {
        return prev.filter((discover) => discover.id !== selectedDiscover.id)
      })
    }
  }

  const increasePosition = () => {
    if (!isDisabled) {
      const pos = position - 1
      setSelectedDiscovers((prev) => {
        const newExercices = [...prev]
        const exercice = newExercices[pos]
        newExercices[pos] = newExercices[pos + 1]
        newExercices[pos + 1] = exercice
        return newExercices
      })
    }
  }

  const decreasePosition = () => {
    if (!isDisabled) {
      const pos = position - 1
      setSelectedDiscovers((prev) => {
        const newExercices = [...prev]
        const exercice = newExercices[pos]
        newExercices[pos] = newExercices[pos - 1]
        newExercices[pos - 1] = exercice
        return newExercices
      })
    }
  }

  return (
    <Flex alignItems="center" as="li">
      <Flex
        flexDir="column"
        alignItems="center"
        bg="white"
        borderRadius={9}
        py={2.5}
        px={2}
        mr={2}
      >
        {position !== 1 && (
          <ChevronUpIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={decreasePosition}
          />
        )}
        <Text>{position}</Text>
        {position !== size && (
          <ChevronDownIcon
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={increasePosition}
          />
        )}
      </Flex>
      <Flex
        bg="white"
        py={2}
        px={3}
        borderRadius={9}
        pos="relative"
        w="full"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text color="tertiary.500" fontSize={14} fontWeight={700}>
          {selectedDiscover.title}
        </Text>
        <Flex alignItems="center">
          {selectedDiscover.type === "video" && (
            <Flex
              cursor={isDisabled ? "not-allowed" : "pointer"}
              borderRadius="50%"
              border="1px solid"
              borderColor="primary.500"
              w={34}
              h={34}
              justifyContent="center"
              alignItems="center"
              mr={1}
              onClick={isDisabled ? undefined : onOpen}
              color="primary.500"
              _hover={{
                bg: "primary.500",
                color: "white",
              }}
            >
              <ViewIcon />
            </Flex>
          )}
          <IconButton
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={handleRemoveDiscover}
            _hover={{ color: "danger.500" }}
            aria-label="Supprimer la vidéo"
            background="none"
            icon={<DeleteIcon />}
          />
        </Flex>
      </Flex>
      {selectedDiscover.type === "video" && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay bg="blackAlpha.400" />
          <ModalContent maxW="724">
            <Box
              pos="relative"
              borderRadius={9}
              bg="white"
              w={724}
              h={469}
              py="32px"
              px="54px"
            >
              <ModalCloseButton aria-label="Fermer" />
              <Heading
                fontSize={22}
                fontWeight="bold"
                textAlign="center"
                mb={7}
              >
                {selectedDiscover.title}
              </Heading>
              <video
                src={selectedDiscover.media.url}
                controls
                onContextMenu={(e) => e.preventDefault()}
                controlsList="nodownload"
              ></video>
            </Box>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  )
}

export default SelectedDiscoverCard

import { Accordion } from "@chakra-ui/react"
import {
  FavoriteProgram,
  newFavoriteProgram,
  newFavoriteProgramWithoutReason,
  SelectedExerciceType,
} from "api/appointmentReason"
import { useEditingFavoritesContext } from "components/EditingFavoritesContext/EditingFavoritesContext"
import { Form, Formik, FormikHelpers } from "formik"
import EditFavoriteProgramCard from "pages/NewAppointment/components/SelectExercices/components/FavoritePrograms/components/EditFavoriteProgramCard"
import { FAVORITE_VALIDATION_SCHEMA } from "constants/schemas"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import useToast from "hooks/useToast"
import favoriteProgramsQueryKeys from "constants/queryKeys/favoritePrograms"

// COMPONENTS
interface NewFavoriteProgramCardProps {
  newProgram: FavoriteProgram
  setNewProgram: (newProgram: FavoriteProgram | undefined) => void
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  currentReasonId: number | undefined
}
const NewFavoriteProgramCard = ({
  newProgram,
  setNewProgram,
  selectedExercices,
  setSelectedExercices,
  currentReasonId,
}: NewFavoriteProgramCardProps) => {
  const createFavoriteProgramMutation = useMutation(newFavoriteProgram)
  const createFavoriteProgramWithoutReasonMutation = useMutation(
    newFavoriteProgramWithoutReason
  )
  const toast = useToast()
  const queryClient = useQueryClient()

  const { currentFavorites, onDone } = useEditingFavoritesContext()
  const onSubmit = (
    { title }: FavoriteProgram,
    { setSubmitting }: FormikHelpers<FavoriteProgram>
  ) => {
    if (currentFavorites.length === 0) {
      setSubmitting(false)
      toast({
        status: "error",
        title: "Aucun favori renseigné",
      })
      return
    }
    if (currentReasonId) {
      createFavoriteProgramMutation.mutate(
        {
          favoriteProgram: {
            id: "new",
            title,
            trainingVideos: currentFavorites,
          },
          appointmentReasonId: currentReasonId,
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: favoriteProgramsQueryKeys.all,
            })
            setNewProgram(undefined)
            onDone()
            toast({
              status: "success",
              title: "Favori ajouté avec succès",
            })
          },
          onError: () => {
            setSubmitting(false)
            toast({
              status: "error",
              title: "Une erreur s'est produite",
            })
          },
        }
      )
    } else {
      createFavoriteProgramWithoutReasonMutation.mutate(
        {
          favoriteProgram: {
            id: "new",
            title,
            trainingVideos: currentFavorites,
          },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: favoriteProgramsQueryKeys.all,
            })
            setNewProgram(undefined)
            onDone()
            toast({
              status: "success",
              title: "Favori ajouté avec succès",
            })
          },
          onError: () => {
            setSubmitting(false)
            toast({
              status: "error",
              title: "Une erreur s'est produite",
            })
          },
        }
      )
    }
  }

  const onCancel = () => {
    setNewProgram(undefined)
    onDone()
  }

  return (
    <Formik
      initialValues={newProgram}
      validationSchema={FAVORITE_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      <Form>
        <Accordion index={0}>
          <EditFavoriteProgramCard
            onCancel={onCancel}
            selectedExercices={selectedExercices}
            setSelectedExercices={setSelectedExercices}
          />
        </Accordion>
      </Form>
    </Formik>
  )
}

export default NewFavoriteProgramCard

import axiosInstance from "./axiosInstance"
import { VideoContentType, VideoPreviewType } from "api/appointmentReason"
import { MediaType } from "api/media"

export type ReasonType = {
  id: number
  name: string
}

export type CheckupType = {
  id: number
  pain: number
  frequency: number
  mobility: number
  stress: number
  createdAt: string
  reasons: ReasonType[]
}

export type ExerciceType = {
  id: number
  duration: number
  osteoSelection: {
    id: number
    level: number
    title: string
    preview: VideoPreviewType
    fullVideo: VideoContentType
    limitedMode: boolean
    group: {
      id: number
      title: string
    }[]
  }
}

export type TrainingPlaylistType = {
  id: number
  logs: {
    sessionDate: string
  }[]
  exercices: ExerciceType[]
  mainReason: ReasonType
  reasons?: ReasonType[]
  expiry?: string
}

export interface PatientAppointmentType extends BaseAppointmentType {
  meetingDate: string
  trainingPlaylist: TrainingPlaylistType
  osteoAdvice: string
  internalNote: string
  secondaryInternalNote: string
  explorerMedias?: MediaType[]
}

export type TraininScheduleTimeType = {
  hour: number
  minute: number
  id: string
}

export type TraininScheduleType = {
  weekday: number
  times: TraininScheduleTimeType[]
}

export interface PatientType {
  id?: number
  firstname?: string
  lastname?: string
  postalCode?: string
  birthday?: string
  account?: { email: string; username: string; passwordInitiated: boolean }
  telephone?: string
  checkups?: CheckupType[]
  appointments?: PatientAppointmentType[]
  trainingSchedule?: TraininScheduleType[]
  label?: string
}

export type GetPatientParams = {
  patientId?: string
  academieId?: number
  centerId?: number
}

export const getPatient = async ({
  patientId,
  academieId,
  centerId,
}: GetPatientParams) => {
  const response = await axiosInstance.get<PatientType>(
    `/osteo/me/patient/${patientId}`,
    {
      params: { academie_id: academieId, center_id: centerId },
    }
  )
  return response.data
}

export type FindPatientsParams =
  | {
      search?: string
    }
  | {
      search?: string
      academieId: number
    }
  | {
      search?: string
      centerId: number
    }

export const findPatients = async (params: FindPatientsParams) => {
  let fullParams
  if ("centerId" in params) {
    fullParams = {
      center_id: params.centerId,
      search: params.search,
    }
  } else if ("academieId" in params) {
    fullParams = {
      academie_id: params.academieId,
      search: params.search,
    }
  } else {
    fullParams = {
      search: params.search,
    }
  }

  const response = await axiosInstance.get<PatientType[]>(
    `/osteo/me/findPatients`,
    {
      params: fullParams,
    }
  )
  return response.data
}

type UpdatePatientParams = {
  id: string
  data: {
    firstname: string
    lastname: string
    birthday: string
    email: string
    postalCode: string
  }
}

type PostNewPasswordPatientParams = {
  id: string
}

export interface UpdatePatientResponse extends PatientType {
  temp_password?: string
}

export const updatePatient = async (params: UpdatePatientParams) => {
  const response = await axiosInstance.put<UpdatePatientResponse>(
    `osteo/me/patient/${params.id}`,
    params.data
  )
  return response.data
}

export const newTemporaryPassword = async (
  params: PostNewPasswordPatientParams
) => {
  const response = await axiosInstance.post<UpdatePatientResponse>(
    `osteo/me/patient/${params.id}/resendWelcomeEmail`
  )
  return response.data
}

export type CreatePatientType = {
  userId?: number
  firstname?: string
  lastname?: string
  email?: string
  postalCode?: string
  birthday?: string
  telephone?: string
  osteo: number
  academieId?: number
  centerId?: number
}

export interface CreatePatientResponseType extends PatientType {
  temp_password?: string
}

export const createPatient = async ({
  academieId,
  centerId,
  ...data
}: CreatePatientType) => {
  const response = await axiosInstance.post<CreatePatientResponseType>(
    `/osteo/me/patient/new`,
    {
      academie_id: academieId,
      center_id: centerId,
      ...data,
    }
  )
  return response.data
}

import { Flex, Text } from "@chakra-ui/react"
import AnimatedNumber from "components/Stats/AnimatedNumber"
import ReactApexChart from "react-apexcharts"

interface ChartProps {
  title: string
  options: any
  series: {
    name: string
    data: any[]
  }[]
  chartWidth: number
  showTotalData?: boolean
  labelColor: string
}

const Chart: React.FC<ChartProps> = ({
  title,
  options,
  series,
  chartWidth,
  showTotalData,
  labelColor,
}: ChartProps) => {
  const total =
    showTotalData === undefined
      ? undefined
      : series[0].data[series[0].data.length - 1]
  return (
    <Flex
      direction={"column"}
      bg="white"
      borderRadius={9}
      px={4}
      py={0}
      height={"fit-content"}
      position="relative"
    >
      <Flex
        justify={"start"}
        direction={"column"}
        pos="absolute"
        top={4}
        left={6}
      >
        {showTotalData && (
          <Text as="b" fontSize={"42px"} color={labelColor} pos="relative">
            <AnimatedNumber end={total} />
          </Text>
        )}
        <Text dangerouslySetInnerHTML={{ __html: title }} />
      </Flex>
      <Flex justify={"center"}>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={255}
          width={chartWidth}
        />
      </Flex>
    </Flex>
  )
}

export default Chart

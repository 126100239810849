import { Flex, Text, Icon } from "@chakra-ui/react"
import { useAppContext } from "../../../AppContext"
import { MdSchool } from "react-icons/md"
import getCurrentAcademy from "utils/getCurrentAcademy"
import AUserAvatar from "components/AAvatar/AUserAvatar"
import getCurrentCenter from "utils/getCurrentCenter"
import ACenterAvatar from "components/AAvatar/ACenterAvatar"

// COMPONENTS
const ACurrentProfile = () => {
  const { currentScope, user } = useAppContext()
  if (!currentScope)
    return (
      <Flex alignItems="center">
        <AUserAvatar user={user} />
        <Text fontWeight={700} fontSize={18}>
          {user?.osteo.firstname} {user?.osteo.lastname}
        </Text>
      </Flex>
    )

  const currentAcademy = getCurrentAcademy({ currentScope, user })

  if (currentAcademy)
    return (
      <Flex alignItems="center">
        <Flex
          w={8}
          h={8}
          justifyContent="center"
          alignItems="center"
          bg="tertiary.500"
          borderRadius="50%"
          mr={2}
        >
          <Icon fontSize={20} as={MdSchool} color="white" />
        </Flex>
        <Flex flexDir="column" alignItems="flex-start">
          <Text fontWeight={700} fontSize={16}>
            {currentAcademy.name}
          </Text>
          <Text fontWeight={400} fontSize={14}>
            {user?.osteo.firstname} {user?.osteo.lastname}
          </Text>
        </Flex>
      </Flex>
    )

  const currentCenter = getCurrentCenter({ currentScope, user })
  if (currentCenter)
    return (
      <Flex alignItems="center">
        <ACenterAvatar avatar={currentCenter.avatar} />
        <Flex flexDir="column" alignItems="flex-start">
          <Text fontWeight={700} fontSize={16}>
            {currentCenter.name}
          </Text>
          <Text fontWeight={400} fontSize={14}>
            {user?.osteo.firstname} {user?.osteo.lastname}
          </Text>
        </Flex>
      </Flex>
    )

  return null
}

export default ACurrentProfile

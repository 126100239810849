import { Box, Flex, Heading } from "@chakra-ui/react"
import React from "react"

const ELearningDatas = [
  {
    title: "Introduction aux conseils nourrisson avec Gianni Marangelli",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/TSp-3b1lXAM?si=DJ2txxyEV_Q8rVXa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
  },
  {
    title: "Sacro-iliaque",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/GU8FK2AHvYI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Hanches",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/1qZ28Jhxoy4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Lombaires",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/VOrHgv8jp7o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Chevilles et pieds",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/WJV2arB4jRY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Genou",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/YVdwwF1nAKE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Les cervicales",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/RuXGZMtsLkg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Dorsale",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/jAOWXYIHcG8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Diaphragme",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/9BTE1lMMcCg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Poignet",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/SEUHJ0efsZw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Coude",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/NFEyZm_0SiU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Exercices crâniens",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/2tRf1SYfZXk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Les épaules",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/Mf7ZKdnb6RU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
  {
    title: "Viscerales",
    iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/AHnuzWE2DKM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>',
  },
]

const ELearning = () => {
  return (
    <Box>
      <Heading
        as="h1"
        fontWeight={700}
        fontSize={28}
        mb={8}
        color="tertiary.500"
      >
        E-learning
      </Heading>
      <Flex flexDir="column" gap={4}>
        {ELearningDatas.map((data, index) => (
          <Box key={index}>
            <Heading as="h2" fontSize={22} color="tertiary.500" mb={2}>
              {data.title}
            </Heading>
            <Box dangerouslySetInnerHTML={{ __html: data.iframe }} />
          </Box>
        ))}
      </Flex>
    </Box>
  )
}

export default ELearning

import { Box, Flex, useDisclosure } from "@chakra-ui/react"
import Billing from "components/Billing/Billing"
import Subscription from "components/Subscription/Subscription"
import { useAppContext } from "../../AppContext"
import EditProfil from "../../components/EditProfil"

const Profile = () => {
  const { user, setUser } = useAppContext()

  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Box>
      <EditProfil user={user} setUser={setUser} />
      <Flex mt={12} gap={12} alignItems="flex-start">
        <Subscription isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        <Billing />
      </Flex>
    </Box>
  )
}

export default Profile

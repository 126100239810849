import { Divider, Flex, Heading, Text } from "@chakra-ui/react"
import { GlobalStatsType } from "api/stats"
import AnimatedNumber from "components/Stats/AnimatedNumber"
import Chart from "components/Stats/Chart"
import SingleData from "components/Stats/SingleData"
import getChartOptions from "utils/chart/getChartOptions"
import getMonthsAbrev from "utils/chart/getMonthsAbrev"
import getChartData from "../../utils/chart/getChartData"

interface GlobalStatsProps {
  globalStats: GlobalStatsType | undefined
}
const GlobalStats = ({ globalStats }: GlobalStatsProps) => {
  if (!globalStats) {
    return <Flex>Aucune donnée</Flex>
  }

  const monthAbreviationsAppointments = getMonthsAbrev(
    globalStats?.appointmentsByMonth || []
  )

  const seriesAppointmentsByMonth = getChartData(
    globalStats.appointmentsByMonth,
    ["Consultations"]
  )
  const optionsAppointmentsByMonth = getChartOptions(
    monthAbreviationsAppointments,
    ["#f5a087"]
  )

  const otherDataFirstLabel =
    "Nombre de patients ayant utilisé\nAndrew ces 7 derniers jours"

  const otherDataSecondLabel = `Nombre de passage total sur notre\ncarte : "trouvez un thérapeute\nAndrew près de chez vous`
  return (
    <Flex direction={"column"} wrap={"wrap"} w={"full"}>
      <Heading as="h2" mb={6} fontSize={24} color="tertiary.500">
        Statistiques generales de l'utilisation de l'application Andrew
      </Heading>
      <Flex direction={"column"} gap={6} w={"full"}>
        <Flex wrap={"wrap"} w={"full"} gap={6} justify={"space-between"}>
          <SingleData
            value={globalStats.activePatients}
            label={"Nombre de patients actifs après la\nconsultation"}
            unit={"%"}
          />
          <SingleData
            // value={globalStats.averageEngagmentTime}
            value={47}
            label={"Temps moyen de l’utilisation de\nl’application par patient"}
            unit={"min"}
          />
          <SingleData
            value={globalStats.patientCompletingExosPercentage}
            label={"Part des patients finissant leurs\nexercices"}
            unit={"%"}
          />
          <SingleData
            value={globalStats.averageScreenViewByPatient}
            label={"Moyenne du nombre d'écrans\nAndrew vus par patient"}
          />
        </Flex>
        <Flex wrap={"wrap"} w={"full"} justify={"space-between"} gap={6}>
          <Flex>
            <Chart
              title="Nombre de consultations"
              options={optionsAppointmentsByMonth}
              series={seriesAppointmentsByMonth}
              chartWidth={850}
              showTotalData={true}
              labelColor={"#ef7560"}
            />
          </Flex>
          <Flex
            direction={"column"}
            p={6}
            borderRadius={"2xl"}
            bg={"white"}
            w={420}
            gap={6}
            h={"fit-content"}
          >
            <Flex direction={"column"} gap={2}>
              <Text color={"common.500"} fontSize={"sm"}>
                Autres données
              </Text>
              <Divider orientation="horizontal" />
            </Flex>
            <Flex w={"full"} justify={"space-between"}>
              <Text
                whiteSpace="pre-wrap"
                dangerouslySetInnerHTML={{ __html: otherDataFirstLabel }}
              />
              <Text as={"b"} color={"primary.500"} fontSize={"22px"}>
                <AnimatedNumber end={globalStats.useForAWeek} />
              </Text>
            </Flex>
            <Divider orientation="horizontal" />
            <Flex w={"full"} justify={"space-between"}>
              <Text
                whiteSpace="pre-wrap"
                dangerouslySetInnerHTML={{ __html: otherDataSecondLabel }}
              />
              <Text as={"b"} color={"primary.500"} fontSize={"22px"}>
                <AnimatedNumber end={globalStats.mapViewCount.value} />
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default GlobalStats

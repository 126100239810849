import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import React from "react"
import ASlider from "./components/ASlider"

interface IProps {
  pain: number
  setPain: React.Dispatch<React.SetStateAction<number>>
  stress: number
  setStress: React.Dispatch<React.SetStateAction<number>>
  frequency: number
  setFrequency: React.Dispatch<React.SetStateAction<number>>
  mobility: number
  setMobility: React.Dispatch<React.SetStateAction<number>>
}

const Questionnaire: React.FC<IProps> = ({
  pain,
  setPain,
  stress,
  setStress,
  frequency,
  setFrequency,
  mobility,
  setMobility,
}) => {
  return (
    <Flex alignItems="center" gap={24}>
      <Box mb={12} maxW={460}>
        <Heading mb={4} fontSize={22}>
          Questionnaire de santé
        </Heading>
        <ASlider
          label="Douleur"
          subtitle={`Comment jugeriez-vous l'intensité de vos douleurs ? (0 : pas de douleur ; 10 : douleur insupportable).`}
          value={pain}
          handleChange={setPain}
        />
        <ASlider
          label="Fréquence"
          subtitle={`Comment qualifierez-vous la fréquence de vos douleurs ? (0 : pas de douleur ;  10 : constantes)`}
          value={frequency}
          handleChange={setFrequency}
        />
        <ASlider
          label="Mobilité"
          subtitle={`Vous sentez vous limité.e dans vos mouvements ? (0 : pas du tout limité.e ; 10 : très limité.e)`}
          value={mobility}
          handleChange={setMobility}
        />
        <ASlider
          label="Stress"
          subtitle={`Comment évaluez-vous votre niveau de stress ? (0 : pas de stress ; 10 : très stressé)`}
          value={stress}
          handleChange={setStress}
        />
      </Box>
      <Box bg="secondary.500" w={340} borderRadius={9} p={4}>
        <Text>
          A ce stade, bien sensibiliser votre patient sur la nécessité de :{" "}
          <br />
          * réaliser les exercices consciencieusement pour maximiser les effets
          de la consultation. <br />* remplir les questionnaires de
          symptomatologie afin que vous puissiez avoir un suivi de qualité de
          votre patient.
        </Text>
      </Box>
    </Flex>
  )
}

export default Questionnaire

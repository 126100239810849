import { CheckIcon } from "@chakra-ui/icons"
import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  Icon,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react"
import { StripeProductType } from "api/subscriptions"
import { useId } from "react"
import { MdOutlineGroupAdd, MdOutlinePerson } from "react-icons/md"
import formatCurrency from "utils/price/formatCurrency"
import getPriceAndPerSeat from "utils/price/getPriceAndPerSeat"

// CONSTANTS
const CONTENT_VARIANTS = {
  individual: {
    titleEnd: "Individuel",
    description: "Pour les thérapeutes qui travaillent seuls.",
    icon: MdOutlinePerson,
  },
  center: {
    titleEnd: "Centre",
    description: "Pour les thérapeutes qui collaborent à une même adresse.",
    icon: MdOutlineGroupAdd,
  },
} as const

type VariantType = keyof typeof CONTENT_VARIANTS

// COMPONENTS
interface StripeProductCardProps {
  product: StripeProductType
  isSelected: boolean
  onSelect: (product: StripeProductType) => void
  variant: VariantType
}

const StripeProductCard = ({
  product,
  isSelected,
  onSelect,
  variant,
}: StripeProductCardProps) => {
  const headingId = useId()
  const { titleEnd, description, icon } = CONTENT_VARIANTS[variant]
  const title = `Forfait ${titleEnd}`

  const { price, perSeat } = getPriceAndPerSeat(product, variant)

  return (
    <Flex
      as="button"
      aria-labelledby={headingId}
      w="50%"
      key={product.id}
      onClick={() => onSelect(product)}
      cursor="pointer"
      flexDir={"column"}
      alignItems={"center"}
      justifyContent="space-between"
      borderRadius={12}
      position="relative"
      border={"3px solid"}
      borderColor={isSelected ? "primary.500" : "primary.100"}
    >
      <Box
        w="100%"
        bg="primary.300"
        borderTopLeftRadius={isSelected ? 6 : 12}
        borderTopRightRadius={isSelected ? 6 : 12}
        borderLeft="1px solid"
        borderRight="1px solid"
        borderTop="1px solid"
        borderColor="primary.500"
        p={4}
        pr={8}
        gap={2}
      >
        <Flex align="center">
          <Icon fontSize={20} as={icon} color="tertiary.700" mr={2} />
          <Heading
            id={headingId}
            as="span"
            fontSize={18}
            color="tertiary.700"
            textAlign="center"
            whiteSpace="nowrap"
          >
            {title}
          </Heading>
        </Flex>
        <Text textAlign="start" fontSize="16" color="tertiary.700">
          {description}
        </Text>
      </Box>
      <Flex
        flexDir="column"
        alignItems="start"
        justifyContent="space-between"
        h="100%"
        w="100%"
        p={6}
        borderBottomLeftRadius={isSelected ? 6 : 12}
        borderBottomRightRadius={isSelected ? 6 : 12}
        borderLeft="1px solid"
        borderRight="1px solid"
        borderBottom="1px solid"
        borderColor="primary.500"
      >
        <Box mb={6}>
          <Flex alignItems="baseline" gap={2} whiteSpace="nowrap">
            {variant === "center" && (
              <Text fontSize={18} fontWeight={700}>
                Dès
              </Text>
            )}
            <Heading as="span" fontSize={26} fontWeight={700}>
              {/* we always want default price and currency */}
              {price}
              {formatCurrency(product.price.currency)}
            </Heading>
            <Text fontSize={18} fontWeight={700}>
              {" "}
              / mois
            </Text>
          </Flex>
          {perSeat && (
            <Text fontSize={18} mt={4}>
              {/* we always want default price and currency */}
              Soit {perSeat}
              {formatCurrency(product.price.currency)} / mois / thérapeute
            </Text>
          )}
        </Box>
        {variant === "individual" ? (
          <List spacing={2}>
            <ListItem textAlign="left">
              <ListIcon as={CheckIcon} color="secondary.800" />
              Accès à l'algorithme de recommandation d&apos;exercices.
            </ListItem>
            <ListItem textAlign="left">
              <ListIcon as={CheckIcon} color="secondary.800" />
              Suivi et analyse de vos patients.
            </ListItem>
            <ListItem textAlign="left">
              <ListIcon as={CheckIcon} color="secondary.800" />
              Accès complet à tous les contenus de l&apos;Explorer.
            </ListItem>
            <ListItem textAlign="left">
              <ListIcon as={CheckIcon} color="secondary.800" />
              Nombre de patients illimité.
            </ListItem>
          </List>
        ) : (
          <>
            <Text align="start" fontSize={16} fontWeight="bold">
              Toutes les fonctionnalités du forfait Individuel, mais aussi :
            </Text>
            <List spacing={2}>
              <ListItem textAlign="left">
                <ListIcon as={CheckIcon} color="secondary.800" />
                Suivi des patients partagé entre tous les thérapeutes du centre.
              </ListItem>
            </List>
          </>
        )}
        <Flex align="center" justify="center" mt={6} width="100%">
          <Center
            borderRadius="50%"
            w={5}
            h={5}
            border="1px solid"
            mr={2}
            borderColor={isSelected ? "primary.500" : "common.500"}
            background={isSelected ? "primary.500" : "transparent"}
          >
            {isSelected && <CheckIcon color="white" fontSize={12} />}
          </Center>
          <Text fontWeight="bold" fontSize={18}>
            Sélectionner
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default StripeProductCard

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Flex,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CurrencyCode, StripePrice, StripeProductType } from "api/subscriptions"
import AButton from "components/AButton"
import { format } from "date-fns"
import { useRef } from "react"
import { MdPlaylistAdd, MdPlaylistRemove } from "react-icons/md"
import AndrewThinking from "assets/illustrations/andrew-interrogateur.png"
import formatCurrency from "utils/price/formatCurrency"
import getLocalizedPricePerPeriodPerSeat from "utils/price/getLocalizedPricePerPeriodPerSeat"
import getSubPricePerPeriodPerSeat from "utils/price/getSubPricePerPeriodPerSeat"
import { updateSubscriptionBilling } from "api/subscriptions"
import useToast from "hooks/useToast"
import getMeQueryKeys from "constants/queryKeys/getMe"
import { useAppContext } from "AppContext"
import getCurrencyCodeForCountry from "utils/price/getCurrencyCodeForCountry"

// HELPERS
const getSubscriptionTitle = (subscription: StripeSubscription) =>
  `Forfait Individuel - ${
    subscription.plan.interval === "month" ? "mensuelle" : "annuelle"
  }`

const getPriceTitle = (price: StripePrice) =>
  `Forfait Individuel - ${
    price.recurring.interval === "month" ? "mensuelle" : "annuelle"
  }`

// COMPONENTS
interface OsteoBillingProrationProps {
  isOpen: boolean
  onClose: () => void
  subscription: StripeSubscription
  nextStripeProduct: StripeProductType
}
const OsteoBillingProration = ({
  isOpen,
  onClose,
  subscription,
  nextStripeProduct,
}: OsteoBillingProrationProps) => {
  const { jwt, user } = useAppContext()
  const cancelRef = useRef(null)
  const toast = useToast()
  const queryClient = useQueryClient()

  const isCurrentBillingYearly = subscription.plan.interval === "year"

  const currencyCode = getCurrencyCodeForCountry(
    user?.osteo.mainAddress?.country
  ) as CurrencyCode
  const currencyPrice = nextStripeProduct.pricesPerCurrency[currencyCode]

  const { monthly, yearly } = getSubPricePerPeriodPerSeat(subscription)
  const { monthly: productMonthly, yearly: productYearly } =
    getLocalizedPricePerPeriodPerSeat(nextStripeProduct, currencyCode)

  const updateSubscriptionBillingMutation = useMutation({
    mutationFn: () =>
      updateSubscriptionBilling({
        priceId: nextStripeProduct.pricesPerCurrency[currencyCode].id,
        subscriptionId: subscription.id,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Périodicité modifiée",
      })
      onClose()
      queryClient.invalidateQueries({
        queryKey: getMeQueryKeys.me(jwt!),
      })
    },
    onError: (error: any) => {
      const isNotFoundError = error?.response?.status === 404
      toast({
        status: "error",
        title: isNotFoundError
          ? "La souscription est introuvable"
          : "Une erreur s'est produite",
      })
    },
  })

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent bg="primary.100" minW={836} p={12} pb={6} px={36}>
        <Image
          position="absolute"
          left={8}
          top={28}
          w={86}
          h={117}
          src={AndrewThinking}
          alt="Andrew Interrogateur"
        />

        <AlertDialogHeader
          mb={6}
          p={0}
          fontSize={22}
          color="tertiary.500"
          fontWeight={700}
          fontFamily="Montserrat"
        >
          Modifier ma périodicité
        </AlertDialogHeader>
        <AlertDialogBody pb={10} pt={0} px={0}>
          {isCurrentBillingYearly ? (
            <Text as="p" color="tertiary.500">
              Vous souhaitez passer d'une facturation&nbsp;
              <Text as="span" fontWeight="bold">
                annuelle
              </Text>
              &nbsp;à une facturation&nbsp;
              <Text as="span" fontWeight="bold">
                mensuelle
              </Text>
              &nbsp;?
            </Text>
          ) : (
            <Text as="p" color="tertiary.500">
              <Text as="span" fontWeight="bold">
                Économisez
              </Text>
              &nbsp;en passant de la facturation&nbsp;
              <Text as="span" fontWeight="bold">
                mensuelle
              </Text>
              &nbsp;à la facturation&nbsp;
              <Text as="span" fontWeight="bold">
                annuelle
              </Text>
            </Text>
          )}
          <Flex
            mt={6}
            direction="column"
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="primary.300"
            justifyContent="center"
            gap={4}
            py={4}
          >
            <Flex direction="row" align="center" gap={4}>
              <Icon fontSize={24} as={MdPlaylistRemove} aria-label="Retiré" />
              <Flex
                direction="row"
                align="center"
                justify="space-between"
                gap={10}
                flexGrow={1}
              >
                <Text
                  fontSize={18}
                  fontWeight={700}
                  color="common.800"
                  textDecorationLine="line-through"
                >
                  {getSubscriptionTitle(subscription)}
                </Text>
                <Flex
                  direction="column"
                  justify="center"
                  align="flex-end"
                  gap={1}
                >
                  <Text
                    fontSize={26}
                    fontWeight={700}
                    textDecorationLine="line-through"
                    color={
                      isCurrentBillingYearly ? "common.800" : "primary.900"
                    }
                  >
                    {monthly} {formatCurrency(subscription.currency)}{" "}
                    <Text as="span" color="inherit">
                      / mois
                    </Text>
                  </Text>
                  <Text
                    fontSize={16}
                    textDecorationLine="line-through"
                    color="common.800"
                  >
                    Soit {yearly} {formatCurrency(subscription.currency)} / an
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="row" align="center" gap={4}>
              <Icon fontSize={24} as={MdPlaylistAdd} aria-label="Ajouté" />
              <Flex
                direction="row"
                align="center"
                justify="space-between"
                gap={10}
                flexGrow={1}
              >
                <Text fontSize={18} fontWeight={700} color="common.800">
                  {getPriceTitle(currencyPrice)}
                </Text>
                <Flex
                  direction="column"
                  justify="center"
                  align="flex-end"
                  gap={1}
                >
                  <Text
                    fontSize={26}
                    fontWeight={700}
                    color={
                      isCurrentBillingYearly ? "common.800" : "secondary.800"
                    }
                  >
                    {productMonthly} {formatCurrency(currencyPrice.currency)}{" "}
                    <Text as="span" color="inherit">
                      / mois
                    </Text>
                  </Text>
                  <Text fontSize={16} color="common.800">
                    Soit {productYearly}{" "}
                    {formatCurrency(currencyPrice.currency)} / an
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          {!isCurrentBillingYearly && (
            <Flex mt={6} gap={10} align="center" justify="space-between">
              <Text
                fontFamily="Montserrat"
                fontSize={18}
                fontWeight={700}
                color="common.800"
              >
                Total
              </Text>
              <Flex
                direction="column"
                justify="center"
                align="flex-end"
                gap={2}
              >
                <Text
                  fontFamily="Montserrat"
                  fontSize={22}
                  textDecorationLine="line-through"
                  color="common.800"
                >
                  {yearly} {formatCurrency(subscription.currency)} / an
                </Text>
                <Text fontFamily="Montserrat" fontSize={26} fontWeight={700}>
                  {productYearly} {formatCurrency(currencyPrice.currency)} / an
                </Text>
              </Flex>
            </Flex>
          )}
        </AlertDialogBody>
        <AlertDialogFooter
          flexDirection="column"
          alignItems="center"
          gap={8}
          p={0}
        >
          <Flex direction="column" gap={2}>
            <Text>
              Votre nouvel abonnement{" "}
              <Text as="span" fontWeight={700}>
                prendra effet le{" "}
                {format(
                  new Date(subscription.current_period_end * 1000),
                  "dd/MM/yyyy"
                )}
              </Text>
            </Text>
            <AButton
              ml={3}
              text={`Passer à la facturation ${
                isCurrentBillingYearly ? "mensuelle" : "annuelle"
              }`}
              py={3}
              px={4}
              type="button"
              onClick={() => updateSubscriptionBillingMutation.mutate()}
              isLoading={updateSubscriptionBillingMutation.isLoading}
            />
          </Flex>
          <AButton
            type="button"
            ref={cancelRef}
            onClick={onClose}
            text="Annuler"
            variant="tertiary"
            py={3}
            px={27}
          />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default OsteoBillingProration

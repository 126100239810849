import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import arrowBack from "../../../../../../assets/arrow-back.svg"
import React from "react"

interface IProps {
  setStep?: React.Dispatch<React.SetStateAction<number>>
  onBack?: () => void
  reason?: "zones" | "pathologies" | "no" | "no-exos" | null
  isForBaby?: boolean
}

const Header: React.FC<IProps> = ({
  setStep,
  onBack,
  reason,
  isForBaby = false,
}) => {
  const onReturn = () => {
    if (setStep) {
      if (reason === "no-exos") setStep(0)
      else setStep(1)
    } else if (onBack) {
      onBack()
    }
  }

  const returnText = setStep
    ? reason === "no-exos"
      ? "Retour au choix de la consultation"
      : "Retour au choix des exercices"
    : "Retour au détail de la consultation"
  return (
    <>
      <Box w="68%" mb="32px">
        <Flex mb={6} cursor="pointer" onClick={onReturn} w={"fit-content"}>
          <Image src={arrowBack} mr={2} />
          <Text>{returnText}</Text>
        </Flex>
        {isForBaby ? (
          <Heading fontSize={22} color="tertiary.500" mb={6}>
            Contenu nourrisson
          </Heading>
        ) : (
          <>
            <Heading fontSize={22} color="tertiary.500" mb={6}>
              Conseiller du contenu Explorer
            </Heading>
            <Text fontSize="18px">
              "Mettez en avant le contenu Explorer qui vous semble le plus
              pertinent pour votre patient. Notez qu'il pourra dans tous les cas
              retrouver la totalité du contenu sur son application
            </Text>
          </>
        )}
      </Box>
    </>
  )
}

export default Header

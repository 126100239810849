import { TopicFullListType } from "api/topics"
import axiosInstance from "./axiosInstance"
import { MediaType } from "api/media"

export type VideoPreviewType = {
  id: number
  alternativeText: string
  formats: {
    small: {
      url: string
    }
  }
}

export type VideoContentType = {
  id: number
  url: string
  caption: string
}

export type VideoType = {
  id: number
  level: number
  limitedMode: boolean
  title: string
  preview: VideoPreviewType
  fullVideo: VideoContentType
  defaultDuration?: number
  group: TrainingVideoGroupsLightType[]
  videoGroup: number
}

export type SelectedExerciceType = VideoType & {
  duration: number
}

export type SelectedDiscoverType = MediaType

export type VideoGroupCategoryType = "souplesse" | "renfo" | "mobilite" | "nerf"
export type VideoGroupRestriction = {
  id: number
  name: string
}

export type TrainingVideoGroupsLightType = {
  id: number
  title: string
}

export type TrainingVideoGroupsType = TrainingVideoGroupsLightType & {
  videos: VideoType[]
  category: VideoGroupCategoryType
  restrictions: VideoGroupRestriction[]
  timeUsed: number
}

export type AppointmentReasonType = {
  id: number
  internalId: number
  name: string
  trainingVideoGroups: TrainingVideoGroupsType[]
  suggestedProgramme: VideoType[]
}

export const getAppointmentReason = async (id: number) => {
  const response = await axiosInstance.get<AppointmentReasonType>(
    `/osteo/me/appointmentReason/${id}`
  )
  return response
}

export type FavoriteProgram = {
  id: number | string
  title: string
  trainingVideos: VideoType[]
}

export const getAllFavoritePrograms = async (appointmentReasonId: number) => {
  const response = await axiosInstance.get<FavoriteProgram[]>(
    `/osteo/favorite-programs/${appointmentReasonId}`
  )
  return response.data
}

export const getAllFavoriteProgramsWithoutReason = async () => {
  const response = await axiosInstance.get<FavoriteProgram[]>(
    `/osteo/favorite-program/no-reason`
  )
  return response.data
}

export type CreateFavoriteProgram = {
  favoriteProgram: {
    id: string
    title: string
    trainingVideos: VideoType[]
  }
  appointmentReasonId?: number
}

export const newFavoriteProgramWithoutReason = async ({
  favoriteProgram,
}: CreateFavoriteProgram) => {
  const response = await axiosInstance.post(
    `/osteo/favorite-program/no-reason/new`,
    {
      title: favoriteProgram.title,
      trainingVideos: favoriteProgram.trainingVideos.map(({ id }) => id),
    }
  )
  return response.data
}

export const newFavoriteProgram = async ({
  favoriteProgram,
  appointmentReasonId,
}: CreateFavoriteProgram) => {
  const response = await axiosInstance.post(
    `/osteo/favorite-programs/${appointmentReasonId}/new`,
    {
      title: favoriteProgram.title,
      trainingVideos: favoriteProgram.trainingVideos.map(({ id }) => id),
    }
  )
  return response.data
}

export type UpdateFavoriteProgramParams = {
  id: number | string
  title: string
  trainingVideos: VideoType[]
}

export const updateFavoriteProgram = async (
  params: UpdateFavoriteProgramParams
) => {
  const response = await axiosInstance.post(
    `/osteo/favorite-program/${params.id}`,
    {
      title: params.title,
      trainingVideos: params.trainingVideos.map((video) => video.id),
    }
  )
  return response.data
}

export const deleteFavoriteProgram = async (id: number) => {
  const response = await axiosInstance.delete(`/osteo/favorite-program/${id}`)
  return response
}

import { ViewIcon } from "@chakra-ui/icons"
import { Box, Flex, Image, Link, Text } from "@chakra-ui/react"
import React from "react"
import { MediaAuthorType } from "../../../api/media"
import AButton from "../../../components/AButton"

interface IProps {
  author: MediaAuthorType
}

const AuthorDescription: React.FC<IProps> = ({ author }) => {
  return (
    <Box mb={6}>
      <Flex gap={4} alignItems="center" mb={2}>
        <Image
          w={16}
          h={16}
          borderRadius="50%"
          src={author?.avatar.formats.thumbnail.url}
        />
        <Box>
          <Text fontSize={18} fontWeight="bold">
            {author?.displayName}
          </Text>
          <Text fontSize={14} maxW={600} lineHeight={1.2}>
            {author?.description}
          </Text>
        </Box>
      </Flex>
      {author.redirectUrl && (
        <Link
          target="_blank"
          href={author.redirectUrl}
          textDecoration="none"
          _hover={{ textDecoration: "none" }}
        >
          <AButton text="Lien" variant="tertiary" leftIcon={<ViewIcon />} />
        </Link>
      )}
    </Box>
  )
}

export default AuthorDescription

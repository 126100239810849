import { Flex, Image, Checkbox, Text } from "@chakra-ui/react"
import { BabyMediaTag } from "api/media"
import React from "react"
import babyBack from "../../../../../../assets/babyMediaTag/baby-back.svg"
import portage from "../../../../../../assets/babyMediaTag/portage.svg"
import moteur from "../../../../../../assets/babyMediaTag/moteur.svg"
import AZoneMarker from "../ZonesPathologies/components/AZonePathologieMarker"

interface IProps {
  selectedBabyMediaTags: BabyMediaTag[] | null
  setSelectedBabyMediaTags: React.Dispatch<
    React.SetStateAction<BabyMediaTag[] | null>
  >
  babyMediaTags: BabyMediaTag[]
}

const BabyMediaTags: React.FC<IProps> = ({
  selectedBabyMediaTags,
  setSelectedBabyMediaTags,
  babyMediaTags,
}) => {
  if (selectedBabyMediaTags === null) {
    return null
  }

  const handleMediaTag = (newTag: BabyMediaTag) => {
    if (selectedBabyMediaTags.some((tag) => tag.key === newTag.key)) {
      setSelectedBabyMediaTags(
        selectedBabyMediaTags.filter((tag) => tag.key !== newTag.key)
      )
    } else {
      setSelectedBabyMediaTags([...selectedBabyMediaTags, newTag])
    }
  }
  const firstTags = babyMediaTags.slice(0, Math.ceil(babyMediaTags.length / 2))
  const lastTags = babyMediaTags.slice(Math.ceil(babyMediaTags.length / 2))

  return (
    <Flex direction={"row"} gap={"104px"}>
      <Flex h={"330px"} pos="relative">
        <Image src={babyBack} alt="Bébé de dos" />
        {firstTags.map((tag: BabyMediaTag, index) => (
          <AZoneMarker
            key={tag.key}
            name={tag.name}
            left={index === 0 ? "10" : "8"}
            top={index === 0 ? "0" : "120px"}
            code={`TAG_${tag.key.toUpperCase()}`}
            state={
              selectedBabyMediaTags.some((elt) => elt.key === tag.key)
                ? "active"
                : "default"
            }
            onClick={() => handleMediaTag(tag)}
          />
        ))}
      </Flex>
      {lastTags.map((tag: BabyMediaTag, index) => (
        <Flex
          h={"330px"}
          direction="column"
          alignItems={"center"}
          key={index}
          onClick={() => handleMediaTag(tag)}
          cursor={"pointer"}
        >
          <Image
            src={index === 0 ? portage : moteur}
            alt={tag.name}
            h={"282px"}
          />
          <Flex alignItems={"center"} gap={2}>
            <Checkbox
              colorScheme="orange"
              borderColor="black"
              isChecked={selectedBabyMediaTags.some(
                (babyTag) => babyTag.key === tag.key
              )}
            />
            <Text>{tag.name}</Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

export default BabyMediaTags

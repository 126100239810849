import { Box, Flex, Image, Link, Text } from "@chakra-ui/react"
import andrewLogo from "../assets/ANDREW_logo_color.png"
import React from "react"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"
import AProfileButton from "./AProfileButton/AProfileButton"
import SearchBar from "./SearchBar"
import AButton from "./AButton"
import { AddIcon } from "@chakra-ui/icons"
import AddOsteo from "./AddOsteo"
import { useAppContext } from "../AppContext"
import useCurrentCenterColor from "hooks/useCurrentCenterColor"
import useCenterSubscription from "hooks/useCenterSubscription"
import useStudentsCannotAddPatient from "hooks/useStudentsCannotAddPatient"
import useCenterIsTester from "hooks/useCenterIsTester"

const AHeader = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user, currentScope, jwt } = useAppContext()

  const { hasCurrentCenter, active, isLoading, isError } =
    useCenterSubscription()
  const currentCenterColor = useCurrentCenterColor()
  const studentsCannotAddPatient = useStudentsCannotAddPatient()
  const centerIsTester = useCenterIsTester()

  const canAddPatient = () => {
    if (currentScope?.type === "academy") return !studentsCannotAddPatient
    if (currentScope?.type === "center") return !centerIsTester
    return !user?.osteo?.isTester
  }

  return (
    <Box bg="primary.200">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={4}
        px={12}
        backgroundColor={currentCenterColor}
      >
        <Link as={RouterLink} to="/" _hover={{ textDecoration: "none" }}>
          <Flex alignItems="center">
            <Image src={andrewLogo} w={115} />{" "}
            <Text color="tertiary.500" fontSize={22} fontWeight={700} ml={1}>
              Pro
            </Text>
            {user?.osteo?.canAddOsteos === true && <AddOsteo />}
          </Flex>
        </Link>

        <Flex alignItems="center" justifyContent="flex-end" flex={1}>
          <Flex mr={120} flex={1} ml={20}>
            <SearchBar
              isDisabled={hasCurrentCenter && (isLoading || isError)}
            />
            {canAddPatient() && (
              <AButton
                text="Ajouter un patient"
                px={35}
                py={5}
                leftIcon={<AddIcon w={3} h={3} />}
                fontFamily="Montserrat"
                ml={6}
                onClick={() => navigate("/patients/new")}
                isLoading={hasCurrentCenter && isLoading}
                isDisabled={hasCurrentCenter && (isError || active === false)}
              />
            )}
          </Flex>
          <Flex minW={300} justifyContent="flex-end">
            <AProfileButton />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderTop="1px solid"
        borderColor="primary.500"
        h="100%"
        px={12}
      >
        <Flex fontWeight={600} h="100%">
          <Link
            as={RouterLink}
            to="/"
            mr={37}
            color={pathname === "/" ? "primary.700" : "common.700"}
            _hover={{
              textDecoration: "none",
              _after: { transform: "scaleX(1)" },
            }}
            _after={{
              height: "5px",
              content: "''",
              display: "block",
              width: "100%",
              backgroundColor: "primary.500",
              transform: "scaleX(0)",
              transition: "transform 0.3s ease-in-out",
              transformOrigin: "left",
              position: "absolute",
              bottom: 0,
            }}
            py={5}
            position="relative"
          >
            Dashboard
          </Link>
          <Link
            as={RouterLink}
            mr={37}
            to="/dashboard"
            color={pathname === "/dashboard" ? "primary.700" : "common.700"}
            _hover={{
              textDecoration: "none",
              _after: { transform: "scaleX(1)" },
            }}
            _after={{
              height: "5px",
              content: "''",
              display: "block",
              width: "100%",
              backgroundColor: "primary.500",
              transform: "scaleX(0)",
              transition: "transform 0.3s ease-in-out",
              transformOrigin: "left",
              position: "absolute",
              bottom: 0,
            }}
            py={5}
            position="relative"
          >
            Consultations récentes
          </Link>
          <Link
            as={RouterLink}
            mr={37}
            to="/explorer"
            color={pathname === "/explorer" ? "primary.700" : "common.700"}
            _hover={{
              textDecoration: "none",
              _after: { transform: "scaleX(1)" },
            }}
            _after={{
              height: "5px",
              content: "''",
              display: "block",
              width: "100%",
              backgroundColor: "primary.500",
              transform: "scaleX(0)",
              transition: "transform 0.3s ease-in-out",
              transformOrigin: "left",
              position: "absolute",
              bottom: 0,
            }}
            py={5}
            position="relative"
          >
            Explorer
          </Link>
          <Link
            as={RouterLink}
            mr={37}
            to="/e-learning"
            color={pathname === "/e-learning" ? "primary.700" : "common.700"}
            _hover={{
              textDecoration: "none",
              _after: { transform: "scaleX(1)" },
            }}
            _after={{
              height: "5px",
              content: "''",
              display: "block",
              width: "100%",
              backgroundColor: "primary.500",
              transform: "scaleX(0)",
              transition: "transform 0.3s ease-in-out",
              transformOrigin: "left",
              position: "absolute",
              bottom: 0,
            }}
            py={5}
            position="relative"
          >
            E-learning
          </Link>
          <Link
            as={"a"}
            mr={37}
            href={`https://forum.andrewapp.fr/?jwt=${jwt}`}
            target={"_blank"}
            color={"common.700"}
            _hover={{
              textDecoration: "none",
              _after: { transform: "scaleX(1)" },
            }}
            _after={{
              height: "5px",
              content: "''",
              display: "block",
              width: "100%",
              backgroundColor: "primary.500",
              transform: "scaleX(0)",
              transition: "transform 0.3s ease-in-out",
              transformOrigin: "left",
              position: "absolute",
              bottom: 0,
            }}
            py={5}
            position="relative"
          >
            Forum Pro Andrew
          </Link>
          {user?.osteo?.canAddOsteos && (
            <Link
              as={RouterLink}
              to="/statistiques"
              color={
                pathname === "/statistiques" ? "primary.700" : "common.700"
              }
              _hover={{
                textDecoration: "none",
                _after: { transform: "scaleX(1)" },
              }}
              _after={{
                height: "5px",
                content: "''",
                display: "block",
                width: "100%",
                backgroundColor: "primary.500",
                transform: "scaleX(0)",
                transition: "transform 0.3s ease-in-out",
                transformOrigin: "left",
                position: "absolute",
                bottom: 0,
              }}
              py={5}
              position="relative"
            >
              Statistiques
            </Link>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default AHeader

import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
  useLayoutEffect,
} from "react"
import { ChakraProvider } from "@chakra-ui/react"
import "@fontsource/mulish/400.css"
import "@fontsource/mulish/700.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/700.css"
import theme from "./theme"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import AppContext, { CurrentScope } from "./AppContext"
import { MeType } from "./api/user"
import { useLoadScript } from "@react-google-maps/api"
import Layout from "./Layout"
import axiosInstance from "./api/axiosInstance"
import Rgpd, { RgpdBanner, RgpdCustom, RgpdProvider } from "./components/Rgpd"
import { Helmet } from "react-helmet"
import userHasNoPersonalAccount from "utils/user/userHasNoPersonalAccount"
import userHasAcademyInCurrentScope from "utils/currentScope/userHasAcademyInCurrentScope.ts"
import userHasCenterInCurrentScope from "utils/currentScope/userHasCenterInCurrentScope"
import isCenterAdminCurrentScope from "utils/currentScope/isCenterAdmin"
import GeolocationContextProvider from "components/Geolocation/GeolocationContext"

declare global {
  interface Window {
    sendinblue: any
    fbq: any
  }
}

const queryClient = new QueryClient()
const libraries: ("places" | "drawing" | "geometry" | "localContext")[] = [
  "places",
]

// HELPERS
const getCleverCurrentScope = (
  user: MeType,
  setCurrentScope: Dispatch<SetStateAction<CurrentScope | null>>
) => {
  const firstCenter = user.osteo.centers?.[0]

  // check for first center as clever current scope
  if (firstCenter) {
    const centerGroupId = firstCenter.centerGroup?.id
    const adminCenterGroupWithCenterGroupId =
      user.osteo.adminCenterGroups?.find(({ id }) => id === centerGroupId)
    // if also admin of center group, add centerGroupId
    if (adminCenterGroupWithCenterGroupId) {
      return setCurrentScope({
        type: "center",
        id: firstCenter.id,
        centerGroupId: adminCenterGroupWithCenterGroupId?.id,
      })
    }
    return setCurrentScope({
      type: "center",
      id: firstCenter.id,
    })
  }

  const firstAcademyId = user.osteo.academies?.[0]?.id

  // else check for first academy as clever current scope
  if (firstAcademyId) {
    return setCurrentScope({
      type: "academy",
      id: firstAcademyId,
    })
  }
}

const currentScopeInitializer: () => CurrentScope | null = () => {
  const storedCurrentScope = localStorage.getItem("scope")
  return storedCurrentScope ? JSON.parse(storedCurrentScope) : null
}

export const App = () => {
  const [jwt, setJwt] = useState(localStorage.getItem("jwt"))
  const [user, setUser] = useState<MeType | null>(null)
  const [currentScope, setCurrentScope] = useState<CurrentScope | null>(
    currentScopeInitializer
  )

  const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.REACT_APP_GTM_ID}');`

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
    libraries,
  })

  // JWT management
  useLayoutEffect(() => {
    if (jwt) {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${jwt}`
    }
  }, [jwt])

  const updateJwt = useCallback(
    (nextJwt: string | null) => {
      setJwt(nextJwt)
      axiosInstance.defaults.headers.common["Authorization"] = nextJwt
        ? `Bearer ${nextJwt}`
        : ""
      if (nextJwt) {
        return localStorage.setItem("jwt", nextJwt)
      }
      localStorage.removeItem("jwt")
      setCurrentScope(null)
    },
    [setJwt, setCurrentScope]
  )

  // current scope profile management
  useEffect(() => {
    if (currentScope) {
      localStorage.setItem("scope", JSON.stringify(currentScope))
    } else {
      localStorage.removeItem("scope")
    }
  }, [currentScope])

  const checkLocalToken = useCallback(() => {
    const localToken = localStorage.getItem("jwt")

    if (!localToken) {
      setJwt(null)
      setCurrentScope(null)
    }
  }, [])

  // Triggered automatically when token is deleted from localstorage
  useEffect(() => {
    window.addEventListener("storage", checkLocalToken)
    return () => {
      window.removeEventListener("storage", checkLocalToken)
    }
  }, [checkLocalToken])

  const setCurrentScopeWithCheck = (currentScope: CurrentScope | null) => {
    // In case we select an academy, we need to check that the user is linked to this academy
    if (userHasAcademyInCurrentScope(user, currentScope))
      return setCurrentScope(currentScope)

    if (userHasCenterInCurrentScope(user, currentScope))
      return setCurrentScope(currentScope)
    // If the user is not linked to the academy, or if he selects a personal account, we set the current scope to null
    return setCurrentScope(null)
  }

  const setCenterAdminCurrentScope = (currentScope: CurrentScope | null) => {
    // current scope can only be center and have a centerGroupId
    if (isCenterAdminCurrentScope(currentScope)) {
      return setCurrentScope(currentScope)
    }
    // else we set the current scope to null
    return setCurrentScope(null)
  }

  const cleverLogin = (nextUser: MeType) => {
    // Aucun scope n'est renseigné
    const hasNoScope = !currentScope
    // Aucun compte perso n'existe pour l'utilisateur en cours
    const hasNoPersonalAccount = userHasNoPersonalAccount(nextUser)

    // tentative de définir un currentScope
    if (hasNoScope && hasNoPersonalAccount) {
      getCleverCurrentScope(nextUser, setCurrentScope)
    }
    setUser(nextUser)
  }

  if (!isLoaded) return null
  return (
    <RgpdProvider>
      <Rgpd serviceId="gtm">
        <Helmet>
          <script type="text/javascript">{gtmScript}</script>
          <script type="text/javascript">
            {`window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "pfxg1h9c"
  }`}
          </script>
          <script type="text/javascript">
            {`// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/pfxg1h9c'
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/pfxg1h9c';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload', l);}else{w.addEventListener('load', l, false);}}})();`}
          </script>
        </Helmet>
      </Rgpd>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <AppContext.Provider
            value={{
              jwt,
              setJwt: updateJwt,
              user,
              setUser,
              currentScope,
              setCurrentScope: setCurrentScopeWithCheck,
              setCenterAdminCurrentScope,
              cleverLogin,
            }}
          >
            <GeolocationContextProvider>
              <BrowserRouter>
                <Layout />
                <RgpdBanner />
                <RgpdCustom />
              </BrowserRouter>
            </GeolocationContextProvider>
          </AppContext.Provider>
        </ChakraProvider>
      </QueryClientProvider>
    </RgpdProvider>
  )
}

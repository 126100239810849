import { Button, ButtonProps, Text } from "@chakra-ui/react"
import { ReasonType } from "api/patient"
import React from "react"

interface IProps extends ButtonProps {
  reason: ReasonType
  isSelected: boolean
}

const theme = {
  selected: {
    bg: "primary.500",
    color: "white",
    fontWeight: "bold",
    fontSize: "22",
    _hover: {
      bg: "primary.500",
    },
  },
  default: {
    bg: "transparent",
    color: "primary.500",
    fontWeight: "normal",
    fontSize: "18",
    _hover: {
      bg: "transparent",
    },
  },
}

const HeaderTab: React.FC<IProps> = ({ reason, isSelected, ...props }) => {
  return (
    <Button
      {...props}
      bg={theme[isSelected ? "selected" : "default"].bg}
      color={theme[isSelected ? "selected" : "default"].color}
      fontWeight={theme[isSelected ? "selected" : "default"].fontWeight}
      fontSize={theme[isSelected ? "selected" : "default"].fontSize}
      _hover={{
        bg: theme[isSelected ? "selected" : "default"]._hover.bg,
      }}
      _focus={{
        bg: theme[isSelected ? "selected" : "default"]._hover.bg,
      }}
      borderRadius="9px 9px 0 0"
      flex={1}
      minW="fit-content"
    >
      <Text>{reason.name}</Text>
    </Button>
  )
}

export default HeaderTab

import { IconButton, ButtonProps, Image } from "@chakra-ui/react"
import EditIcon from "../assets/edit.svg"
import React from "react"

interface IProps extends ButtonProps {
  ariaLabel?: string
}

const AEditButton: React.FC<IProps> = ({
  ariaLabel = "Modifier",
  ...props
}) => {
  return (
    <IconButton
      w={8}
      h={8}
      bg="primary.500"
      icon={<Image src={EditIcon} />}
      aria-label={ariaLabel}
      borderRadius="50%"
      minWidth={8}
      _hover={{ bg: "primary.600" }}
      {...props}
    />
  )
}

export default AEditButton

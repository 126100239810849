import { ReasonType } from "api/patient"

export interface ZonePathologieType extends ReasonType {
  code: string
  left: string
  top: string
  schema: number
  secondarySchema?: number
  zIndex?: number
}
export const PATHOLOGIES_MOCK: ZonePathologieType[] = [
  {
    name: "Sciatique",
    code: "SCIATIQUE",
    id: 1,
    left: "45px",
    top: "235px",
    schema: 2,
  },
  {
    name: "Cruralgie",
    code: "CRURALGIE",
    id: 2,
    schema: 1,
    left: "95px",
    top: "230px",
    zIndex: 10,
  },
  {
    name: "NCB",
    code: "NCB",
    id: 3,
    schema: 1,
    secondarySchema: 2,
    left: "85px",
    top: "70px",
    zIndex: 5,
  },
  {
    name: "Pubalgie",
    code: "PUBALGIE",
    id: 4,
    schema: 1,
    left: "70px",
    top: "260px",
  },
  {
    name: "Tendinite d'épaule",
    code: "TENDINITE_EPAULE",
    id: 5,
    schema: 1,
    left: "15px",
    top: "84px",
  },
  {
    name: "Pathologie du genou",
    code: "PATHOLOGIE_GENOU",
    id: 6,
    schema: 1,
    left: "42px",
    top: "344px",
  },
  {
    name: "Entorse cheville",
    code: "ENTORSE_CHEVILLE",
    id: 7,
    schema: 1,
    left: "53px",
    top: "470px",
  },
  {
    name: "Symptomatologies du pied et de la cheville",
    code: "EPINE_CALCANENE",
    id: 8,
    schema: 1,
    left: "68px",
    top: "495px",
  },
  {
    name: "Migraine et névralgies d’Arnold",
    code: "MIGRAINE",
    id: 9,
    schema: 1,
    secondarySchema: 2,
    left: "60px",
    top: "0px",
  },
  {
    name: "Tendinite interne du coude",
    code: "TENDINITE_INTERNE_COULE",
    id: 10,
    schema: 1,
    left: "23px",
    top: "175px",
  },
  {
    name: "Tendinite externe du coude",
    code: "TENDINITE_EXTERNE_COULE",
    id: 11,
    schema: 1,
    left: "3px",
    top: "170px",
  },
  {
    name: "Estomac",
    code: "ESTOMAC",
    id: 12,
    schema: 1,
    left: "85px",
    top: "180px",
    zIndex: 10,
  },
]

export const ZONES_MOCK: ZonePathologieType[] = [
  {
    name: "Lombaires",
    code: "LOMBALGIE",
    id: 13,
    left: "63px",
    top: "185px",
    schema: 2,
  },
  {
    name: "Dorsales",
    code: "DORSALGIE",
    id: 14,
    left: "60px",
    top: "100px",
    schema: 2,
  },
  {
    name: "Cervicales",
    code: "CERVICALGIE",
    id: 15,
    left: "74px",
    top: "70px",
    schema: 2,
  },
  {
    name: "Hanche",
    code: "HANCHE",
    id: 16,
    left: "33px",
    top: "217px",
    schema: 1,
  },
  {
    name: "Genou",
    code: "GENOU",
    id: 17,
    left: "42px",
    top: "345px",
    schema: 1,
  },
  {
    name: "Épaules",
    code: "EPAULES",
    id: 18,
    left: "15px",
    top: "84px",
    schema: 1,
  },
  {
    name: "Viscéral",
    code: "VISCERAL",
    id: 19,
    left: "61px",
    top: "180px",
    schema: 1,
  },
  {
    name: "Crâne",
    code: "CRANE",
    id: 20,
    left: "56px",
    top: "-4px",
    schema: 1,
    secondarySchema: 2,
  },
  {
    name: "Sacro iliaque",
    code: "SACRO_ILIAQUE",
    id: 21,
    left: "58px",
    top: "217px",
    schema: 2,
  },
  {
    name: "Cheville / Pied",
    code: "CHEVILLE_PIED",
    id: 22,
    left: "43px",
    top: "470px",
    schema: 1,
  },
  {
    name: "Poignet",
    code: "POIGNET",
    id: 23,
    left: "0px",
    top: "235px",
    schema: 1,
  },
  {
    name: "Diaphragme",
    code: "DIAPHRAGME",
    id: 24,
    left: "40px",
    top: "143px",
    schema: 1,
  },
  {
    name: "Coude",
    code: "COUDE",
    id: 25,
    left: "2px",
    top: "168px",
    schema: 1,
  },
]

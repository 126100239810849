import { Box, Flex, Heading, Image } from "@chakra-ui/react"
import React from "react"
import corpsFront from "../../../../../../assets/corps-front.svg"
import corpsBack from "../../../../../../assets/corps-back.svg"
import AZonePathologieMarker from "./components/AZonePathologieMarker"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"

interface IProps {
  title: string
  datas: ZonePathologieType[]
  selectedDatas: ZonePathologieType[]
  setSelectedDatas: React.Dispatch<React.SetStateAction<ZonePathologieType[]>>
}

const ZonesPathologies: React.FC<IProps> = ({
  title,
  datas,
  selectedDatas,
  setSelectedDatas,
}) => {
  const handleClick = (currentElt: ZonePathologieType) => {
    if (selectedDatas.find((elt) => elt.id === currentElt.id)) {
      setSelectedDatas((prev) => prev.filter((elt) => elt.id !== currentElt.id))
    } else {
      setSelectedDatas((prev) => [...prev, currentElt])
    }
  }

  return (
    <Box mb={75}>
      <Flex mb={14}>
        <Heading as="h6" fontSize={18}>
          {title}
        </Heading>
      </Flex>
      <Flex>
        <Box mr={54} ml={6} pos="relative">
          <div>
            <Image src={corpsFront} alt="Corps de devant" userSelect="none" />
            {datas
              .filter((data) => data.schema === 1 || data.secondarySchema === 1)
              .map(({ name, id, left, top, zIndex, code }, i) => (
                <AZonePathologieMarker
                  name={name}
                  code={code}
                  left={left}
                  top={top}
                  key={`front_${id}`}
                  zIndex={zIndex}
                  state={
                    selectedDatas.find((elt) => elt.id === id)
                      ? "active"
                      : "default"
                  }
                  onClick={() =>
                    handleClick(
                      datas.filter(
                        (data) =>
                          data.schema === 1 || data.secondarySchema === 1
                      )[i]
                    )
                  }
                />
              ))}
          </div>
        </Box>
        <Box mr={237} pos="relative">
          <Image src={corpsBack} alt="Corps de derrière" userSelect="none" />
          {datas
            .filter((data) => data.schema === 2 || data.secondarySchema === 2)
            .map(({ name, id, left, top, zIndex, code }, i) => (
              <AZonePathologieMarker
                name={name}
                code={code}
                left={left}
                top={top}
                key={`back_${id}`}
                zIndex={zIndex}
                state={
                  selectedDatas.find((elt) => elt.id === id)
                    ? "active"
                    : "default"
                }
                onClick={() =>
                  handleClick(
                    datas.filter(
                      (data) => data.schema === 2 || data.secondarySchema === 2
                    )[i]
                  )
                }
              />
            ))}
        </Box>
      </Flex>
    </Box>
  )
}

export default ZonesPathologies

import { SVGProps } from "pages/NewAppointment/components/SelectReason/components/ZonesPathologies/components/svg/type"
import React from "react"

const BabyHead: React.FC<SVGProps> = ({ fill, stroke }) => {
  return (
    <svg
      width="76"
      height="67"
      viewBox="0 0 76 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.9994 18.4992C47.3994 -11.1008 19.166 3.16589 7.49937 13.9992C4.49937 16.1661 -0.700635 25.6 2.49937 46C5.69937 66.4 32.4994 66.5 45.4994 64C60.8327 61.1664 86.5994 48.0992 66.9994 18.4992Z"
        fill={fill || "#F1FDF3"}
        stroke={stroke || "#A8D8C3"}
        fillOpacity="0.6"
        strokeWidth={3}
      />
    </svg>
  )
}

export default BabyHead

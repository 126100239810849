import axiosInstance from "./axiosInstance"
import axiosInstanceWithoutAuth from "./axiosInstanceWithoutAuth"

import { PatientType } from "./patient"

type LoginParams = {
  identifier: string
  password: string
}
export const login = (params: LoginParams) => {
  localStorage.removeItem("jwt")
  localStorage.removeItem("scope")
  return axiosInstance.post("/auth/local", params)
}

export interface UserAcademieType extends AcademieType {
  locations: AcademieLocationType[]
}

export type UserOsteoType = {
  id: number
  firstname: string
  lastname: string
  avatar?: AvatarType
  canAddOsteos?: boolean
  isOsteow?: boolean
  isTester?: boolean
  sessionId?: string
  mainAddress?: AddressType
  secondaryAddress?: AddressType
  telephone?: string
  urlDoctolib?: string
  patients?: PatientType[]
  hasUsedTrial?: boolean
  academies?: UserAcademieType[]
  adminAcademies?: AcademieType[]
  adminCenterGroups?: CenterGroupType[]
  centers?: CenterType[]
  adeli?: string
  school?: string
  hasAcceptedCGS?: boolean
  urlGoogleMyBusiness?: string
}

type StripeCustomerType = {
  id: string
}

type RoleType = {
  id: Number
}

export type MeType = {
  id: number
  email: string
  osteo: UserOsteoType
  passwordInitiated: boolean
  stripeCustomer?: StripeCustomerType
  stripeSubscription?: StripeSubscription
  role: RoleType
}

export const getMe = () =>
  axiosInstance.get<MeType>(`/osteo/me`).then((response) => response.data)

export type RegisterUserType = {
  username: string
  email: string
  password: string
  passwordInitiated: boolean
  cooptionToken?: string | null
  urlGoogleMyBusiness?: string
}

export const registerUser = (data: RegisterUserType) => {
  return axiosInstanceWithoutAuth
    .post("/auth/local/register", data, {
      headers: {
        authorization: "",
      },
    })
    .then((response) => response.data.user)
}

export type InitiatePasswordType = {
  password: string
}
export const initiatePassword = (data: InitiatePasswordType) =>
  axiosInstance
    .post("/password/defineFirstPassword", {
      newPassword: data.password,
    })
    .then((response) => response.data)

export type ForgotPasswordType = {
  email: string
}

export const forgotPassword = (data: ForgotPasswordType) =>
  axiosInstance
    .post("/auth/forgot-password", { email: data.email })
    .then((response) => response.data)

export type ResetPasswordType = {
  code: string
  password: string
  passwordConfirmation: string
}

export const resetPassword = (data: ResetPasswordType) => {
  return axiosInstance
    .post("/auth/reset-password", data, {
      headers: {
        Authorization: "",
      },
    })
    .then((response) => response.data)
}

import {
  FormLabel,
  Grid,
  GridItem,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { Form, Formik, FormikHelpers } from "formik"
import AAdressField from "../../../components/Field/AAdressField"
import AInputField from "../../../components/Field/AInputField"
import * as Yup from "yup"
import validateAddress from "../../../utils/validators/validateAddress"
import ASubmitButton from "../../../components/ASubmitButton"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAppContext, useCenterAdminCurrentScope } from "AppContext"
import useToast from "../../../hooks/useToast"
import ACenterAvatarField from "components/Field/ACenterAvatarField"
import validateAvatarSize from "utils/validators/validateAvatarSize"
import { uploadAvatar } from "api/upload"
import { addCenter, AdminCenterGroup, editCenter } from "api/centerGroup"
import { centerGroupQueryKeys } from "pages/Center/constants/queryKeys"
import ASelectColorField from "components/Field/ASelectColorField"
import getMeQueryKeys from "constants/queryKeys/getMe"

// CONSTANTS
type EditCenterType = {
  avatar?: string
  color?: string
  name: string
  address?: AddressType
  url?: string
  urlGoogleMyBusiness?: string
}

const INITIAL_VALUES_NO_CENTER = {
  avatar: "",
  color: "",
  name: "",
  address: {
    name: "",
  },
  url: "",
  urlGoogleMyBusiness: "",
} as EditCenterType

const VALIDATION_SCHEMA = Yup.object().shape({
  avatar: Yup.mixed().test(validateAvatarSize),
  color: Yup.string().nullable(),
  name: Yup.string().required("Nom requis"),
  address: Yup.object().test(validateAddress).nullable(),
  url: Yup.string().nullable(),
  urlGoogleMyBusiness: Yup.string().matches(
    new RegExp(/\/review$/),
    `Le lien n'est pas valide`
  ),
})

// HELPERS
const getInitialValues = (center?: CenterType) => {
  if (center === undefined) {
    return INITIAL_VALUES_NO_CENTER
  }
  return { ...center, avatar: center.avatar?.url ?? "" }
}

// COMPONENTS
interface EditCenterProps {
  isOpen: boolean
  onClose: () => void
  center?: CenterType
}
const EditCenter = ({ center, isOpen, onClose }: EditCenterProps) => {
  const initialValues = getInitialValues(center)
  const initialAvatar = initialValues.avatar
  const currentScope = useCenterAdminCurrentScope()
  const { jwt } = useAppContext()
  const uploadAvatarMutation = useMutation(uploadAvatar)
  const toast = useToast()
  const queryClient = useQueryClient()

  const addOrEditMutation = useMutation({
    mutationFn: (values: EditCenterType) =>
      center
        ? editCenter({
            centerGroupId: currentScope.centerGroupId,
            centerId: center.id,
            ...values,
          })
        : addCenter({
            centerGroupId: currentScope.centerGroupId,
            ...values,
          }),
    onError: () => {
      toast({
        status: "error",
        title: "Une erreur s'est produite",
      })
    },
  })

  const mutateCenter = (
    values: EditCenterType,
    { setSubmitting }: FormikHelpers<EditCenterType>
  ) =>
    addOrEditMutation.mutate(values, {
      onSuccess: async (submittedCenter) => {
        await queryClient.invalidateQueries({
          queryKey: getMeQueryKeys.me(jwt!),
        })
        toast({
          status: "success",
          title: center ? "Modification enregistrée" : "Lieu ajouté",
        })
        onClose()
        if (center) {
          queryClient.setQueriesData<AdminCenterGroup>(
            centerGroupQueryKeys.current(currentScope.centerGroupId),
            (centerGroup) => {
              if (centerGroup) {
                return {
                  ...centerGroup,
                  centers: centerGroup.centers.map((center) =>
                    center.id !== submittedCenter.id ? center : submittedCenter
                  ),
                }
              }
            }
          )
        } else {
          queryClient.setQueriesData<AdminCenterGroup>(
            centerGroupQueryKeys.current(currentScope.centerGroupId),
            (centerGroup) => {
              if (centerGroup) {
                return {
                  ...centerGroup,
                  centers: [...centerGroup.centers, submittedCenter],
                }
              }
            }
          )
        }
      },
      onSettled: () => {
        setSubmitting(false)
      },
    })

  const onSubmit = (
    { avatar, ...values }: EditCenterType,
    formikHelpers: FormikHelpers<EditCenterType>
  ) => {
    if (avatar && avatar !== initialAvatar) {
      const file = new FormData()
      file.append("files", avatar)
      return uploadAvatarMutation.mutate(file, {
        onError: () => {
          formikHelpers.setSubmitting(false)
          toast({
            status: "error",
            title: "Une erreur s'est produite",
          })
        },
        onSuccess: (fileId: string) => {
          mutateCenter({ ...values, avatar: fileId }, formikHelpers)
        },
      })
    }
    return mutateCenter(values, formikHelpers)
  }

  return (
    <Modal
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />

      <ModalContent maxW={724} borderRadius={8} p={8}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={VALIDATION_SCHEMA}
        >
          <Form>
            <ModalCloseButton aria-label="Fermer" />
            <ModalHeader mb={6} p={0} fontSize={18}>
              {center ? "Modifier le" : "Ajouter un"} centre
            </ModalHeader>
            <Grid templateColumns="repeat(2, 1fr)" rowGap={6}>
              <GridItem>
                <ACenterAvatarField
                  name="avatar"
                  label="Avatar (poids max 1Mo)"
                  avatar={center?.avatar}
                />
              </GridItem>
              <GridItem>
                <ASelectColorField name="color" label="Couleur" />
              </GridItem>
              <GridItem>
                <AInputField
                  name="name"
                  label={<FormLabel fontSize={14}>Nom *</FormLabel>}
                  placeholder="Nom"
                  bg="common.100"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <AAdressField name="address" />
              </GridItem>
              <GridItem colSpan={2}>
                <AInputField
                  name="url"
                  label={
                    <FormLabel fontSize={14}>
                      Url du site de réservation
                    </FormLabel>
                  }
                  placeholder="https://www.doctolib.fr/osteopathe/..."
                  bg="common.100"
                />
              </GridItem>
              <GridItem colSpan={2}>
                <AInputField
                  name="urlGoogleMyBusiness"
                  label={
                    <FormLabel>
                      <Text>
                        Url de partage pour{" "}
                        <Tooltip label="Obtenir mon lien" placement="right">
                          <Link
                            target="_blank"
                            href="https://support.google.com/business/answer/3474122?hl=fr"
                            textDecoration="underline"
                          >
                            avis Google My Business
                          </Link>
                        </Tooltip>
                      </Text>
                    </FormLabel>
                  }
                  placeholder="https://g.page/r/CeATmWfwbkmWEAI/review"
                  bg="common.100"
                />
                <Tooltip label="Obtenir mon lien" placement="right">
                  <Link
                    target="_blank"
                    href="https://youtu.be/tLzuMAhj_2I"
                    textDecor="underline"
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                    mt={2}
                  >
                    Ou consultez notre vidéo explicative pour obtenir votre lien
                  </Link>
                </Tooltip>
              </GridItem>
            </Grid>
            <ModalFooter justifyContent="center" p={0} mt={14}>
              <ASubmitButton
                text={
                  center ? "Enregistrer les modifications" : "Créer le centre"
                }
                py={3}
                px={28}
              />
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  )
}

export default EditCenter

import React from "react"
import { Box, Flex, Select, Text } from "@chakra-ui/react"
import { Heading } from "@chakra-ui/react"
import getVideoGroupCategory from "utils/getVideoGroupCategory"
import AInputSearch from "components/AInputSearch"
import AButton from "components/AButton"
import { VideoGroupCategoryType } from "api/appointmentReason"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"
import { ReasonType } from "api/patient"

interface IProps {
  categories: VideoGroupCategoryType[]
  categoryFilter: string | null
  setCategoryFilter: React.Dispatch<React.SetStateAction<string | null>>
  setSearch: React.Dispatch<React.SetStateAction<string>>
  restrictions: string[]
  restrictionFilter: string | null
  setRestrictionFilter: React.Dispatch<React.SetStateAction<string | null>>
  reasons?: ZonePathologieType[] | ReasonType[]
  allReasons: ZonePathologieType[]
  setCurrentReason: React.Dispatch<React.SetStateAction<ReasonType | null>>
}
const FilterExercices = ({
  categories,
  categoryFilter,
  setCategoryFilter,
  setSearch,
  restrictions,
  restrictionFilter,
  setRestrictionFilter,
  reasons,
  allReasons,
  setCurrentReason,
}: IProps) => {
  return (
    <Flex mb={8} direction="column" gap={8}>
      <Flex direction={"column"}>
        <Heading as="h2" fontSize={18} mb={2}>
          Exercices disponibles
        </Heading>
        <Text fontSize={14} mb={2}>
          Filtrez parmi les catégories d'exercices
        </Text>
      </Flex>
      <Flex direction={"column"} gap={4}>
        <AInputSearch
          placeholder="Filtrer les vidéos"
          canClear
          setSearch={setSearch}
        />
        <Flex justify={"space-between"}>
          <Flex gap={4}>
            {categories.map((category) => (
              <AButton
                text={getVideoGroupCategory(category)}
                variant={categoryFilter === category ? "primary" : "tertiary"}
                onClick={() => {
                  if (categoryFilter === category) setCategoryFilter(null)
                  else setCategoryFilter(category)
                }}
                bg={categoryFilter === category ? "primary.500" : "transparent"}
                color={categoryFilter === category ? "white" : "common.700"}
                _hover={{
                  bg:
                    categoryFilter === category ? "primary.600" : "primary.200",
                }}
                fontWeight={400}
                key={`category_${category}`}
              />
            ))}
          </Flex>
          {!reasons && (
            <Select
              width={200}
              borderColor="black"
              onChange={(e) => {
                const newCurrentReason =
                  allReasons.find(
                    (reason) => reason.id === parseInt(e.target.value, 10)
                  ) ?? null
                setCurrentReason(newCurrentReason)
              }}
            >
              {allReasons.map((zone) => (
                <option key={zone.id} value={zone.id}>
                  {zone.name}
                </option>
              ))}
            </Select>
          )}
        </Flex>
        <Flex>
          {restrictions && (
            <Box mt={2}>
              {restrictions.map((restriction) => (
                <AButton
                  text={restriction}
                  variant={
                    restrictionFilter === restriction ? "secondary" : "tertiary"
                  }
                  onClick={() => {
                    setRestrictionFilter(
                      restrictionFilter === restriction ? null : restriction
                    )
                  }}
                  bg={
                    restrictionFilter === restriction
                      ? "secondary.500"
                      : "transparent"
                  }
                  color={
                    restrictionFilter === restriction ? "white" : "common.700"
                  }
                  _hover={{
                    bg:
                      restrictionFilter === restriction
                        ? "secondary.600"
                        : "secondary.200",
                  }}
                  fontWeight={400}
                  key={`restriction_${restriction}`}
                />
              ))}
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FilterExercices

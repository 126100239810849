import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import arrowBack from "../../../../assets/arrow-back.svg"
import { TraininScheduleType } from "../../../../api/patient"
import React from "react"
import { SCHEDULE_DAYS_MOCK } from "./selectSchedule.mock"
import ScheduleDay from "./components/ScheduleDay"
import AButton from "../../../../components/AButton"

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  trainingSchedule: TraininScheduleType[]
  setTrainingSchedule: React.Dispatch<
    React.SetStateAction<TraininScheduleType[]>
  >
  handleNewAppointment: () => void
  isSubmitting: boolean
}

const SelectSchedule: React.FC<IProps> = ({
  setStep,
  trainingSchedule,
  setTrainingSchedule,
  handleNewAppointment,
  isSubmitting,
}) => {
  return (
    <Box maxW={784} pb={16}>
      <Flex mb={6} cursor="pointer" onClick={() => setStep(2)}>
        <Image src={arrowBack} mr={2} />
        <Text>Retour au choix de la consultation</Text>
      </Flex>
      <Heading as="h1" color="tertiary.500" fontSize={28} mb={8}>
        Jours d'exercice
      </Heading>
      {SCHEDULE_DAYS_MOCK.map((day) => (
        <ScheduleDay
          day={day}
          key={day.value}
          state={
            trainingSchedule.find((schedule) => schedule.weekday === day.value)
              ? "selected"
              : "default"
          }
          setTrainingSchedule={setTrainingSchedule}
          times={
            trainingSchedule.find((schedule) => schedule.weekday === day.value)
              ?.times || []
          }
        />
      ))}
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
      >
        <AButton
          text="Envoyer les exercices"
          px={120}
          py={3}
          fontFamily="Montserrat"
          onClick={() => {
            handleNewAppointment()
          }}
          isDisabled={isSubmitting}
        />
      </Flex>
    </Box>
  )
}

export default SelectSchedule

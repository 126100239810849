import { AddIcon } from "@chakra-ui/icons"
import { Button, ButtonProps } from "@chakra-ui/react"
import React from "react"

const theme = {
  active: {
    bg: "primary.500",
    borderColor: "primary.500",
    color: "white",
    _hover: {
      bg: "primary.400",
      color: "white",
      borderColor: "primary.400",
    },
  },
  default: {
    bg: "white",
    borderColor: "white",
    color: "tertiary.500",
    _hover: {
      bg: "primary.100",
      color: "primary.500",
      borderColor: "primary.500",
    },
  },
}

interface IProps extends ButtonProps {
  size?: "md" | "lg"
  text: string
  state?: "default" | "active"
}

const ASelectableButton: React.FC<IProps> = ({
  size = "md",
  text,
  state = "default",
  ...props
}) => {
  return (
    <Button
      border="1px solid"
      bg={theme[state].bg}
      color={theme[state].color}
      borderColor={theme[state].borderColor}
      _hover={{
        bg: theme[state]._hover.bg,
        color: theme[state]._hover.color,
        borderColor: theme[state]._hover.borderColor,
      }}
      borderRadius={9}
      py={size === "md" ? 6 : 8}
      px={20}
      leftIcon={<AddIcon w={3} h={3} />}
      {...props}
    >
      <span>{text}</span>
    </Button>
  )
}

export default ASelectableButton

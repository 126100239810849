import { Box, Flex, Text } from "@chakra-ui/react"
import React from "react"
import {
  SelectedExerciceType,
  TrainingVideoGroupsType,
} from "api/appointmentReason"
import AExerciceCard from "components/AExerciceCard/AExerciceCard"

interface IProps {
  videoGroup: TrainingVideoGroupsType
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
}

const VideoGroup: React.FC<IProps> = ({
  videoGroup,
  selectedExercices,
  setSelectedExercices,
}) => {
  return (
    <Box mb={6}>
      <Text fontWeight={700} fontSize={16} mb={4}>
        {videoGroup.title}
      </Text>
      <Flex gap={4}>
        {videoGroup.videos
          .filter(({ limitedMode }) => limitedMode === false)
          .map((video) => (
            <AExerciceCard
              key={`video_${video.id}`}
              video={video}
              videoGroupId={videoGroup.id}
              selectedExercices={selectedExercices}
              setSelectedExercices={setSelectedExercices}
            />
          ))}
      </Flex>
    </Box>
  )
}

export default VideoGroup

import React, { useState, useEffect } from "react"
import { VideoType } from "api/appointmentReason"
import { SelectedExerciceType } from "api/appointmentReason"
import { useEditingFavoritesContext } from "components/EditingFavoritesContext/EditingFavoritesContext"
import AExerciceCardBase from "components/AExerciceCard/Base"
import { InputProps } from "@chakra-ui/react"

interface IProps {
  video: VideoType
  videoGroupId: number
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
  title?: string
}

const AFavoriteExerciceCard: React.FC<IProps> = ({
  video,
  videoGroupId,
  selectedExercices,
  setSelectedExercices,
  title,
}) => {
  const [isSelecting, setIsSelecting] = useState(false)
  const {
    isEditing,
    getIsFavorite,
    addToCurrentFavorites,
    removeFromCurrentFavorites,
  } = useEditingFavoritesContext()

  const isSelected = isEditing
    ? getIsFavorite(video)
    : selectedExercices.some((exercice) => exercice.id === video.id)

  const onClickFavorite = isSelected
    ? () => removeFromCurrentFavorites(video)
    : () => addToCurrentFavorites(video)
  const onClickSelection = isSelected ? () => null : () => setIsSelecting(true)

  const onAdd = (value: number) => {
    if (!value) return
    setSelectedExercices((prev) => [
      ...prev,
      {
        ...video,
        duration: value,
        videoGroup: videoGroupId,
        defaultDuration: video.defaultDuration,
      },
    ])
  }

  return (
    <AExerciceCardBase
      video={video}
      videoGroupId={videoGroupId}
      title={title}
      isEditingFavorite={isEditing}
      isSelecting={isSelecting}
      isSelected={isSelected}
      onClick={isEditing ? onClickFavorite : onClickSelection}
      onAdd={onAdd}
    />
  )
}

export default AFavoriteExerciceCard

import { Box, Flex, Heading } from "@chakra-ui/react"
import React from "react"
import SelectedItem from "./components/SelectedItem"
import { ZonePathologieType } from "pages/NewAppointment/newAppointment.mock"

interface IProps {
  pathologies: ZonePathologieType[]
  setPathologies: React.Dispatch<React.SetStateAction<ZonePathologieType[]>>
  zones: ZonePathologieType[]
  setZones: React.Dispatch<React.SetStateAction<ZonePathologieType[]>>
}

const SelectedZonesPathologies: React.FC<IProps> = ({
  pathologies,
  zones,
  setPathologies,
  setZones,
}) => {
  return (
    <Box
      w={"full"}
      borderRadius={9}
      p={4}
      border="1px solid"
      borderColor="primary.500"
      mb={5}
    >
      <Heading as="h3" fontSize={18} mb={4}>
        Zones / symptomatologies sélectionnées
      </Heading>
      <Flex gap={3} flexWrap="wrap" alignItems="center">
        {zones.map(({ name, id }) => (
          <SelectedItem
            key={id}
            itemType="zone"
            name={name}
            itemId={id}
            setZones={setZones}
            setPathologies={setPathologies}
          />
        ))}
        {pathologies.map(({ name, id }) => (
          <SelectedItem
            key={id}
            itemType="pathologie"
            name={name}
            itemId={id}
            setZones={setZones}
            setPathologies={setPathologies}
          />
        ))}
      </Flex>
    </Box>
  )
}

export default SelectedZonesPathologies

import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import useToast from "../../hooks/useToast"
import * as Yup from "yup"
import { Field, Form, Formik } from "formik"
import { useRef, useState } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import arrowBack from "../../assets/arrow-back.svg"
import AButton from "../../components/AButton"
import { format } from "date-fns"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createPatient } from "../../api/patient"
// import { verifyEmail } from "../../api/user"
import { useAppContext } from "../../AppContext"
import InvitePatient from "./component/InvitePatient"
import getMeQueryKeys from "../../constants/queryKeys/getMe"
import parseBirthday from "../../utils/parseBirthday"
import getCurrentScopeParams from "utils/getCurrentScopeParams"
import DiscloseNewPatientTempPassword from "pages/NewPatient/component/DiscloseNewPatientTempPassword"
import useOnCollaboratorUnauthorized from "hooks/useOnCollaboratorUnauthorized"
import uppercaseFirstLetterLowercaseRest from "utils/uppercaseFirstLetterLowercaseRest"
import CreateNoVerifiedEmailPatient from "pages/NewPatient/component/CreateNoVerifiedEmailPatient"
import { CreatePatientType } from "api/patient"

const NewPatient = () => {
  const queryClient = useQueryClient()
  const { user, jwt, currentScope } = useAppContext()
  const createPatientExtraParams = getCurrentScopeParams(currentScope)
  const createPatientMutation = useMutation(createPatient)
  const navigate = useNavigate()
  const [typedEmail, setTypedEmail] = useState<string>("")
  const [tempPassword, setTempPassword] = useState<string>("")
  const [patientId, setPatientId] = useState<number>()
  const [tempPatient, setTempPatient] = useState<CreatePatientType>()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: passwordIsOpen,
    onOpen: passwordOnOpen,
    onClose: passwordOnClose,
  } = useDisclosure()

  const {
    isOpen: noVerifiedEmailIsOpen,
    onOpen: noVerifiedEmailOnOpen,
    onClose: noVerifiedEmailOnClose,
  } = useDisclosure()
  const cancelRefNoVerifiedEmail = useRef(null)
  const cancelRefPassword = useRef(null)
  const onCollaboratorUnauthorized = useOnCollaboratorUnauthorized()

  const onPatientSuccess = ({
    id,
    password,
  }: {
    id: number
    password: string
  }) => {
    setPatientId(id)
    setTempPassword(password)
    passwordOnOpen()
  }

  const onAndrewPlusPatientSuccess = ({ id }: { id: number }) => {
    setPatientId(id)
    toast({
      status: "success",
      title: "Patient ajouté avec succès",
    })
    navigate(`/patients/${id}`)
  }

  const passwordOnCloseNavigate = () => {
    passwordOnClose()
    navigate(`/patients/${patientId}`)
  }

  return (
    <Box flex={1} mr={6}>
      <Link as={RouterLink} to="/" mb={6} display="block">
        <Flex alignItems="center" justifyContent="start">
          <Image src={arrowBack} mr={2} />
          <Text>Retour</Text>
        </Flex>
      </Link>
      <Heading as="h1" fontSize={28} color="tertiary.500" mb={6}>
        Ajouter un patient
      </Heading>
      <Heading fontSize={18} mb={4}>
        Coordonnées du patient
      </Heading>
      <Formik
        initialValues={{
          lastname: "",
          firstname: "",
          birthday: "",
          email: "",
          telephone: "",
          postalCode: "",
        }}
        validateOnChange={true}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          lastname: Yup.string().required("Ce champ est obligatoire"),
          firstname: Yup.string().required("Ce champ est obligatoire"),
          birthday: Yup.date()
            .required("Ce champ est obligatoire")
            .transform(function (value, originalValue) {
              return parseBirthday(originalValue)
            })
            .typeError("Merci de rentrer une date valide"),
          email: Yup.string()
            .email("Ceci n'est pas un email")
            .required("Ce champ est obligatoire"),
          telephone: Yup.string().matches(
            new RegExp(
              /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
            ),
            "Ceci n'est pas un numéro de téléphone"
          ),
          postalCode: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const email = values.email.trim()
          setTypedEmail(email)
          if (!user || !user.osteo) return
          const newBirthday = parseBirthday(values.birthday)
          const patientData = {
            firstname: `${values.firstname}`,
            lastname: values.lastname,
            telephone: values.telephone?.split(" ").join(""),
            postalCode: values.postalCode,
            birthday: format(newBirthday, "yyyy-MM-dd"),
            email,
            osteo: user.osteo.id,
            ...createPatientExtraParams,
          }
          createPatientMutation.mutate(patientData, {
            onSuccess: (data) => {
              console.log({ data })
              if (!data.id) return
              queryClient.invalidateQueries({
                queryKey: getMeQueryKeys.me(jwt!),
              })
              setSubmitting(false)
              if (data.temp_password !== undefined) {
                onPatientSuccess({ id: data.id, password: data.temp_password })
                return
              }
              onAndrewPlusPatientSuccess({ id: data.id })
            },
            onError: (data: any) => {
              console.log({ data })
              setSubmitting(false)
              onCollaboratorUnauthorized(data)
              const message = data.response.data.error.message
              if (message === "User already exists") {
                onOpen()
              } else if (message === "Email doesnt exist") {
                setTempPatient(patientData)
                noVerifiedEmailOnOpen()
              } else if (message === "You are not allowed to add this user") {
                toast({
                  status: "error",
                  title:
                    "Ce mail est déjà associé à un compte hors patient déjà existant",
                })
              } else {
                toast({
                  status: "error",
                  title: data.response.data.error.message,
                })
              }
            },
          })
        }}
      >
        {(props) => (
          <Form>
            <Flex gap={26}>
              <Field name="lastname">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.lastname && form.touched.lastname}
                    flex={1}
                    mb={6}
                  >
                    <FormLabel>Nom *</FormLabel>
                    <Input
                      {...field}
                      placeholder="Nom"
                      bg="white"
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase()
                        field.onChange(e)
                      }}
                    />
                    <FormErrorMessage>{form.errors.lastname}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="firstname">
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.firstname && form.touched.firstname}
                    flex={1}
                    mb={6}
                  >
                    <FormLabel>Prénom *</FormLabel>
                    <Input
                      {...field}
                      placeholder="Prénom"
                      bg="white"
                      onChange={(e) => {
                        e.target.value = uppercaseFirstLetterLowercaseRest(
                          e.target.value
                        )
                        field.onChange(e)
                      }}
                    />
                    <FormErrorMessage>{form.errors.firstname}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Field name="birthday">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.birthday && form.touched.birthday}
                  w="calc(50% - 12px)"
                  mb={6}
                >
                  <FormLabel>Date de naissance *</FormLabel>
                  <Input
                    {...field}
                    placeholder="01/01/2001"
                    bg="white"
                    type="string"
                    onChange={(e) => {
                      if (
                        (e.target.value.length === 2 &&
                          field.value.length !== 3) ||
                        (e.target.value.length === 5 &&
                          field.value.length !== 6)
                      ) {
                        e.target.value += "/"
                      }
                      field.onChange(e)
                    }}
                  />
                  <FormErrorMessage>{form.errors.birthday}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="email">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  flex={1}
                  mb={6}
                >
                  <FormLabel>Email *</FormLabel>
                  <Input
                    {...field}
                    placeholder="exemple@exemple.com"
                    bg="white"
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="telephone">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.telephone && form.touched.telephone}
                  w="calc(50% - 12px)"
                  mb={6}
                >
                  <FormLabel>Téléphone</FormLabel>
                  <Input
                    {...field}
                    placeholder="06 12 34 56 78"
                    bg="white"
                    onKeyDown={(e) => {
                      if (
                        e.key === "Backspace" ||
                        e.key === "Delete" ||
                        e.key === "ArrowLeft" ||
                        e.key === "ArrowRight" ||
                        e.key === "ArrowUp" ||
                        e.key === "ArrowDown" ||
                        e.key === "Tab" ||
                        e.key === "Enter" ||
                        e.key === "Control" ||
                        e.key === "Command" ||
                        e.key === "+" ||
                        e.key === " " ||
                        // Paste event
                        (e.key === "v" && e.ctrlKey === true) ||
                        // Paste event on mac
                        (e.key === "v" && e.metaKey === true) ||
                        // Select all event
                        (e.key === "a" && e.ctrlKey === true) ||
                        // Select all event on mac
                        (e.key === "a" && e.metaKey === true) ||
                        // Cut event
                        (e.key === "x" && e.ctrlKey === true) ||
                        // Cut event on mac
                        (e.key === "x" && e.metaKey === true)
                      ) {
                        return
                      }
                      const isNumber = /^[0-9]$/i.test(e.key)
                      if (!isNumber) {
                        e.preventDefault()
                      }
                    }}
                  />
                  <FormErrorMessage>{form.errors.telephone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="postalCode">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.postalCode && form.touched.postalCode}
                  w="calc(50% - 12px)"
                  mb={16}
                >
                  <FormLabel>Code postal</FormLabel>
                  <Input
                    {...field}
                    placeholder="69003"
                    bg="white"
                    maxLength={5}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Backspace" ||
                        e.key === "Delete" ||
                        e.key === "ArrowLeft" ||
                        e.key === "ArrowRight" ||
                        e.key === "ArrowUp" ||
                        e.key === "ArrowDown" ||
                        e.key === "Tab" ||
                        e.key === "Enter" ||
                        e.key === "Control" ||
                        e.key === "Command" ||
                        // Paste event
                        (e.key === "v" && e.ctrlKey === true) ||
                        // Paste event on mac
                        (e.key === "v" && e.metaKey === true) ||
                        // Select all event
                        (e.key === "a" && e.ctrlKey === true) ||
                        // Select all event on mac
                        (e.key === "a" && e.metaKey === true) ||
                        // Cut event
                        (e.key === "x" && e.ctrlKey === true) ||
                        // Cut event on mac
                        (e.key === "x" && e.metaKey === true)
                      ) {
                        return
                      }
                      if (e.currentTarget.value.length >= 14) {
                        e.preventDefault()
                      }
                      const isNumber = /^[0-9]$/i.test(e.key)
                      if (!isNumber) {
                        e.preventDefault()
                      }
                    }}
                  />
                  <FormErrorMessage>{form.errors.postalCode}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex justifyContent="center">
              <AButton
                colorScheme="teal"
                isLoading={props.isSubmitting}
                type="submit"
                text="Ajouter un patient"
                py={3}
                px={20}
              />
            </Flex>
            <DiscloseNewPatientTempPassword
              onClose={passwordOnCloseNavigate}
              isOpen={passwordIsOpen}
              password={tempPassword}
            />
          </Form>
        )}
      </Formik>
      <InvitePatient
        onClose={onClose}
        isOpen={isOpen}
        cancelRef={cancelRefPassword}
        email={typedEmail}
      />
      <CreateNoVerifiedEmailPatient
        onClose={noVerifiedEmailOnClose}
        isOpen={noVerifiedEmailIsOpen}
        cancelRef={cancelRefNoVerifiedEmail}
        patient={tempPatient}
        onPatientSuccess={onPatientSuccess}
      />
    </Box>
  )
}

export default NewPatient

import axiosInstance from "./axiosInstance"

export type CurrencyCode = "eur" | "cad"

export type StripePrice = {
  id: string
  unit_amount: number
  recurring: {
    interval: "year" | "month"
  }
  currency: CurrencyCode
}

export type StripeProductType = {
  id: string
  pricesPerCurrency: { eur: StripePrice; cad: StripePrice }
  price: StripePrice
  images: []
  name: string
  metadata: {
    id: ListOfSubscriptions
  }
}

export const getSubscriptions = async () => {
  const { data } = await axiosInstance.get<StripeProductType[]>(
    "/osteo/me/subscriptions"
  )
  return data
}

export type CreateCheckoutSessionType = {
  priceId: string
  email: string
  quantity?: number
  stripeCode?: string
  affiliationNameUrl?: string
}

export const createCheckoutSession = async (
  data: CreateCheckoutSessionType
) => {
  const response = await axiosInstance.post(
    "/osteo/me/create-checkout-session",
    data
  )
  return response.data
}

export interface CreateCenterGroupCheckoutSession
  extends CreateCheckoutSessionType {}

export const createCenterGroupCheckoutSession = async (
  params: CreateCenterGroupCheckoutSession
) => {
  const response = await axiosInstance.post(
    "/center-groups/create-checkout-session",
    params
  )
  return response.data
}

export type CustomerPortalType = {
  sessionId: string
}

export const customerPortal = async (data: CustomerPortalType) => {
  const response = await axiosInstance.post<string>(
    "/osteo/me/customer-portal",
    data
  )
  return response.data
}

export interface CenterGroupCustomerPortalParams extends CustomerPortalType {
  centerGroupId: number
}

export const centerGroupCustomerPortal = async ({
  centerGroupId,
  ...params
}: CenterGroupCustomerPortalParams) => {
  const response = await axiosInstance.post<string>(
    "/center-groups/customer-portal",
    { center_group_id: centerGroupId, ...params }
  )
  return response.data
}

export type GetCenterSubscriptionStateParams = {
  centerId: number
}

export const getCenterSubscriptionState = async ({
  centerId,
}: GetCenterSubscriptionStateParams) => {
  const response = await axiosInstance.post<{ active: boolean }>(
    "/center-groups/subscription-state",
    { center_id: centerId }
  )
  return response.data
}

export type UpdateUserSubscriptionsParams = {
  priceId: string
  subscriptionId: string
  quantity: number
  email: string
}
export type UpdateCenterSubscriptionParams = {
  priceId: string
  stripeCustomerId: string
  quantity: number
  centerGroupId: number
}

export const updateSubscription = async ({
  priceId,
  subscriptionId,
  ...params
}: UpdateUserSubscriptionsParams) => {
  const response = await axiosInstance.post("/osteo/me/update-subscription", {
    price_id: priceId,
    subscription_id: subscriptionId,
    ...params,
  })
  return response.data
}

export const updateCenterGroupSubscription = async ({
  priceId,
  centerGroupId,
  stripeCustomerId,
  ...params
}: UpdateCenterSubscriptionParams) => {
  const response = await axiosInstance.post(
    "/center-groups/update-subscription",
    {
      price_id: priceId,
      center_group_id: centerGroupId,
      stripe_customer_id: stripeCustomerId,
      ...params,
    }
  )
  return response.data
}

export type GetCustomerResponse = {
  customer_id: string
}
export const getCustomer = async (sessionId: string) => {
  const response = await axiosInstance.post<GetCustomerResponse>(
    "/osteo/stripe-custome-id",
    {
      session_id: sessionId,
    }
  )
  return response.data
}

export type SubscriptionBillingParams = {
  priceId: string
  subscriptionId: string
}

export type PreviewInvoiceItem = {
  amount: number
  currency: string
  description: string
  plan: StripePlan
}
export type PreviewInvoice = {
  lines: {
    data: PreviewInvoiceItem[]
  }
  total: number
}

export const updateSubscriptionBilling = async ({
  priceId,
  subscriptionId,
}: SubscriptionBillingParams) => {
  const response = await axiosInstance.post(
    "/osteo/me/subscription-billing/update",
    {
      price_id: priceId,
      subscription_id: subscriptionId,
    }
  )
  return response.data
}

export type ListOfSubscriptions =
  | "individual_monthly"
  | "individual_yearly"
  | "business_monthly"
  | "business_yearly"

export type PartnerType = {
  id: string
  name: string
  stripeCode: string
  subscriptions: {
    id: string
    metadataId: ListOfSubscriptions
  }[]
}

export const getPartner = async (partnerMetadataId: string) => {
  const { data } = await axiosInstance.get(`/partner/${partnerMetadataId}`)
  return data
}

export const getAllSubscriptions = async () => {
  const { data } = await axiosInstance.get("/subscription/all")
  return data
}

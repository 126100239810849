import { getSubscriptions, StripeProductType } from "api/subscriptions"
import { useQuery } from "@tanstack/react-query"
import { useAppContext } from "AppContext"
import userAdminsCenterGroupInCurrentScope from "utils/currentScope/userAdminsCenterGroupInCurrentScope"
import OsteoSubscription from "components/Subscription/Osteo/OsteoSubscription"
import CenterGroupSubscription from "components/Subscription/CenterGroup/CenterGroupSubscription"
import userHasSubscription from "utils/subscription/userHasSubscription"

interface SubscriptionProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}
const Subscription = (props: SubscriptionProps) => {
  const { user, currentScope } = useAppContext()

  const { data: stripeProducts, isLoading: isLoadingStripeProducts } = useQuery<
    StripeProductType[]
  >(["getSubscriptions"], getSubscriptions)

  const userHasIndividualSubscription = userHasSubscription(user)
  const isCenterAdmin = userAdminsCenterGroupInCurrentScope(user, currentScope)

  if (userHasIndividualSubscription) {
    return (
      <OsteoSubscription
        {...props}
        stripeProducts={stripeProducts}
        isLoadingStripeProducts={isLoadingStripeProducts}
      />
    )
  }

  if (isCenterAdmin) {
    return (
      <CenterGroupSubscription
        {...props}
        stripeProducts={stripeProducts}
        isLoadingStripeProducts={isLoadingStripeProducts}
      />
    )
  }
  return null
}

export default Subscription

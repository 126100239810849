import { createContext, ReactNode, useContext, useState } from "react"
import { FavoriteProgram, VideoType } from "api/appointmentReason"

//CONTEXT
interface EditingFavoritesValues {
  currentFavorites: VideoType[]
  addToCurrentFavorites: (video: VideoType | VideoType[]) => void
  removeFromCurrentFavorites: (video: VideoType) => void
  isEditing: boolean
  currentFavoriteProgramId: FavoriteProgram["id"] | undefined
  onEditFavoriteProgram: (favorite: FavoriteProgram) => void
  getIsFavorite: (video: VideoType) => boolean
  onDone: () => void
  isFavoriteNotSaved: boolean
}
export const EditingFavoritesContext = createContext<
  EditingFavoritesValues | undefined
>(undefined)

export const useEditingFavoritesContext = () => {
  const context = useContext(EditingFavoritesContext)
  if (!context) {
    throw new Error(
      "useEditingFavoritesContext must be used within a EditingFavoritesContext Provider"
    )
  }
  return context
}

interface EditingFavoritesContextProviderProps {
  children?: ReactNode
}
const EditingFavoritesContextProvider = ({
  children,
}: EditingFavoritesContextProviderProps) => {
  const [currentFavorites, setCurrentFavorites] = useState<VideoType[]>([])
  const [currentFavoriteProgramId, setCurrentFavoriteProgramId] = useState<
    FavoriteProgram["id"] | undefined
  >()
  const [lastFavoriteVideoIds, setLastFavoriteVideosIds] = useState<
    string | undefined
  >()

  const currentFavoriteIds = currentFavorites.map(({ id }) => id)

  const isEditing = currentFavoriteProgramId !== undefined

  const isFavoriteNotSaved = isEditing
    ? lastFavoriteVideoIds !==
      JSON.stringify(currentFavorites.map((fav) => fav.id).sort())
    : false

  const onEditFavoriteProgram = (favoriteProgram: FavoriteProgram) => {
    setCurrentFavorites(favoriteProgram.trainingVideos)
    setCurrentFavoriteProgramId(favoriteProgram.id)
    setLastFavoriteVideosIds(
      JSON.stringify(favoriteProgram.trainingVideos.map((fav) => fav.id).sort())
    )
  }

  const onDone = () => {
    setCurrentFavorites([])
    setCurrentFavoriteProgramId(undefined)
    setLastFavoriteVideosIds(undefined)
  }

  const addToCurrentFavorites = (video: VideoType | VideoType[]) => {
    Array.isArray(video)
      ? setCurrentFavorites((prevFavorites) => [...prevFavorites, ...video])
      : setCurrentFavorites((prevFavorites) => [...prevFavorites, video])
  }

  const removeFromCurrentFavorites = (video: VideoType) => {
    setCurrentFavorites((prevFavorites) =>
      prevFavorites.filter((favorite) => favorite.id !== video.id)
    )
  }

  const getIsFavorite = (video: VideoType) =>
    currentFavoriteIds.includes(video.id)

  const value = {
    currentFavoriteProgramId,
    onEditFavoriteProgram,
    onDone,
    isEditing,
    currentFavorites,
    addToCurrentFavorites,
    removeFromCurrentFavorites,
    getIsFavorite,
    isFavoriteNotSaved,
  }

  return (
    <EditingFavoritesContext.Provider value={value}>
      {children}
    </EditingFavoritesContext.Provider>
  )
}

export default EditingFavoritesContextProvider

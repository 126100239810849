import { Flex, Icon, Text } from "@chakra-ui/react"
import AnimatedNumber from "components/Stats/AnimatedNumber"
import { InfoOutlineIcon } from "@chakra-ui/icons"

interface SingleDataProps {
  value: number
  subvalue?: string
  label: string
  unit?: string
  sublabel?: string
  labelColor?: string
}

const SingleData: React.FC<SingleDataProps> = ({
  value,
  label,
  unit,
  subvalue,
  sublabel,
  labelColor = "primary.500",
}) => {
  return (
    <Flex
      direction={"column"}
      p={6}
      borderRadius={"2xl"}
      bg={"white"}
      w={314}
      h={"fit-content"}
    >
      <Flex align={"center"} gap={2}>
        <Text as="b" fontSize={"42px"} color={labelColor}>
          {unit ? (
            <>
              <AnimatedNumber end={value} />
              {unit}
            </>
          ) : (
            <AnimatedNumber end={value} />
          )}
        </Text>
        {subvalue && <Text as="b">{subvalue}</Text>}
      </Flex>
      <Text whiteSpace="pre-wrap">{label}</Text>
      {sublabel && (
        <Flex p={2} bg={"primary.100"} borderRadius={"8px"} gap={2} mt={6}>
          <Icon fontSize={14} as={InfoOutlineIcon} mt={1} />
          <Text whiteSpace="pre-wrap" fontSize={"sm"}>
            {sublabel}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default SingleData

import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import AEditButton from "components/AEditButton"
import { format } from "date-fns"
import React, { useRef } from "react"
import { useNavigate } from "react-router-dom"
import { PatientAppointmentType } from "../../../api/patient"
import addTwoMonths from "../../../utils/addTwoMonth"
import AButton from "components/AButton"
import NewPlaylistValidity from "./NewPlaylistValidity"

interface IProps {
  appointment: PatientAppointmentType
  patientId: string | undefined
  setAppointment?: React.Dispatch<
    React.SetStateAction<PatientAppointmentType | null>
  >
}

const Training: React.FC<IProps> = ({
  appointment,
  patientId,
  setAppointment,
}) => {
  const navigate = useNavigate()
  const cancelRef = useRef(null)

  const {
    isOpen: isDelayModalOpen,
    onClose: onDelayModalClose,
    onOpen: onDelayModalOpen,
  } = useDisclosure()

  const onEdit = () => {
    navigate(`/patients/${patientId}/appointments/${appointment.id}/edit`)
  }

  return (
    <div>
      <Box bg="white" p={4} borderRadius={9} pos="relative">
        <AEditButton
          pos="absolute"
          top={2}
          right={2}
          onClick={onEdit}
          ariaLabel="Modifier les exercices conseillés"
        />

        <Heading as="h3" fontSize={18} mb={2}>
          Exercices conseillés
        </Heading>
        <Text fontSize={12} mb={2}>
          Disponible jusqu’au{" "}
          {appointment.trainingPlaylist.expiry !== undefined
            ? format(
                new Date(appointment.trainingPlaylist.expiry),
                "dd/MM/yyyy"
              )
            : format(
                addTwoMonths(new Date(appointment.meetingDate)),
                "dd/MM/yyyy"
              )}
        </Text>
        {appointment.trainingPlaylist.mainReason !== null && (
          <>
            <Text fontSize={14}>
              <b>
                Motif principal de consultation :{" "}
                {appointment.trainingPlaylist.mainReason.name}
              </b>
            </Text>
            {appointment.trainingPlaylist.reasons && (
              <Text fontSize={14}>
                Autres motifs de consultation :{" "}
                {appointment.trainingPlaylist.reasons
                  .map((reason) => reason.name)
                  .join(", ")}
              </Text>
            )}
          </>
        )}

        {appointment.trainingPlaylist?.exercices.map((exercice) => (
          <Flex key={exercice.id} mb={4} mt={4} alignItems="center">
            <Image
              src={exercice.osteoSelection.preview.formats.small.url}
              mr={4}
              borderRadius={9}
              w={143}
            />
            <Box>
              <Heading as="p" fontSize={18} color="primary.500" mb={2}>
                {exercice.osteoSelection.group[0].title}
              </Heading>
              <Text fontWeight={700}>
                Niveau {exercice.osteoSelection.level}
              </Text>
              <Text>
                <b>Durée</b> {exercice.duration} sec
              </Text>
            </Box>
          </Flex>
        ))}
        <Flex alignItems="center" justify="center" py="2">
          <AButton text="Prolonger la durée" onClick={onDelayModalOpen} />
        </Flex>
      </Box>
      <NewPlaylistValidity
        onClose={onDelayModalClose}
        isOpen={isDelayModalOpen}
        cancelRef={cancelRef}
        appointment={appointment}
        setAppointment={setAppointment}
      />
    </div>
  )
}

export default Training

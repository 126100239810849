import axiosInstance from "./axiosInstance"
import { TraininScheduleType } from "./patient"

export type AppointmentType = {
  id: number
  dateFormatted: string
  patient: {
    firstname: string
    lastname: string
    id: number
  }
  createdAt: string
  osteo: OsteoType
}

export type GetAppointmentsParams = PaginationParamsType &
  (
    | { centerId: number; osteos?: number[] }
    | {
        academieId: number
        osteos?: number[]
      }
    | {}
  )
export type GetAppointmentsResponse = {
  results: AppointmentType[]
  count: PaginationType
}
export const getAppointments = async (params: GetAppointmentsParams) => {
  const endpoint = "/osteo/me/getAllAppointments"
  let fullParams
  if ("centerId" in params) {
    fullParams = {
      center_id: params.centerId,
      osteos: params.osteos,
      per_page: params.perPage,
      page: params.page,
    }
  } else if ("academieId" in params) {
    fullParams = {
      academie_id: params.academieId,
      osteos: params.osteos,
      per_page: params.perPage,
      page: params.page,
    }
  } else {
    fullParams = {
      per_page: params.perPage,
      page: params.page,
    }
  }
  const response = await axiosInstance.get<GetAppointmentsResponse>(endpoint, {
    params: fullParams,
  })
  return response.data
}

export type VideoExerciceType = {
  duration: number
  videoGroup: number
  osteoSelection: number
}

export type CreateAppointmentType = {
  appointment: {
    osteo: number
    academieId?: number
    centerId?: number
    patient: number
    osteoAdvice: string | null
    meetingDate: string
    internalNote: string | null
    secondaryInternalNote: string | null
    explorerMedias?: number[] | null
  }
  playlist: {
    author: number
    expiry: string
    mainReason?: number
    reasons?: (number | undefined)[]
  }
  videoExercices: VideoExerciceType[]
  checkup: {
    pain: number
    stress: number
    frequency: number
    mobility: number
    patient: number
    mainReason?: number
    reasons?: (number | undefined)[]
  }
  trainingSchedule: TraininScheduleType[]
  patientId: number
}

export const newAppointment = async ({
  appointment,
  ...data
}: CreateAppointmentType) => {
  const { academieId, centerId, ...restAppointment } = appointment
  const response = await axiosInstance.post("/osteo/me/appointment/new", {
    ...data,
    appointment: {
      ...restAppointment,
      academie_id: academieId,
      center_id: centerId,
    },
  })
  return response.data
}

export type UpdateAppointmentParams = {
  id: string
  data: {
    osteoAdvice?: string
    videoExercices?: VideoExerciceType[]
    expiry?: number
  }
}

export const updateAppointment = async (params: UpdateAppointmentParams) => {
  const response = await axiosInstance.put(
    `/osteo/me/appointment/update`,
    params
  )
  return response.data
}

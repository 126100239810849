import { useAppContext } from "AppContext"

const useCurrentCenterColor = () => {
  const { user, currentScope } = useAppContext()

  const currentCenter =
    currentScope?.type === "center"
      ? user?.osteo.centers?.find(({ id }) => id === currentScope.id)
      : undefined

  const currentCenterColor = currentCenter?.color

  return currentCenterColor
}

export default useCurrentCenterColor

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import { SelectedDiscoverType } from "api/appointmentReason"
import Header from "./components/Header/Header"
import { TopicFullListType, getTopics } from "api/topics"
import DiscoverMedia from "pages/NewAppointment/components/SelectDiscovers/components/DiscoverMedia/DiscoverMedia"
import {
  getAllMedias,
  BabyMediaTag,
  MediaType,
  BabyMediaTagListType,
} from "api/media"
import SelectedDiscovers from "pages/NewAppointment/components/SelectDiscovers/components/SelectedDiscovers/SelectedDiscovers"
import AButton from "components/AButton"
import SuggestedVideo from "pages/NewAppointment/components/SelectDiscovers/components/SuggestedVideo/SuggestedVideo"
import GianniModal from "pages/NewAppointment/components/SelectDiscovers/components/GianniModal/GianniModal"

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  reason?: "zones" | "pathologies" | "no" | "no-exos" | null
  selectedDiscovers: SelectedDiscoverType[]
  setSelectedDiscovers: React.Dispatch<
    React.SetStateAction<SelectedDiscoverType[]>
  >
  isBabySwitchOn: boolean
  selectedBabyMediaTags: BabyMediaTag[] | null
  babyMediaTags: BabyMediaTag[]
  handleNewAppointment: () => void
  isSubmitting: boolean
}

const SelectDiscovers: React.FC<IProps> = ({
  setStep,
  reason,
  selectedDiscovers,
  setSelectedDiscovers,
  isBabySwitchOn,
  selectedBabyMediaTags,
  babyMediaTags,
  handleNewAppointment,
  isSubmitting,
}) => {
  const [discovers, setDiscovers] = useState<TopicFullListType[]>([])
  const [mediasByTag, setMediaByTag] = useState<BabyMediaTagListType[]>([])
  const cancelRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const fetchData = async () => {
    const topics = await getTopics()
    const medias = await getAllMedias(isBabySwitchOn)

    if (isBabySwitchOn) {
      const mediaByTag: BabyMediaTagListType[] =
        selectedBabyMediaTags === null
          ? babyMediaTags.map((tag) => {
              const tagMedias = medias.filter(
                (media) => media.tag && media.tag.key === tag.key
              )
              return { tag, medias: tagMedias }
            })
          : selectedBabyMediaTags.map((tag) => {
              const tagMedias = medias.filter(
                (media) => media.tag && media.tag.key === tag.key
              )
              return { tag, medias: tagMedias }
            })

      const mediasWithoutTag = medias.filter((media) => !media.tag)
      mediaByTag.push({
        tag: { key: "other", name: "Autre" },
        medias: mediasWithoutTag,
      })

      setMediaByTag(mediaByTag)
      return
    }

    const discovers: TopicFullListType[] = topics.map((topic) => ({
      id: topic.id,
      attributes: topic.attributes,
      medias: medias.filter(
        (media) => media.topic && media.topic.id === topic.id
      ),
    }))

    setDiscovers(discovers)
  }

  const handleAddAllMedias = (medias: MediaType[]) => {
    const newMedias = medias.filter(
      (media) => !selectedDiscovers.find((discover) => discover.id === media.id)
    )
    setSelectedDiscovers([...selectedDiscovers, ...newMedias])
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (discovers.length === 0 && mediasByTag.length === 0) {
    return <Header setStep={setStep} reason={reason} />
  }

  return (
    <Box>
      <Header setStep={setStep} reason={reason} isForBaby={isBabySwitchOn} />
      <Flex w={"full"}>
        {isBabySwitchOn ? (
          <Flex direction={"column"} flex={1}>
            <Box w={"full"}>
              <SuggestedVideo
                title="Introduction aux conseils nourrisson avec Gianni Marangelli"
                url="https://www.youtube.com/embed/TSp-3b1lXAM?si=DJ2txxyEV_Q8rVXa"
              />
            </Box>
            <Box mb={12}>
              <Text mb={2} fontWeight="bold">
                Le contenu nourrisson a été créé avec Gianni Marangelli.
              </Text>
              <Text>
                <Text
                  textDecor={"underline"}
                  onClick={onOpen}
                  cursor="pointer"
                  display={"inline"}
                >
                  En savoir plus
                </Text>{" "}
                sur Gianni Marangelli et ses références de travail.
              </Text>
            </Box>
            <Flex direction={"column"} gap={"32px"} w={"full"}>
              {mediasByTag.length > 0 &&
                mediasByTag.map(
                  (mediaTag) =>
                    mediaTag.medias.length > 0 && (
                      <Flex
                        w={"full"}
                        key={mediaTag.tag.key}
                        direction={"column"}
                        alignItems={"start"}
                        gap={4}
                      >
                        <Text fontWeight={700} fontSize={24}>
                          {mediaTag.tag.name}
                        </Text>

                        <Flex
                          direction="row"
                          flexWrap="wrap"
                          gap="16px"
                          w={"full"}
                        >
                          {mediaTag.medias.map((media) => (
                            <DiscoverMedia
                              key={media.id}
                              media={media}
                              selectedDiscovers={selectedDiscovers}
                              setSelectedDiscovers={setSelectedDiscovers}
                            />
                          ))}
                        </Flex>
                      </Flex>
                    )
                )}
            </Flex>
          </Flex>
        ) : (
          <Accordion w="full" allowToggle>
            {discovers
              .filter((discover) => discover.medias.length > 0)
              .map((discover) => (
                <AccordionItem
                  key={discover.id}
                  border="0px"
                  id={`media_${discover.id}`}
                >
                  {({ isExpanded }) => (
                    <>
                      <Heading>
                        <AccordionButton
                          borderBottom="1px"
                          borderColor="primary.300"
                          w="full"
                          _expanded={{
                            fontWeight: "semibold",
                            borderBottom: "2px",
                            borderColor: "primary.500",
                          }}
                          padding="16px"
                          flex={1}
                          justifyContent="space-between"
                          as="div"
                        >
                          <Box>{discover.attributes.title}</Box>
                          <Flex alignItems={"center"} gap={4}>
                            {isExpanded && (
                              <AButton
                                text="Tout ajouter"
                                onClick={() =>
                                  handleAddAllMedias(discover.medias)
                                }
                                variant="tertiary"
                                my={3}
                              />
                            )}

                            <AccordionIcon boxSize="28px" />
                          </Flex>
                        </AccordionButton>
                      </Heading>
                      <AccordionPanel
                        display="flex"
                        flexDirection="column"
                        alignItems="start"
                        gap={2}
                      >
                        <Flex
                          flexDirection="row"
                          flexWrap="wrap"
                          gap="16px"
                          w={"full"}
                        >
                          {discover.medias.map((media) => (
                            <DiscoverMedia
                              key={media.id}
                              media={media}
                              selectedDiscovers={selectedDiscovers}
                              setSelectedDiscovers={setSelectedDiscovers}
                            />
                          ))}
                        </Flex>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
          </Accordion>
        )}

        {selectedDiscovers && (
          <SelectedDiscovers
            selectedDiscovers={selectedDiscovers}
            setSelectedDiscovers={setSelectedDiscovers}
          />
        )}
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        gap={2}
        fontFamily="Montserrat"
        bg="primary.200"
        py={6}
        position="fixed"
        bottom={0}
        left={0}
        zIndex={100}
      >
        <AButton
          text={isBabySwitchOn ? "Créer la consultation" : "Suivant"}
          px={120}
          py={3}
          onClick={() => {
            if (isBabySwitchOn) {
              handleNewAppointment()
              return
            }
            setStep(3)
          }}
          isDisabled={isSubmitting}
        />
      </Flex>
      <GianniModal cancelRef={cancelRef} isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}

export default SelectDiscovers

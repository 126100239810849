import { DeleteIcon } from "@chakra-ui/icons"
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Heading,
  IconButton,
} from "@chakra-ui/react"
import { SelectedExerciceType } from "api/appointmentReason"
import AButton from "components/AButton"
import AFavoriteExerciceCard from "components/AExerciceCard/AFavoriteExerciceCard"
import ASubmitButton from "components/ASubmitButton"
import { useEditingFavoritesContext } from "components/EditingFavoritesContext/EditingFavoritesContext"
import AInputField from "components/Field/AInputField"
import { useState } from "react"
import AAlertDialogConfirm from "components/AAlertDialogConfirm"

interface EditFavoriteProgramCardProps {
  onCancel: () => void
  onDelete?: () => void
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
}

const EditFavoriteProgramCard = ({
  onCancel,
  onDelete,
  selectedExercices,
  setSelectedExercices,
}: EditFavoriteProgramCardProps) => {
  const { currentFavorites, currentFavoriteProgramId } =
    useEditingFavoritesContext()

  const headerContent = "Supprimer le favori"
  const destructiveActionText = "Supprimer"

  const [alertDialogVisibility, setAlertDialogVisibility] = useState(false)

  const toggleAlertDialogVisibility = () => {
    setAlertDialogVisibility((alertDialogVisibility) => !alertDialogVisibility)
  }

  return (
    <>
      <AccordionItem bg="secondary.500">
        <Heading as={"h3"}>
          <AccordionButton as="div">
            <Flex justify={"space-between"} grow={1} align="center">
              <AInputField
                w="auto"
                aria-label="Titre"
                bg="common.100"
                name="title"
              />
              <Flex gap={4} align="center">
                {onDelete && (
                  <IconButton
                    w={10}
                    h={10}
                    border="1px solid"
                    borderColor="danger.500"
                    color="danger.500"
                    borderRadius="50%"
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleAlertDialogVisibility()
                    }}
                    icon={<DeleteIcon />}
                    aria-label="Supprimer le favori"
                  />
                )}
                <AButton
                  text="Annuler"
                  variant="tertiary"
                  onClick={(e) => {
                    e.stopPropagation()
                    onCancel()
                  }}
                />
                <ASubmitButton
                  text="Sauvegarder"
                  onClick={(e) => e.stopPropagation()}
                />
                <AccordionIcon />
              </Flex>
            </Flex>
          </AccordionButton>
        </Heading>
        <AccordionPanel>
          <Flex mt={4} gap={4} flexWrap="wrap">
            <>
              {currentFavorites.length === 0 ? (
                currentFavoriteProgramId === "new" ? (
                  <Flex
                    justify="center"
                    w={"full"}
                    pt={2}
                    pb={6}
                    fontWeight="semibold"
                  >
                    Sélectionnez les vidéos que vous souhaitez ajouter au favori
                  </Flex>
                ) : (
                  <Flex
                    justify="center"
                    w={"full"}
                    pt={2}
                    pb={6}
                    fontWeight="semibold"
                  >
                    Aucune vidéo n'est renseignée dans ce favori
                  </Flex>
                )
              ) : (
                currentFavorites
                  .filter((video) => video.group && video.group.length > 0)
                  .map((video) => (
                    <AFavoriteExerciceCard
                      key={`video_${video.id}`}
                      selectedExercices={selectedExercices}
                      setSelectedExercices={setSelectedExercices}
                      video={video}
                      videoGroupId={video.group[0].id}
                      title={video.group[0].title}
                    />
                  ))
              )}
            </>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <AAlertDialogConfirm
        isOpen={alertDialogVisibility}
        onClose={toggleAlertDialogVisibility}
        onConfirm={onDelete}
        headerContent={headerContent}
        destructiveActionText={destructiveActionText}
        destructiveActionVariant="primary"
        bodyContent="Êtes-vous sûr de vouloir supprimer ce favori ?"
      />
    </>
  )
}

export default EditFavoriteProgramCard

import { FavoriteProgram, SelectedExerciceType } from "api/appointmentReason"
import React from "react"
import { useEditingFavoritesContext } from "components/EditingFavoritesContext/EditingFavoritesContext"
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Flex,
  Icon,
} from "@chakra-ui/react"
import AButton from "components/AButton"
import { AddIcon } from "@chakra-ui/icons"
import { RiPencilLine } from "react-icons/ri"
import AExerciceCard from "components/AExerciceCard/AExerciceCard"
import { MdFavoriteBorder } from "react-icons/md"

interface FavoriteProgramCardProps {
  favoriteProgram: FavoriteProgram
  selectedExercices: SelectedExerciceType[]
  setSelectedExercices: React.Dispatch<
    React.SetStateAction<SelectedExerciceType[]>
  >
}

const FavoriteProgramCard = ({
  favoriteProgram,
  selectedExercices,
  setSelectedExercices,
}: FavoriteProgramCardProps) => {
  const {
    isEditing,
    onEditFavoriteProgram,
    currentFavorites,
    addToCurrentFavorites,
  } = useEditingFavoritesContext()

  const hasNoVideos = favoriteProgram.trainingVideos.length === 0

  const handleEditButton = () => {
    onEditFavoriteProgram(favoriteProgram)
  }
  const handleAddAll = () => {
    if (isEditing) {
      const videosToAddToFavorite = favoriteProgram.trainingVideos.filter(
        (video) => !currentFavorites.some((elt: any) => elt.id === video.id)
      )
      if (videosToAddToFavorite.length > 0) {
        addToCurrentFavorites(videosToAddToFavorite)
      }
    } else {
      const videosToAdd = favoriteProgram.trainingVideos.filter(
        (video) =>
          video.group &&
          video.group.length > 0 &&
          selectedExercices.find((elt: any) => elt.id === video.id) ===
            undefined
      )
      const newSelectedExercices = videosToAdd.map((video) => ({
        ...video,
        duration: video.defaultDuration || 30,
        videoGroup: video.group[0].id,
      }))
      setSelectedExercices((prev) => [...prev, ...newSelectedExercices])
    }
  }

  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <>
          <Flex>
            <AccordionButton as="div">
              <Flex justify={"space-between"} grow={1} align="center">
                <Heading as="h3" fontSize={16}>
                  {favoriteProgram.title}
                </Heading>
                <Flex gap={4} align="center">
                  {isExpanded && (
                    <>
                      <AButton
                        text="Tout ajouter"
                        leftIcon={
                          isEditing ? (
                            <Icon
                              w={3}
                              h={3}
                              aria-label="Ajouter aux favoris"
                              as={MdFavoriteBorder}
                            />
                          ) : (
                            <AddIcon w={3} h={3} />
                          )
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          handleAddAll()
                        }}
                        isDisabled={hasNoVideos}
                      />
                      <AButton
                        text="Modifier"
                        leftIcon={<RiPencilLine />}
                        isDisabled={isEditing}
                        onClick={(e) => {
                          e.stopPropagation()
                          handleEditButton()
                        }}
                      />
                    </>
                  )}
                  <AccordionIcon />
                </Flex>
              </Flex>
            </AccordionButton>
          </Flex>
          <AccordionPanel>
            <Flex mt={4} gap={4} flexWrap="wrap">
              {hasNoVideos ? (
                <Flex
                  justify="center"
                  w={"full"}
                  pt={2}
                  pb={6}
                  fontWeight="medium"
                >
                  Aucune vidéo n'est renseignée dans ce favori
                </Flex>
              ) : (
                favoriteProgram.trainingVideos
                  .filter((video) => video.group && video.group.length > 0)
                  .map((video) => (
                    <AExerciceCard
                      key={`video_${video.id}`}
                      selectedExercices={selectedExercices}
                      setSelectedExercices={setSelectedExercices}
                      video={video}
                      videoGroupId={video.group[0].id}
                      title={video.group[0].title}
                    />
                  ))
              )}
            </Flex>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

export default FavoriteProgramCard
